import React from 'react';
import { Container, Header, Segment, Button, Embed, Icon } from 'semantic-ui-react';
import Slider from 'react-slick';
import classes from "./FootersPages.module.css";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export default function DocumentationVideoCarousel() {
    const videos = [
        { title: "Step 1: Create an Account", id: "yourYoutubeVideoID1" },
        { title: "Step 2: Select Your AI Model", id: "yourYoutubeVideoID2" },
        { title: "Step 3a: Choose a Story", id: "yourYoutubeVideoID3" },
        { title: "Step 3b: Create Your Own Story", id: "yourYoutubeVideoID4" },
    ];

    // Custom arrow components
    const NextArrow = ({ onClick }) => (
        <div className={classes.nextArrow} onClick={onClick}>
            <Icon name="arrow right" size="big" />
        </div>
    );

    const PrevArrow = ({ onClick }) => (
        <div className={classes.prevArrow} onClick={onClick}>
            <Icon name="arrow left" size="big" />
        </div>
    );

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };

    return (
        <Container className={classes.documentationCarousel} style={{ padding: '3em 0em' }}>
            <Segment basic textAlign="center">
                <Header as="h1" style={{ fontSize: '2.5em', color: '#2a2a2a' }}>Getting Started with Biffe Corps</Header>
                <p style={{ fontSize: '1.33em', color: '#5a5a5a', marginBottom: '2em' }}>
                    Follow these video guides to start using Biffe Corps effectively.
                </p>
            </Segment>

            <Slider {...settings}>
                {videos.map((video, index) => (
                    <Segment key={index} basic textAlign="center">
                        <Header as="h2" style={{ fontSize: '2em', color: '#2a2a2a' }}>{video.title}</Header>
                        <Embed
                            id={video.id}
                            source="youtube"
                            active
                            placeholder={`path/to/placeholder-${index}.jpg`} // Optional placeholders
                        />
                    </Segment>
                ))}
            </Slider>

            <Segment basic textAlign="center" style={{ marginTop: '2em' }}>
                <Button primary href="/signup">Get Started</Button>
            </Segment>
        </Container>
    );
}
