import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "Home": "Home",
          "Story Mode": "Story Mode",
          "Save": "Save",
          "Creation Mode": "Creation Mode",
          "Select a Story": "Select a Story",
          "SignIn": "Sign In",
          "LogIn": "Log In",
          "SignUp": "Sign Up",
          "SignOut": "Sign Out",
          "LogInGoogle": "Log In with Google",
          "SignUpGoogle": "Sign Up with Google",
          "NoAccount": "Don't have an account?",
          "AlreadyAccount": "Already have an account?",
          "headerHomePage": "Create interactive stories with your own characters",
          "textHomePage": "Let our artificial intelligence interpret your characters' lines and live unique adventures.",
          "PleaseFillFields": "Please fill all fields.",
          "PhoneAlreadyUsedOrNonExistant": "This phone number is already used or non existant.",
          "CreateNewContact": "Please fill all fields.",
          "Name": "Name",
          "EnterNameContact": "Enter the name of the contact",
          "DefaultName": "John Doe",
          "PhoneNumber": "Phone number",
          "PlaceholderPhoneNumber": "Phone number +33900000000",
          "Cancel": "Cancel",
          "Create": "Create",
          "Edit": "Save changes",
          "NameOfStory": "Title of the story",
          "NameOfStoryPlaceholder": "Name of the story",
          "AddToDatabase": "Add the story (Don't worry, you can still edit it later)",
          "LoadStory": "Load the story",
          "ContactInformation": "Contact information",
          "ContactAcquaintances": "Characters known",
          "KnownInformation": "Known information",
          "AcquaintanceKnowledge": "Enter knowledge about him/her",
          "User": "User",
          "Number": "Number",
          "EnterNumber": "Enter the number",
          "Age": "Age",
          "EnterAge": "Enter the age",
          "Gender": "Gender",
          "EnterGender": "Enter the gender",
          "Male": "Man",
          "Female": "Woman",
          "OtherGender": "Unspecified",
          "CharacterTraits": "Character traits (serious, joking, charismatic...)",
          "EnterCharacterTraits": "Enter the character traits (serious, joking, charismatic...)",
          "PhysicalFeatures": "Physical features",
          "EnterPhysicalFeatures": "Enter the physical features",
          "Particularities": "Particularities (language tic, mania, addiction...)",
          "EnterParticularities": "Enter the other particularities (language tic, mania, addiction...)",
          "CharacterIcon": "Icon",
          "Interactive": "Interactive",
          "InitialContact": "Initial contact",
          "MessageText": "Message Text",
          "EnterMessageText": "Type your message here",
          "Author": "Author",
          "EnterAuthorName": "Author's name",
          "AssistantAuthorChoice": "Your New Character",
          "UserAuthorChoice": "The User",
          "Date": "Date",
          "EnterDate": "Date (e.g., 2024-01-29)",
          "Time": "Time",
          "EnterTime": "Time (e.g., 14:30)",
          "AddMessage": "Add Message",
          "GenerateJSON": "Generate the JSON",
          "GeneralParameters": "General parameters",
          "NewStoryTitle": "My new amazing interactive story",
          "StoryLanguage": "Language",
          "EnterStoryLanguage": "Enter the language of the story",
          "French": "French",
          "StoryContext": "Context",
          "EnterStoryContext": "Example : L'histoire se déroule en 2015, à Naples, en Italie. Carlo Rossi est un mafieux au sein de Il Giorno, une mafia italienne. Après plusieurs années passées à Naples au sein de la branche locale d'Il Giorno dont Salvatore Bianchi est le chef, il a fini par rejoindre la branche romaine d'Il Giorno et y devenir l'un des hommes les plus influents. Un jour où il revient à Naples pour régler une affaire, Salvatore Bianchi est assassiné et Carlo se fait arrêter sans savoir pourquoi.",
          "StoryDate": "Start date of the plot",
          "EnterStoryDate": "Exemple : 2015-07-11",
          "StoryAccess": "Access",
          "PublicAccess": "Public",
          "LinkAccess": "Unreferenced",
          "LimitedAccess": "Limited",
          "EnterStoryAccess": "Choose how people can access this story",
          "StoryFinancialStatus": "Financial status",
          "EnterStoryFinancialStatus": "Choose a financial status",
          "TokenUser": "Paying access for the user",
          "StoryDefaultModel": "Default model",
          "EnterStoryDefaultModel": "Choose a model",
          "StoryHomeMessage": "Greeting message for the user",
          "EnterStoryHomeMessage": "Example : Welcome in Giustizia per Telefono !",
          "StoryThumbnail": "Thumbnail of the story",
          "StoryMusic": "Background music",
          "MainCharacter": "Main character",
          "ErrorForfaitSoldout": "Error: Forfait sold out",
          "ConversationWith": "Conversation with ",
          "SendMessage": "Write a message...",
          "Email": "Email",
          "Password": "Password",
          "DefaultModel": "Default Model",
          "CurrentlyUsedModel": "Currently used model",
          "APIKey": "API Key",
          "EnterAPIKey": "Enter API Key",
          "AIModel": "AI Model",
          "YouNotSignedIn": "You are not signed in",
          "StoriaDescription": "The world first interactive storytelling platform. Rediscover classic storys or Create your own characters and let our AI interpret their lines. Live unique and personnalized adventures and share them with the world.",
          "AproposDeNous": "About us",
          "ConctactUs": "Contact us",
          "ChoseRightModelForYou": "Choose the right model for you",
          "Documentation": "Documentation",
          "GettingStarted": "Getting started",
          "DocumentationVideo": "Documentation video",
          "DocumentationText": "Documentation text",
          "DocumentationFreeVersion": "Documentation how to use free version",
          "Tokens": "Tokens",
          "Contacts": "Contacts",
          "AllStories": "All Stories",
          "NoStoriesFound": "No stories found.",
          "StoriesIAlreadyStarted": "Stories I already started",
          "MyPublicStories": "My public stories",
          "StartCreatingNewStory": "Start by creating a new story!",
          "Drafts": "Drafts",
          "CreateNewStory": "Create New Story",
          "Profile": "Profile",
          "Settings": "Settings",
          "AISettings": "AI Settings",
          "SelectAI": "Select an AI",
          "Receivers": "Receivers",
          "Senders": "Senders",
          "SelectReceivers": "Select receivers",
          "SelectSenders": "Select senders",
          "Messages": "Messages",
          "NonInitialContacts": "Non Initial Contacts",
          "InitialContacts": "Initial Contacts",
          "MadeByStoria": "Recommanded by Fablia",
          "PleaseConnectToAccessPage": "Please connect to access this page",
          "ContactNumNotNumericError": "The contact number must be a numeric value",
          "ContactNumAlreadyExistError": "The phone number is already assigned to another contact",
          "NegativeAgeError": "The age must be a positive number",
          "NotIntegerAgeError": "The age must be an integer",
          "ContactNameEmptyError": "The contact name must not be empty",
          "StoryContextEmptyError": "The context cannot be empty",
          "StoryTitleEmptyError": "The story title cannot be empty",
          "DownloadLocalAI": "Please download et install the local AI by clicking here",
          "AdvancedOptions": "Advanced Options",
          "NarrativeParameters": "Narrative Parameters",
          "Pupup_ConfirmDelete": "Confirm",
          "Popup_TextConfirmDelete": "Are you sure you want to delete this story? This action is irreversible.",
          "Popup_TextConfirmDeleteAlreadyStarted": "Are you sure you want to delete this save? This action is irreversible.",
          "Popup_Play": "Play",
          "Popup_Edit": "Edit",
          "Popup_Duplicate": "Duplicate and Edit",
          "Popup_GeneralParameters_MainCharacter": "The character played by the player during conversations with contacts",
          "Popup_GeneralParameters_DefaultModel": "Default AI model selected when a user starts the story. It is used to embody characters and respond to messages. The user can individually change it if desired.",
          "Popup_GeneralParameters_StartDate": "Upon the user's first launch of the story, this date is considered the current date by the various characters. It also serves as a starting point for events that may be triggered based on the in-game date.",
          "Popup_GeneralParameters_CharacterTraits": "You can add character traits (serious, humorous, charismatic, etc.) to try to give the character a more distinct personality. Note that if you want these character traits to be known by all or some of the other contacts, you will still need to mention them in what the respective contact knows about this character.",
          "Popup_GeneralParameters_PhysicalFeatures": "You can add physical traits to try to give the character a better coherence between the different descriptions they might give of themselves, as well as with any images or videos representing them that you may have provided. Note that if you want these physical characteristics to be known by all or some of the other contacts, you will still need to mention them in what the respective contact knows about this character.",
          "Popup_GeneralParameters_Particularities": "You can add any other peculiarities about the character here, whether it's a tic, an addiction, or a particular role in the context, such as being the leader of the mafia or regularly having tea with colleagues' wives. Note that if you want these particularities to be known by all or some of the other contacts, you will still need to mention them in what the respective contact knows about this character.",
          "Popup_GeneralParameters_StoryHomeMessage": "This message will be displayed to the user when they launch this story for the first time. You can use it to present the story's context, how to play, who you are, or just to greet them!",
          "Popup_GeneralParameters_StoryContext": "The context of your story corresponds to the global information known by all characters. This can be the location where the action takes place, the date, social conventions, or any information you deem relevant. Note that the more complete the context is, the more effective the AI model will be, but if it is too overloaded, the model will assign less importance to each element.",
          "Popup_GeneralParameters_NarrativeParameters": "These parameters correspond to how characters respond when the story is launched.",
          "Popup_GeneralParameters_RessourcesComplexes": "This menu allows you to upload files (images, video, audio etc) in order to incorporate them with the syntax #%x%# where x is the index of the resource you want to display",
          "Popup_ContactForm_CharacterTraits": "You can add character traits (serious, humorous, charismatic, etc.) to try to give the character a more distinct personality. Note that if you want these character traits to be known by all or some of the other contacts, you will still need to mention them in what the respective contact knows about this character.",
          "Popup_ContactForm_PhysicalFeatures": "You can add physical traits to try to give the character a better coherence between the different descriptions they might give of themselves, as well as with any images or videos representing them that you may have provided. Note that if you want these physical characteristics to be known by all or some of the other contacts, you will still need to mention them in what the respective contact knows about this character.",
          "Popup_ContactForm_Particularities": "You can add any other peculiarities about the character here, whether it's a tic, an addiction, or a particular role in the context, such as being the leader of the mafia or regularly having tea with colleagues' wives. Note that if you want these particularities to be known by all or some of the other contacts, you will still need to mention them in what the respective contact knows about this character.",
          "Popup_ContactForm_InitialContact": "Indicate whether the contact is initially available when the story is launched. If the contact is not initially available, the user can unlock it by entering the associated phone number.",
          "Popup_ContactForm_Number": "This number allows making a non-initial contact available for discussion later in the game if it is known by other characters and discovered by the user. It also serves as a unique identifier for each character.",
          "Popup_ContactForm_Interactive": "It is only possible to interact with interactive contacts. Non-interactive contacts are intended to provide more context and credibility to the story, representing, for example, surveillance camera alerts or spams.",
          "Popup_ContactForm_KnownInformation": "Describe here everything that the character being created knows or believes they know about this character, including information already mentioned in this character's creation form.",
          "Popup_ContactForm_ContactAcquaintances": "Check each character that the character being created knows at least by name.",
          "Popup_Settings_APIKey": "The API key is what currently allows you to converse with story characters, except if you are using a local model. It is unique to you and differs depending on the models. To obtain one, you must register with the service of the chosen model company and then follow the displayed instructions. For GPT (3.5 and 4), go to https://platform.openai.com/api-keys. For Mistral (7B, 8x7B, Tiny, Small, Medium, and Large), go to https://console.mistral.ai/api-keys/.",
          "Popup_Settings_AIModel": "The more powerful the models, the more realistic and coherent the responses provided by the characters will be, but the production of these responses will be long and costly. Therefore, we recommend that you test different models offered to establish which level of quality suits you. Today, Mistral Large and GPT-4 are the most powerful models offered. AI models are the basic components that allow different characters to respond to you. These are LLM (for Large Language Model), based on neural networks and capable of reproducing a natural response style by predicting the most likely words to complete the text one by one. They have been trained automatically on a huge amount of data. If you want to learn more, we recommend the article https://en.wikipedia.org/wiki/Large_language_model.",
          "RessourcesComplexes": "Complex Resources",
          "DropFilesHere": "Drop the files here ...",
          "DragNDropFilesOrClick": "Drag 'n' drop some files here, or click to select files",
          "OpenAIAPIKeyInstruction": "# OpenAI API Key Instruction\n\nTo use an OpenAI model, you will need to obtain an API key from the OpenAI website. \nYou can sign up for an OpenAI account and generate an API key by following these steps:\n\n1. Go to the [OpenAI website](https://openai.com/) and create an account, if you haven't already.\n2. Log in to your OpenAI account and navigate to the 'API Keys' section.\n3. Click on the 'Create new secret key' button to generate a new API key.\n4. Copy the generated API key and use it in your application to authenticate with the OpenAI API.",
          "AnthropicAPIKeyInstruction": "# Anthropic API Key Instruction\n\nTo use an Anthropic model, you will need to obtain an API key from the Anthropic website. \nYou can sign up for an Anthropic account and generate an API key by following these steps:\n\n1. Go to the [Anthropic website](https://www.anthropic.com/) and create an account, if you haven't already.\n2. Log in to your Anthropic account and navigate to the 'API' section.\n3. Click on the 'Create new API key' button to generate a new API key.\n4. Copy the generated API key and use it in your application to authenticate with the Anthropic API.",
          "MistralAPIKeyInstruction": "# Mistral API Key Instruction\n\nTo use a Mistral model, you will need to obtain an API key from the Mistral website. \nYou can sign up for a Mistral account and generate an API key by following these steps:\n\n1. Go to the [Mistral website](https://www.mistral.ai/) and create an account, if you haven't already.\n2. Log in to your Mistral account and navigate to the 'API' section.\n3. Click on the 'Create new API key' button to generate a new API key.\n4. Copy the generated API key and use it in your application to authenticate with the Mistral API.",
          "GroqAPIKeyInstruction": "# Groq API Key Instruction\n\nTo use a Groq model, you will need to obtain an API key from the Groq website. \nYou can sign up for a Groq account and generate an API key by following these steps:\n\n1. Go to the [Groq website](https://www.groq.com/) and create an account, if you haven't already.\n2. Log in to your Groq account and navigate to the 'API' section.\n3. Click on the 'Create new API key' button to generate a new API key.\n4. Copy the generated API key and use it in your application to authenticate with the Groq API.",
          "DeepSeekAPIKeyInstruction": "# DeepSeek API Key Instruction\n\nTo use a DeepSeek model, you will need to obtain an API key from the DeepSeek website. \nYou can sign up for a DeepSeek account and generate an API key by following these steps:\n\n1. Go to the [DeepSeek website](https://www.deepseek.com/) and create an account, if you haven't already.\n2. Log in to your DeepSeek account and navigate to the 'API' section.\n3. Click on the 'Create new API key' button to generate a new API key.\n4. Copy the generated API key and use it in your application to authenticate with the DeepSeek API.",
          "MyPrivateStories" : "My private stories", 
          "FirstCreateContact":"First create a contact",
          "FirstCreateMessage":"First create a message",
          "CreateEvent": "Create an event",
          "EventDescription": "Event description",
          "EnterEventDescription": "Enter the event description",
          "EventDuration": "Event duration",
          "EventDurationDays": "Days",
          "EnterEventDurationDays": "Enter the number of days",
          "EventDurationHours": "Hours",
          "EnterEventDurationHours": "Enter the number of hours",
          "EventDurationMinutes": "Minutes",
          "EnterEventDurationMinutes": "Enter the number of minutes",
          "EventReceivers": "Characters who have experienced the event",
          "SelectEventReceivers": "Select the characters who have experienced the event",
          "EventTrigger": "Event trigger mode",
          "SelectEventTrigger": "Select the event trigger mode",
          "EventStartDate": "Event start date",
          "EnterEventStartDate": "Enter the event start date",
          "EventStartTime": "Event start time",
          "EnterEventStartTime": "Enter the event start time",
          "EventNbMessages": "Number of messages to exchange to trigger the event",
          "EnterEventNbMessages": "Enter the number of messages to exchange to trigger the event",
          "EventNbMessageCharacters": "Characters concerned",
          "SelectEventNbMessageCharacters": "Select the characters concerned by the message exchange",
          "NewEvent": "New event",
          "PhoneNumberKnown": "Phone number known",
          "AddEvent": "Add",
          "SaveDate": "Saved the",
          "CreationDate": "Created the",
          "Popup_TextConfirmDeleteEvent": "Are you sure you want to delete this event? This action is irreversible.",
          "PopupEventItem_Receivers": "Characters concerned",
          "PopupEventItem_Date": "Date of beginning",
          "PopupEventItem_Duration": "Duration",
          "PopupEventItem_NbMessages": "Number of messages to exchange to trigger the event",
          "PopupEventItem_Characters": "Characters concerned by the message exchange",
          "PopupEventItem_Description": "Description",
          "at": "at",
          "days": "days",
          "hours": "hours",
          "minutes": "minutes",
          "and": "and",
          "AddNewEvent": "Add an event",
          "Error": "Error",
          "Player": " (Player)",
          "No description": "No description",
          "Continue": "Continue",
          "PopUp_StoryInformation": "Read the story teaser again",
          "PopUp_SaveStory": "Save your progression",
          "PopUp_Music_On": "Put off the sound",
          "PopUp_Music_Off": "Put on the sound",
          "PopUp_FullScreen_On": "Full screen",
          "PopUp_FullScreen_Off": "Exit full screen",
          "PopUp_AddContact": "Add a contact",
          "NbMessages": "Number of messages",
          "EventConsequenceHeader": "Consequence",
          "EventTriggerHeader": "Trigger",
          "EventEndStoryMessage": "Message to display to the player",
          "EnterEventEndStoryMessage": "Enter the message to display to the player at the end of the story when it was provoked by the event",
          "EventConsequenceType": "Consequence type",
          "SelectEventConsequenceType": "Select the consequence type",
          "Information": "Information",
          "EndStory": "End of the story",
          "ExplanationContinueAfterEnd": "Despite the end of the story, you can continue to discuss with the characters.",
          "ContinueAfterEnd": "Continue to discuss",
          "PopupEventItem_EndStoryMessage": "Message",
          "LeaveStory": "Leave",
        }
      },
      fr: {
        translation: {
          "Home": "Accueil",
          "Story Mode": "Mode Histoire",
          "Save": "Enregistrer",
          "Creation Mode": "Mode Création",
          "Select a Story": "Sélectionner une histoire",
          "SignIn": "Se connecter",
          "LogIn": "Se connecter",
          "SignUp": "S'inscrire",
          "SignOut": "Se déconnecter",
          "LogInGoogle": "Se connecter avec Google",
          "SignUpGoogle": "S'inscrire avec Google",
          "NoAccount": "Vous n'avez pas de compte ?",
          "AlreadyAccount": "Vous avez déjà un compte ?",
          "headerHomePage": "Créez des histoires interactives avec vos propres personnages",
          "textHomePage": "Laissez notre intelligence artificielle interpréter les répliques de vos personnages et vivez des aventures uniques.",
          "PleaseFillFields": "Veuillez remplir tous les champs.",
          "PhoneAlreadyUsedOrNonExistant": "Ce numéro de téléphone est déjà utilisé ou n'existe pas.",
          "CreateNewContact": "Veuillez remplir tous les champs.",
          "Name": "Nom",
          "EnterNameContact": "Entrez le nom du contact",
          "DefaultName": "John Doe",
          "PhoneNumber": "Numéro de téléphone",
          "PlaceholderPhoneNumber": "Numéro de téléphone +33900000000",
          "Cancel": "Annuler",
          "Create": "Créer",
          "Edit": "Valider",
          "NameOfStory": "Titre de l'histoire",
          "NameOfStoryPlaceholder": "Titre de l'histoire",
          "AddToDatabase": "Ajouter l'histoire (Ne vous inquiétez pas, vous pourrez toujours la modifier plus tard)",
          "LoadStory": "Charger l'histoire",
          "ContactInformation": "Informations de contact",
          "ContactAcquaintances": "Personnages connus",
          "KnownInformation": "Informations connues",
          "AcquaintanceKnowledge": "Entrez des informations le concernant",
          "User": "Utilisateur",
          "Number": "Numéro",
          "EnterNumber": "Entrez le numéro",
          "Age": "Âge",
          "EnterAge": "Entrez l'âge",
          "Gender": "Genre",
          "EnterGender": "Entrez le genre",
          "Male": "Homme",
          "Female": "Femme",
          "OtherGender": "Autre",
          "CharacterTraits": "Traits de caractère",
          "EnterCharacterTraits": "Entrez les traits de caractère (sérieux, blagueur, charismatique...)",
          "PhysicalFeatures": "Caractéristiques physiques",
          "EnterPhysicalFeatures": "Entrez les caractéristiques physiques",
          "Particularities": "Particularités (tic de langage, rôle particulier, manie, addiction...)",
          "EnterParticularities": "Entrez les particularités (tic de langage, manie, addiction...)",
          "CharacterIcon": "Icône",
          "Interactive": "Interactif",
          "InitialContact": "Contact initial",
          "MessageText": "Texte du message",
          "EnterMessageText": "Saisissez votre message ici",
          "Author": "Auteur",
          "EnterAuthorName": "Nom de l'auteur",
          "AssistantAuthorChoice": "Votre Nouveau Personnage",
          "UserAuthorChoice": "L'Utilisateur",
          "Date": "Date",
          "EnterDate": "Date (exemple : 2024-01-29)",
          "Time": "Heure",
          "EnterTime": "Heure (exemple : 14:30)",
          "AddMessage": "Ajouter un message",
          "GenerateJSON": "Générer le JSON",
          "GeneralParameters": "Paramètres généraux",
          "NewStoryTitle": "Mon incroyable histoire interactive",
          "StoryLanguage": "Langue",
          "EnterStoryLanguage": "Entrez la langue de l'histoire",
          "French": "Français",
          "StoryContext": "Contexte",
          "EnterStoryContext": "Exemple : L'histoire se déroule en 2015, à Naples, en Italie. Carlo Rossi est un mafieux au sein de Il Giorno, une mafia italienne. Après plusieurs années passées à Naples au sein de la branche locale d'Il Giorno dont Salvatore Bianchi est le chef, il a fini par rejoindre la branche romaine d'Il Giorno et y devenir l'un des hommes les plus influents. Un jour où il revient à Naples pour régler une affaire, Salvatore Bianchi est assassiné et Carlo se fait arrêter sans savoir pourquoi.",
          "StoryDate": "Date de début de l'intrigue",
          "EnterStoryDate": "Exemple : 2015-07-11",
          "StoryAccess": "Accès",
          "PublicAccess": "Public",
          "LinkAccess": "Non référencé",
          "LimitedAccess": "Limité",
          "EnterStoryAccess": "Choisissez comment les gens peuvent accéder à cette histoire",
          "StoryFinancialStatus": "Statut financier",
          "EnterStoryFinancialStatus": "Choisissez un statut financier",
          "TokenUser": "Accès payant pour l'utilisateur",
          "StoryDefaultModel": "Modèle par défaut",
          "EnterStoryDefaultModel": "Choisissez un modèle",
          "StoryHomeMessage": "Message d'accueil pour l'utilisateur",
          "EnterStoryHomeMessage": "Exemple : Bienvenue dans Giustizia per Telefono !",
          "StoryThumbnail": "Miniature de l'histoire",
          "StoryMusic": "Musique de fond",
          "MainCharacter": "Personnage principal",
          "ErrorForfaitSoldout": "Erreur : Forfait épuisé",
          "ConversationWith": "Conversation avec ",
          "SendMessage": "Écrire un message...",
          "Email": "Courriel",
          "Password": "Mot de passe",
          "DefaultModel": "Modèle par défaut",
          "CurrentlyUsedModel": "Modèle actuellement utilisé",
          "APIKey": "Clé API",
          "EnterAPIKey": "Entrez la clé API",
          "AIModel": "Modèle IA",
          "YouNotSignedIn": "Vous n'êtes pas connecté",
          "StoriaDescription": "La première plateforme de narration interactive au monde. Redécouvrez les histoires classiques ou créez vos propres personnages et laissez notre IA interpréter leurs répliques. Vivez des aventures uniques et personnalisées et partagez-les avec le monde.",
          "AproposDeNous": "À propos de nous",
          "ConctactUs": "Contactez-nous",
          "ChoseRightModelForYou": "Choisissez le bon modèle pour vous",
          "Documentation": "Documentation",
          "GettingStarted": "Prise en main",
          "DocumentationVideo": "Documentation vidéo",
          "DocumentationText": "Documentation texte",
          "DocumentationFreeVersion": "Documentation pour utiliser la version gratuite",
          "Tokens": "Tokens",
          "Contacts": "Contacts",
          "AllStories": "Toutes les histoires",
          "NoStoriesFound": "Aucune histoire trouvée.",
          "StoriesIAlreadyStarted": "Histoires que j'ai déjà commencées",
          "MyPublicStories": "Mes histoires publiques",
          "StartCreatingNewStory": "Commencez par créer une nouvelle histoire !",
          "Drafts": "Brouillons",
          "CreateNewStory": "Créer une nouvelle histoire",
          "Profile": "Profil",
          "Settings": "Paramètres",
          "AISettings": "Paramètres de l'IA",
          "SelectAI": "Sélectionner une IA",
          "Receivers": "Destinataires",
          "Senders": "Expéditeurs",
          "SelectReceivers": "Sélectionner les destinataires",
          "SelectSenders": "Sélectionner les expéditeurs",
          "Messages": "Messages",
          "NonInitialContacts": "Contacts non initiaux",
          "InitialContacts": "Contacts initiaux",
          "MadeByStoria": "Recommandé par Fablia",
          "ContactNumNotNumericError": "Le numéro de contact doit être une valeur numérique",
          "ContactNumAlreadyExistError": "Le numéro de téléphone est déjà attribué à un autre contact",
          "NegativeAgeError": "L'âge doit être un nombre positif",
          "NotIntegerAgeError": "L'âge doit être un nombre entier",
          "ContactNameEmptyError": "Le nom du contact ne doit pas être vide",
          "PleaseConnectToAccessPage": "Veuillez vous connecter pour accéder à cette page",
          "DownloadLocalAI": "Veuillez télécharger et installer l'IA locale en cliquant ici",
          "AdvancedOptions": "Options avancées",
          "NarrativeParameters": "Paramètres narratifs",
          "Pupup_ConfirmDelete": "Confirmer",
          "Popup_TextConfirmDelete": "Êtes-vous sûr de vouloir supprimer cette histoire ? Cette opération est irréversible.",
          "Popup_TextConfirmDeleteAlreadyStarted": "Êtes-vous sûr de vouloir supprimer cette sauvegarde ? Cette opération est irréversible.",
          "Popup_Play": "Jouer",
          "Popup_Edit": "Modifier",
          "Popup_Duplicate": "Dupliquer et modifier",
          "Popup_GeneralParameters_MainCharacter": "Le personnage incarné par le joueur lors des discussions avec les contacts",
          "Popup_GeneralParameters_DefaultModel": "Modèle d'IA sélectionné par défaut lorsqu'un utilisateur lance l'histoire. Il est utilisé pour incarner les personnages et répondre aux messages. L'utilisateur peut en changer individuellement s'il le souhaite.",
          "Popup_GeneralParameters_StartDate": "Au premier lancement de l'histoire par l'utilisateur, cette date est considérée comme la date actuelle par les différents personnages. Elle fait aussi office de point de départ pour les évènements pouvant être déclenchés en fonction de la date en jeu.",
          "Popup_GeneralParameters_CharacterTraits": "Vous pouvez ajouter des traits de caractère (sérieux, blagueur, charismatique...) pour tenter de donner une personnalité plus marquée au personnage. Attention, si vous souhaitez que ces traits de caractères soient connus de tous ou de certains des autres contacts, il faudra tout de même les mentionner dans ce que le contact en question sait de ce personnage-ci.",
          "Popup_GeneralParameters_PhysicalFeatures": "Vous pouvez ajouter des traits de caractère pour tenter de donner une meilleure cohérence au personnage, entre les différentes descriptions qu'il pourrait faire de lui-même, mais aussi vis-à-vis des éventuels images ou vidéos le représentant que vous pourriez avoir fourni. Attention, si vous souhaitez que ces caractéristiques physiques soient connus de tous ou de certains des autres contacts, il faudra tout de même les mentionner dans ce que le contact en question sait de ce personnage-ci.",
          "Popup_GeneralParameters_Particularities": "Vous pouvez ajouter ici n'importe quelle autre particularité concernant le personnage, que cela soit un tic, une addiction ou un rôle particulier dans le contexte, par exemple qu'il soit le chef de la mafia ou qu'il prenne régulièrement le thé avec les femmes de ses collègues. Attention, si vous souhaitez que ces particularités soient connus de tous ou de certains des autres contacts, il faudra tout de même les mentionner dans ce que le contact en question sait de ce personnage-ci.",
          "Popup_GeneralParameters_StoryHomeMessage": "Ce message sera affiché à l'utilisateur lorsqu'il lancera cette histoire pour la première fois. Vous pouvez vous en servir pour lui présenter le contexte de l'histoire, la manière de jouer, qui vous êtes ou encore juste pour le saluer !",
          "Popup_GeneralParameters_StoryContext": "Le contexte de votre histoire correspond aux informations globales, qui sont connues par tous les personnages. Cela peut être le lieu où se déroule l'action, la date, les conventions sociales ou n'importe quelle information que vous jugez pertinente. Attention, plus le contexte est complet, plus le modèle d'IA sera performant, mais s'il est trop chargé le modèle attribuera moins d'importance à chaque élément.",
          "Popup_GeneralParameters_NarrativeParameters": "Ces paramètres correspondent à la manière dont les personnages répondent lorsque l'histoire est lancée.",
          "Popup_GeneralParameters_RessourcesComplexes": "Ce menu vous permet d'uploader des fichiers (images, video, audio etc) afin de les incorporer avec la syntaxe #%x%# où x est l'index de la ressource que vous souhaitez afficher",
          "Popup_ContactForm_CharacterTraits": "Vous pouvez ajouter des traits de caractère (sérieux, blagueur, charismatique...) pour tenter de donner une personnalité plus marquée au personnage. Attention, si vous souhaitez que ces traits de caractères soient connus de tous ou de certains des autres contacts, il faudra tout de même les mentionner dans ce que le contact en question sait de ce personnage-ci.",
          "Popup_ContactForm_PhysicalFeatures": "Vous pouvez ajouter des traits de caractère pour tenter de donner une meilleure cohérence au personnage, entre les différentes descriptions qu'il pourrait faire de lui-même, mais aussi vis-à-vis des éventuels images ou vidéos le représentant que vous pourriez avoir fourni. Attention, si vous souhaitez que ces caractéristiques physiques soient connus de tous ou de certains des autres contacts, il faudra tout de même les mentionner dans ce que le contact en question sait de ce personnage-ci.",
          "Popup_ContactForm_Particularities": "Vous pouvez ajouter ici n'importe quelle autre particularité concernant le personnage, que cela soit un tic, une addiction ou un rôle particulier dans le contexte, par exemple qu'il soit le chef de la mafia ou qu'il prenne régulièrement le thé avec les femmes de ses collègues. Attention, si vous souhaitez que ces particularités soient connus de tous ou de certains des autres contacts, il faudra tout de même les mentionner dans ce que le contact en question sait de ce personnage-ci.",
          "Popup_ContactForm_InitialContact": "Indique si le contact est ou non disponible lorsque l'histoire est lancée pour la première fois. Si le contact n'est pas disponible initialement, il pourra être débloqué par l'utilisateur en entrant le numéro de téléphone qui lui est associé.",
          "Popup_ContactForm_Number": "Ce numéro permet de rendre disponible à la discussion un contact non initial plus tard dans la partie s'il est connu par d'autres personnages et que l'utilisateur le découvre. Il sert aussi d'identifiant unique à chaque personnage.",
          "Popup_ContactForm_Interactive": "Il n'est possible de discuter qu'avec les contacts interactifs. Les contacts non interactifs ont été pensé pour donner plus de contexte et de vraisemblance à l'histoire, en représentant par exemple des alertes de caméra de surveillance ou des spams.",
          "Popup_ContactForm_KnownInformation": "Décrivez ici tout ce que le personnage en cours de création connaît ou croit connaître de ce personnage, y compris les informations déjà mentionné dans le formulaire de création de ce dernier.",
          "Popup_ContactForm_ContactAcquaintances": "Cochez chaque personnage que le personnage en cours de création connaît au moins de nom.",
          "Popup_Settings_APIKey": "La clé API est ce qui vous permet pour l'instant de converser avec les personnages des histoires, excepté si vous utilisez un modèle local. Elle vous est propre et diffère en fonction des modèles. Pour en obtenir une, vous devez vous inscrire sur le service de la compagnie du modèle choisie puis suivre les instructions affichées. Pour GPT (3.5 et 4), rendez-vous sur https://platform.openai.com/api-keys. Pour Mistral (7B, 8x7B, Tiny, Small, Medium et Large), rendez-vous sur https://console.mistral.ai/api-keys/.",
          "Popup_Settings_AIModel": "Plus les modèles sont puissants, plus les réponses fournies par les personnages seront réalistes et cohérentes mais la production de ces réponses sera longue et coûteuse. Nous vous conseillons donc de tester différents modèles proposés pour établir quel niveau de qualité vous convient. Aujourd'hui, Mistral Large et GPT-4 sont les modèles les plus puissants proposés. Les modèles d'IA sont les composants de base qui permettent aux différents personnages de vous répondre. Il s'agit de LLM (pour Large Language Model, en français Grand Modèle de Language), basés sur le principe des réseaux de neurones et capables de reproduire un style de réponse naturel en prédisant un à un les mots les plus probables pour compléter le texte. Ils ont pour cela été entraînés automatiquement sur une immense quantité de données. Si vous souhaitez en apprendre plus, nous vous conseillons l'article https://fr.wikipedia.org/wiki/Grand_mod%C3%A8le_de_langage.",
          "RessourcesComplexes": "Ressources Complexes",
          "DropFilesHere": "Déposez les fichiers ici ...",
          "DragNDropFilesOrClick": "Faites glisser et déposez des fichiers ici, ou cliquez pour sélectionner des fichiers",
          "OpenAIAPIKeyInstruction": "Instruction de la clé API OpenAI : \nPour utiliser un modèle OpenAI, vous devrez obtenir une clé API à partir du site Web d'OpenAI. \nVous pouvez vous inscrire à un compte OpenAI et générer une clé API en suivant ces étapes : \n1. Allez sur le site Web d'OpenAI (https://openai.com/) et créez un compte, si vous n'en avez pas déjà un. \n2. Connectez-vous à votre compte OpenAI et accédez à la section 'Clés API'. \n3. Cliquez sur le bouton 'Créer une nouvelle clé secrète' pour générer une nouvelle clé API. \n4. Copiez la clé API générée et utilisez-la dans votre application pour vous authentifier auprès de l'API OpenAI.",
          "AnthropicAPIKeyInstruction": "Instruction de la clé API Anthropic : \nPour utiliser un modèle Anthropic, vous devrez obtenir une clé API à partir du site Web d'Anthropic. \nVous pouvez vous inscrire à un compte Anthropic et générer une clé API en suivant ces étapes : \n1. Allez sur le site Web d'Anthropic (https://www.anthropic.com/) et créez un compte, si vous n'en avez pas déjà un. \n2. Connectez-vous à votre compte Anthropic et accédez à la section 'API'. \n3. Cliquez sur le bouton 'Créer une nouvelle clé API' pour générer une nouvelle clé API. \n4. Copiez la clé API générée et utilisez-la dans votre application pour vous authentifier auprès de l'API Anthropic.",
          "MistralAPIKeyInstruction": "Instruction de la clé API Mistral : \nPour utiliser un modèle Mistral, vous devrez obtenir une clé API à partir du site Web de Mistral. \nVous pouvez vous inscrire à un compte Mistral et générer une clé API en suivant ces étapes : \n1. Allez sur le site Web de Mistral (https://www.mistral.ai/) et créez un compte, si vous n'en avez pas déjà un. \n2. Connectez-vous à votre compte Mistral et accédez à la section 'API'. \n3. Cliquez sur le bouton 'Créer une nouvelle clé API' pour générer une nouvelle clé API. \n4. Copiez la clé API générée et utilisez-la dans votre application pour vous authentifier auprès de l'API Mistral.",
          "GroqAPIKeyInstruction": "Instruction de la clé API Groq : \nPour utiliser un modèle Groq, vous devrez obtenir une clé API à partir du site Web de Groq. \nVous pouvez vous inscrire à un compte Groq et générer une clé API en suivant ces étapes : \n1. Allez sur le site Web de Groq (https://www.groq.com/) et créez un compte, si vous n'en avez pas déjà un. \n2. Connectez-vous à votre compte Groq et accédez à la section 'API'. \n3. Cliquez sur le bouton 'Créer une nouvelle clé API' pour générer une nouvelle clé API. \n4. Copiez la clé API générée et utilisez-la dans votre application pour vous authentifier auprès de l'API Groq.",
          "DeepSeekAPIKeyInstruction": "Instruction de la clé API DeepSeek : \nPour utiliser un modèle DeepSeek, vous devrez obtenir une clé API à partir du site Web de DeepSeek. \nVous pouvez vous inscrire à un compte DeepSeek et générer une clé API en suivant ces étapes : \n1. Allez sur le site Web de DeepSeek (https://www.DeepSeek.com/) et créez un compte, si vous n'en avez pas déjà un. \n2. Connectez-vous à votre compte DeepSeek et accédez à la section 'API'. \n3. Cliquez sur le bouton 'Créer une nouvelle clé API' pour générer une nouvelle clé API. \n4. Copiez la clé API générée et utilisez-la dans votre application pour vous authentifier auprès de l'API DeepSeek.",
          "MyPrivateStories" : "Mes histoires privées",
          "FirstCreateContact":"Pour commencer, créez un contact",
          "FirstCreateMessage":"Pour commencer, créez un message", 
          "CreateEvent": "Créer un événement",
          "EventDescription": "Description de l'événement",
          "EnterEventDescription": "Entrez la description de l'événement",
          "EventDuration": "Durée de l'événement",
          "EventDurationDays": "Jours",
          "EnterEventDurationDays": "Entrez le nombre de jours",
          "EventDurationHours": "Heures",
          "EnterEventDurationHours": "Entrez le nombre d'heures",
          "EventDurationMinutes": "Minutes",
          "EnterEventDurationMinutes": "Entrez le nombre de minutes",
          "EventReceivers": "Personnages ayant vécu l'évènement",
          "SelectEventReceivers": "Sélectionnez les personnages ayant vécu l'évènement",
          "EventTrigger": "Mode de déclencheur de l'événement",
          "SelectEventTrigger": "Sélectionnez le mode de déclencheur de l'événement",
          "EventStartDate": "Date de début de l'événement",
          "EnterEventStartDate": "Entrez la date de début de l'événement",
          "EventStartTime": "Heure de début de l'événement",
          "EnterEventStartTime": "Entrez l'heure de début de l'événement",
          "EventNbMessages": "Nombre de messages à échanger",
          "EnterEventNbMessages": "Entrez le nombre de messages à échanger pour déclencher l'évènement",
          "EventNbMessageCharacters": "Personnages concernés",
          "SelectEventNbMessageCharacters": "Sélectionnez les personnages concernés par l'échange de messages",
          "NewEvent": "Nouvel événement",
          "PhoneNumberKnown": "Numéro de téléphone connu",
          "AddEvent": "Ajouter",
          "SaveDate": "Sauvegardé le",
          "CreationDate": "Créé le",
          "Popup_TextConfirmDeleteEvent": "Êtes-vous sûr de vouloir supprimer cet événement ? Cette opération est irréversible.",
          "PopupEventItem_Receivers": "Personnages concernés ",
          "PopupEventItem_Date": "Date de début ",
          "PopupEventItem_NbMessages": "Nombre de messages à échanger pour déclencher l'évènement ",
          "PopupEventItem_NbMessagesCharacters": "Personnages concernés par les échanges de messages ",
          "PopupEventItem_Duration": "Durée ",
          "PopupEventItem_Description": "Description ",
          "days": "jours",
          "hours": "heures",
          "minutes": "minutes",
          "and": "et",
          "at": "à",
          "AddNewEvent": "Ajouter un événement",
          "Error": "Erreur",
          "Player": " (Joueur)",
          "No description": "Pas de description",
          "Continue": "Continuer",
          "PopUp_StoryInformation": "Relire la présentation de l'histoire",
          "PopUp_SaveStory": "Sauvegarder votre progression",
          "PopUp_Music_On": "Couper le son",
          "PopUp_Music_Off": "Mettre le son",
          "PopUp_FullScreen_On": "Plein écran",
          "PopUp_FullScreen_Off": "Quitter le mode plein écran",
          "PopUp_AddContact": "Ajouter un contact",
          "NbMessages": "Nombre de messages",
          "EventConsequenceHeader": "Conséquence",
          "EventTriggerHeader": "Déclencheur",
          "EventEndStoryMessage": "Message à afficher au joueur",
          "EnterEventEndStoryMessage": "Entrez le message à afficher au joueur à la fin de l'histoire lorsqu'elle a été provoquée par cet événement",
          "EventConsequenceType": "Type de conséquence",
          "SelectEventConsequenceType": "Sélectionnez le type de conséquence",
          "Information": "Information",
          "EndStory": "Fin de l'histoire",
          "ExplanationContinueAfterEnd": "Malgré la fin de l'histoire, vous pouvez continuer à discuter avec les personnages.",
          "ContinueAfterEnd": "Continuer à discuter",
          "PopupEventItem_EndStoryMessage": "Message",
          "LeaveStory": "Quitter",
        }
      },
      es: {
        translation: {
          "Home": "Inicio",
          "Story Mode": "Modo Historia",
          "Save": "Guardar",
          "Creation Mode": "Modo Creación",
          "Select a Story": "Seleccionar una historia",
          "SignIn": "Iniciar sesión",
          "LogIn": "Iniciar sesión",
          "SignUp": "Registrarse",
          "SignOut": "Cerrar sesión",
          "LogInGoogle": "Iniciar sesión con Google",
          "SignUpGoogle": "Registrarse con Google",
          "NoAccount": "¿No tienes cuenta?",
          "AlreadyAccount": "¿Ya tienes cuenta?",
          "headerHomePage": "Crea historias interactivas con tus propios personajes",
          "textHomePage": "Deja que nuestra inteligencia artificial interprete las líneas de tus personajes y vive aventuras únicas.",
          "PleaseFillFields": "Por favor, completa todos los campos.",
          "PhoneAlreadyUsedOrNonExistant": "Este número de teléfono ya está en uso o no existe.",
          "CreateNewContact": "Por favor, completa todos los campos.",
          "Name": "Nombre",
          "EnterNameContact": "Ingresa el nombre del contacto",
          "DefaultName": "John Doe",
          "PhoneNumber": "Número de teléfono",
          "PlaceholderPhoneNumber": "Número de teléfono +33900000000",
          "Cancel": "Cancelar",
          "Create": "Crear",
          "Edit": "Editar",
          "NameOfStory": "Nombre de la historia",
          "NameOfStoryPlaceholder": "Nombre de la historia",
          "AddToDatabase": "Agrega la historia (No te preocupes, puedes editarla más tarde)",
          "LoadStory": "Cargar la historia",
          "ContactInformation": "Información de contacto",
          "ContactAcquaintances": "Conocidos",
          "KnownInformation": "Información conocida",
          "AcquaintanceKnowledge": "Ingresa información sobre él/ella",
          "User": "Usuario",
          "Number": "Número",
          "EnterNumber": "Ingresa el número",
          "Age": "Edad",
          "EnterAge": "Ingresa la edad",
          "Gender": "Género",
          "EnterGender": "Ingresa el género",
          "Male": "Hombre",
          "Female": "Mujer",
          "OtherGender": "Otro",
          "CharacterTraits": "Rasgos del personaje (serio, bromista, carismático...)",
          "EnterCharacterTraits": "Ingresa los rasgos del personaje (serio, bromista, carismático...)",
          "PhysicalFeatures": "Características físicas",
          "EnterPhysicalFeatures": "Ingresa las características físicas",
          "Particularities": "Particularidades (tic de lenguaje, manía, adicción...)",
          "EnterParticularities": "Ingresa las particularidades (tic de lenguaje, manía, adicción...)",
          "CharacterIcon": "Icono",
          "Interactive": "Interactivo",
          "InitialContact": "Contacto inicial",
          "MessageText": "Texto del mensaje",
          "EnterMessageText": "Escribe tu mensaje aquí",
          "Author": "Autor",
          "EnterAuthorName": "Nombre del autor",
          "AssistantAuthorChoice": "Tu Nuevo Personaje",
          "UserAuthorChoice": "El Usuario",
          "Date": "Fecha",
          "EnterDate": "Fecha (ejemplo : 2024-01-29)",
          "Time": "Hora",
          "EnterTime": "Hora (ejemplo : 14:30)",
          "AddMessage": "Agregar mensaje",
          "GenerateJSON": "Generar el JSON",
          "GeneralParameters": "Parámetros generales",
          "NewStoryTitle": "Mi nueva historia interactiva asombrosa",
          "StoryLanguage": "Idioma",
          "EnterStoryLanguage": "Ingresa el idioma de la historia",
          "Spanish": "Español",
          "StoryContext": "Contexto",
          "EnterStoryContext": "Ejemplo : La historia tiene lugar en 2015, en Nápoles, Italia. Carlo Rossi es un mafioso en Il Giorno, una mafia italiana. Después de varios años en Nápoles como parte de la rama local de Il Giorno, con Salvatore Bianchi como jefe, finalmente se unió a la rama romana de Il Giorno y se convirtió en uno de los hombres más influyentes. Un día, cuando regresa a Nápoles para resolver un asunto, Salvatore Bianchi es asesinado y Carlo es arrestado sin saber por qué.",
          "StoryDate": "Fecha de inicio de la trama",
          "EnterStoryDate": "Ejemplo : 2015-07-11",
          "StoryAccess": "Acceso",
          "PublicAccess": "Público",
          "LinkAccess": "No referenciado",
          "LimitedAccess": "Limitado",
          "EnterStoryAccess": "Elige cómo las personas pueden acceder a esta historia",
          "StoryFinancialStatus": "Estado financiero",
          "EnterStoryFinancialStatus": "Elige un estado financiero",
          "TokenUser": "Acceso pagado para el usuario",
          "StoryDefaultModel": "Modelo predeterminado",
          "EnterStoryDefaultModel": "Elige un modelo",
          "StoryHomeMessage": "Mensaje de bienvenida para el usuario",
          "EnterStoryHomeMessage": "Ejemplo : ¡Bienvenido a Giustizia per Telefono!",
          "StoryThumbnail": "Miniatura de la historia",
          "StoryMusic": "Música de fondo",
          "MainCharacter": "Personaje principal",
          "ErrorForfaitSoldout": "Error: Forfait agotado",
          "ConversationWith": "Conversación con ",
          "SendMessage": "Escribe un mensaje...",
          "Email": "Correo electrónico",
          "Password": "Contraseña",
          "DefaultModel": "Modelo predeterminado",
          "CurrentlyUsedModel": "Modelo actualmente utilizado",
          "APIKey": "Clave API",
          "EnterAPIKey": "Ingresa la clave API",
          "AIModel": "Modelo IA",
          "YouNotSignedIn": "No has iniciado sesión",
          "StoriaDescription": "La primera plataforma de narración interactiva del mundo. Redescubre historias clásicas o crea tus propios personajes y deja que nuestra IA interprete sus líneas. Vive aventuras únicas y personalizadas y compártelas con el mundo.",
          "AproposDeNous": "Acerca de nosotros",
          "ConctactUs": "Contácta nos",
          "ChoseRightModelForYou": "Elige el modelo adecuado para ti",
          "Documentation": "Documentación",
          "GettingStarted": "Empezando",
          "DocumentationVideo": "Vídeo de documentación",
          "DocumentationText": "Texto de documentación",
          "DocumentationFreeVersion": "Documentación sobre cómo usar la versión gratuita",
          "Tokens": "Tokens",
          "Contacts": "Contactos",
          "AllStories": "Todas las historias",
          "NoStoriesFound": "No se encontraron historias.",
          "StoriesIAlreadyStarted": "Historias que ya he comenzado",
          "MyPublicStories": "Mis historias públicas",
          "StartCreatingNewStory": "¡Empieza por crear una nueva historia!",
          "Drafts": "Borradores",
          "CreateNewStory": "Crear nueva historia",
          "Profile": "Perfil",
          "Settings": "Ajustes",
          "AISettings": "Ajustes de IA",
          "SelectAI": "Seleccionar una IA",
          "Receivers": "Destinatarios",
          "Senders": "Remitentes",
          "SelectReceivers": "Seleccionar destinatarios",
          "SelectSenders": "Seleccionar remitentes",
          "Messages": "Mensajes",
          "NonInitialContacts": "Contactos no iniciales",
          "InitialContacts": "Contactos iniciales",
          "MadeByStoria": "Recomendado por Fablia",
          "PleaseConnectToAccessPage": "Por favor, conéctate para acceder a esta página",
          "DownloadLocalAI": "Por favor, descarga e instala la IA local haciendo clic aquí",
          "AdvancedOptions": "Opciones avanzadas",
          "NarrativeParameters": "Parámetros narrativos",
          "Pupup_ConfirmDelete": "Confirmar",
          "Popup_TextConfirmDelete": "¿Estás seguro de que quieres eliminar esta historia? Esta acción es irreversible.",
          "Popup_TextConfirmDeleteAlreadyStarted": "¿Estás seguro de que quieres eliminar esta copia de seguridad? Esta acción es irreversible.",
          "Popup_Play": "Jugar",
          "Popup_Edit": "Editar",
          "Popup_Duplicate": "Duplicar y editar",
          "Popup_GeneralParameters_MainCharacter": "El personaje interpretado por el jugador durante las conversaciones con los contactos",
          "Popup_GeneralParameters_DefaultModel": "Modelo de IA predeterminado seleccionado cuando un usuario inicia la historia. Se utiliza para encarnar personajes y responder a mensajes. El usuario puede cambiarlo individualmente si lo desea.",
          "Popup_GeneralParameters_StartDate": "En el primer lanzamiento de la historia por parte del usuario, esta fecha se considera la fecha actual por los diferentes personajes. También sirve como punto de partida para eventos que pueden ser desencadenados según la fecha en el juego.",
          "Popup_GeneralParameters_CharacterTraits": "Puedes agregar rasgos de carácter (serio, humorístico, carismático, etc.) para intentar darle una personalidad más distintiva al personaje. Ten en cuenta que si quieres que estos rasgos de carácter sean conocidos por todos o algunos de los otros contactos, aún tendrás que mencionarlos en lo que el respectivo contacto sabe sobre este personaje.",
          "Popup_GeneralParameters_PhysicalFeatures": "Puedes agregar rasgos físicos para intentar darle una mejor coherencia al personaje entre las diferentes descripciones que podría hacer de sí mismo, así como con cualquier imagen o video que lo represente que hayas proporcionado. Ten en cuenta que si quieres que estas características físicas sean conocidas por todos o algunos de los otros contactos, aún tendrás que mencionarlas en lo que el respectivo contacto sabe sobre este personaje.",
          "Popup_GeneralParameters_Particularities": "Puedes agregar cualquier otra particularidad sobre el personaje aquí, ya sea un tic, una adicción o un papel particular en el contexto, como ser el líder de la mafia o tomar té regularmente con las esposas de sus colegas. Ten en cuenta que si quieres que estas particularidades sean conocidas por todos o algunos de los otros contactos, aún tendrás que mencionarlas en lo que el respectivo contacto sabe sobre este personaje.",
          "Popup_GeneralParameters_StoryHomeMessage": "Este mensaje se mostrará al usuario cuando inicie esta historia por primera vez. ¡Puedes usarlo para presentar el contexto de la historia, cómo jugar, quién eres o simplemente para saludarlos!",
          "Popup_GeneralParameters_StoryContext": "El contexto de tu historia corresponde a la información global conocida por todos los personajes. Esto puede ser la ubicación donde ocurre la acción, la fecha, las convenciones sociales o cualquier información que consideres relevante. Ten en cuenta que cuanto más completo sea el contexto, más efectivo será el modelo de IA, pero si está demasiado cargado, el modelo asignará menos importancia a cada elemento.",
          "Popup_GeneralParameters_NarrativeParameters": "Estos parámetros corresponden a cómo responden los personajes cuando se inicia la historia.",
          "Popup_GeneralParameters_RessourcesComplexes": "Este menú te permite subir archivos (imágenes, video, audio, etc.) para incorporarlos con la sintaxis #%x%# donde x es el índice del recurso que deseas mostrar",
          "Popup_ContactForm_CharacterTraits": "Puedes agregar rasgos de carácter (serio, humorístico, carismático, etc.) para intentar darle una personalidad más distintiva al personaje. Ten en cuenta que si quieres que estos rasgos de carácter sean conocidos por todos o algunos de los otros contactos, aún tendrás que mencionarlos en lo que el respectivo contacto sabe sobre este personaje.",
          "Popup_ContactForm_PhysicalFeatures": "Puedes agregar rasgos físicos para intentar darle una mejor coherencia al personaje entre las diferentes descripciones que podría hacer de sí mismo, así como con cualquier imagen o video que lo represente que hayas proporcionado. Ten en cuenta que si quieres que estas características físicas sean conocidas por todos o algunos de los otros contactos, aún tendrás que mencionarlas en lo que el respectivo contacto sabe sobre este personaje.",
          "Popup_ContactForm_Particularities": "Puedes agregar cualquier otra particularidad sobre el personaje aquí, ya sea un tic, una adicción o un papel particular en el contexto, como ser el líder de la mafia o tomar té regularmente con las esposas de sus colegas. Ten en cuenta que si quieres que estas particularidades sean conocidas por todos o algunos de los otros contactos, aún tendrás que mencionarlas en lo que el respectivo contacto sabe sobre este personaje.",
          "Popup_ContactForm_InitialContact": "Indica si el contacto está o no disponible cuando se inicia la historia por primera vez. Si el contacto no está disponible inicialmente, el usuario puede desbloquearlo ingresando el número de teléfono asociado.",
          "Popup_ContactForm_Number": "Este número permite que un contacto no inicial esté disponible para discusión más adelante en el juego si es conocido por otros personajes y descubierto por el usuario. También sirve como identificador único para cada personaje.",
          "Popup_ContactForm_Interactive": "Solo es posible interactuar con contactos interactivos. Los contactos no interactivos están destinados a proporcionar más contexto y credibilidad a la historia, representando, por ejemplo, alertas de cámaras de vigilancia o spam.",
          "Popup_ContactForm_KnownInformation": "Describe aquí todo lo que el personaje en proceso de creación sabe o cree saber sobre este personaje, incluida la información ya mencionada en el formulario de creación de este último.",
          "Popup_ContactForm_ContactAcquaintances": "Marca cada personaje que el personaje en proceso de creación conoce al menos por nombre.",
          "Popup_Settings_APIKey": "La clave API es lo que actualmente te permite conversar con los personajes de las historias, excepto si estás utilizando un modelo local. Es único para ti y difiere según los modelos. Para obtener uno, debes registrarte en el servicio de la empresa del modelo elegido y luego seguir las instrucciones que se muestran. Para GPT (3.5 y 4), ve a https://platform.openai.com/api-keys. Para Mistral (7B, 8x7B, Tiny, Small, Medium y Large), ve a https://console.mistral.ai/api-keys/.",
          "Popup_Settings_AIModel": "Cuanto más potentes sean los modelos, más realistas y coherentes serán las respuestas proporcionadas por los personajes, pero la producción de estas respuestas será larga y costosa. Por lo tanto, te recomendamos que pruebes diferentes modelos ofrecidos para establecer qué nivel de calidad te conviene. Hoy en día, Mistral Large y GPT-4 son los modelos más potentes ofrecidos. Los modelos de IA son los componentes básicos que permiten que los diferentes personajes te respondan. Estos son LLM (Large Language Model), basados en redes neuronales y capaces de reproducir un estilo de respuesta natural prediciendo las palabras más probables para completar el texto una por una. Han sido entrenados automáticamente en una gran cantidad de datos. Si quieres saber más, te recomendamos el artículo https://es.wikipedia.org/wiki/Modelo_de_lenguaje_grande.",
          "RessourcesComplexes": "Recursos Complejos",
          "DropFilesHere": "Arrastra y suelta los archivos aquí ...",
          "DragNDropFilesOrClick": "Arrastra y suelta los archivos aquí, o haz clic para seleccionar archivos",
          "OpenAIAPIKeyInstruction": "Instrucciones de la clave API de OpenAI: \nPara usar un modelo de OpenAI, deberás obtener una clave API del sitio web de OpenAI. \nPuedes registrarte en una cuenta de OpenAI y generar una clave API siguiendo estos pasos: \n1. Ve al sitio web de OpenAI (https://openai.com/) y crea una cuenta, si aún no tienes una. \n2. Inicia sesión en tu cuenta de OpenAI y navega a la sección 'Claves API'. \n3. Haz clic en el botón 'Crear una nueva clave secreta' para generar una nueva clave API. \n4. Copia la clave API generada y úsala en tu aplicación para autenticarte con la API de OpenAI.",
          "AnthropicAPIKeyInstruction": "Instrucciones de la clave API de Anthropic: \nPara usar un modelo de Anthropic, deberás obtener una clave API del sitio web de Anthropic. \nPuedes registrarte en una cuenta de Anthropic y generar una clave API siguiendo estos pasos: \n1. Ve al sitio web de Anthropic (https://www.anthropic.com/) y crea una cuenta, si aún no tienes una. \n2. Inicia sesión en tu cuenta de Anthropic y navega a la sección 'API'. \n3. Haz clic en el botón 'Crear una nueva clave API' para generar una nueva clave API. \n4. Copia la clave API generada y úsala en tu aplicación para autenticarte con la API de Anthropic.",
          "MistralAPIKeyInstruction": "Instrucciones de la clave API de Mistral: \nPara usar un modelo de Mistral, deberás obtener una clave API del sitio web de Mistral. \nPuedes registrarte en una cuenta de Mistral y generar una clave API siguiendo estos pasos: \n1. Ve al sitio web de Mistral (https://www.mistral.ai/) y crea una cuenta, si aún no tienes una. \n2. Inicia sesión en tu cuenta de Mistral y navega a la sección 'API'. \n3. Haz clic en el botón 'Crear una nueva clave API' para generar una nueva clave API. \n4. Copia la clave API generada y úsala en tu aplicación para autenticarte con la API de Mistral.",
          "GroqAPIKeyInstruction": "Instrucciones de la clave API de Groq: \nPara usar un modelo de Groq, deberás obtener una clave API del sitio web de Groq. \nPuedes registrarte en una cuenta de Groq y generar una clave API siguiendo estos pasos: \n1. Ve al sitio web de Groq (https://www.groq.com/) y crea una cuenta, si aún no tienes una. \n2. Inicia sesión en tu cuenta de Groq y navega a la sección 'API'. \n3. Haz clic en el botón 'Crear una nueva clave API' para generar una nueva clave API. \n4. Copia la clave API generada y úsala en tu aplicación para autenticarte con la API de Groq.",
          "DeepSeekAPIKeyInstruction": "Instrucciones de la clave API de DeepSeek: \nPara usar un modelo de DeepSeek, deberás obtener una clave API del sitio web de DeepSeek. \nPuedes registrarte en una cuenta de DeepSeek y generar una clave API siguiendo estos pasos: \n1. Ve al sitio web de DeepSeek (https://www.DeepSeek.com/) y crea una cuenta, si aún no tienes una. \n2. Inicia sesión en tu cuenta de DeepSeek y navega a la sección 'API'. \n3. Haz clic en el botón 'Crear una nueva clave API' para generar una nueva clave API. \n4. Copia la clave API generada y úsala en tu aplicación para autenticarte con la API de DeepSeek.",
          "MyPrivateStories" : "Mis historias privadas", 
          "FirstCreateContact":"Para empezar, crea un contacto",
          "FirstCreateMessage":"Para empezar, crea un mensaje",
          "CreateEvent": "Crear un evento",
          "EventDescription": "Descripción del evento",
          "EnterEventDescription": "Ingresa la descripción del evento",
          "EventDuration": "Duración del evento",
          "EventDurationDays": "Días",
          "EnterEventDurationDays": "Ingresa el número de días",
          "EventDurationHours": "Horas",
          "EnterEventDurationHours": "Ingresa el número de horas",
          "EventDurationMinutes": "Minutos",
          "EnterEventDurationMinutes": "Ingresa el número de minutos",
          "EventReceivers": "Personajes que vivieron el evento",
          "SelectEventReceivers": "Selecciona los personajes que vivieron el evento",
          "EventTrigger": "Modo de activación del evento",
          "SelectEventTrigger": "Selecciona el modo de activación del evento",
          "EventStartDate": "Fecha de inicio del evento",
          "EnterEventStartDate": "Ingresa la fecha de inicio del evento",
          "EventStartTime": "Hora de inicio del evento",
          "EnterEventStartTime": "Ingresa la hora de inicio del evento",
          "EventNbMessages": "Número de mensajes a intercambiar",
          "EnterEventNbMessages": "Ingresa el número de mensajes a intercambiar para activar el evento",
          "EventNbMessageCharacters": "Personajes concernés",
          "SelectEventNbMessageCharacters": "Selecciona los personajes concernidos por el intercambio de mensajes",
          "NewEvent": "Nuevo evento",
          "PhoneNumberKnown": "Número de teléfono conocido",
          "AddEvent": "Añadir",
          "SaveDate": "Guardado el",
          "CreationDate": "Creado el",
          "Popup_TextConfirmDeleteEvent": "¿Estás seguro de que quieres eliminar este evento? Esta acción es irreversible.",
          "PopupEventItem_Receivers": "Personajes concernidos ",
          "PopupEventItem_Date": "Fecha de inicio ",
          "PopupEventItem_NbMessages": "Número de mensajes a intercambiar para activar el evento ",
          "PopupEventItem_NbMessagesCharacters": "Personajes concernidos por el intercambio de mensajes ",
          "PopupEventItem_Duration": "Duración ",
          "PopupEventItem_Description": "Descripción ",
          "days": "días",
          "hours": "horas",
          "minutes": "minutos",
          "and": "y",
          "at": "a",
          "AddNewEvent": "Añadir un evento",
          "Error": "Error",
          "Player": " (Jugador)",
          "No description": "Sin descripción",
          "Continue": "Continuar",
          "PopUp_StoryInformation": "Volver a leer la presentación de la historia",
          "PopUp_SaveStory": "Guardar tu progreso",
          "PopUp_Music_On": "Silenciar",
          "PopUp_Music_Off": "Poner música",
          "PopUp_FullScreen_On": "Pantalla completa",
          "PopUp_FullScreen_Off": "Salir del modo de pantalla completa",
          "PopUp_AddContact": "Añadir un contacto",
          "NbMessages": "Número de mensajes",
          "EventConsequenceHeader": "Consecuencia",
          "EventTriggerHeader": "Activador",
          "EventEndStoryMessage": "Mensaje a mostrar al jugador",
          "EnterEventEndStoryMessage": "Ingresa el mensaje a mostrar al jugador al final de la historia cuando fue provocada por este evento",
          "EventConsequenceType": "Tipo de consecuencia",
          "SelectEventConsequenceType": "Selecciona el tipo de consecuencia",
          "Information": "Información",
          "EndStory": "Fin de la historia",
          "ExplanationContinueAfterEnd": "A pesar del final de la historia, puedes seguir hablando con los personajes.",
          "ContinueAfterEnd": "Continuar hablando",
          "PopupEventItem_EndStoryMessage": "Mensaje",
          "LeaveStory": "Salir",
        }
      },
      de: {
        translation: {
          "Home": "Startseite",
          "Story Mode": "Geschichtenmodus",
          "Save": "Speichern",
          "Creation Mode": "Erstellungsmodus",
          "Select a Story": "Geschichte auswählen",
          "SignIn": "Anmelden",
          "LogIn": "Anmelden",
          "SignUp": "Registrieren",
          "SignOut": "Abmelden",
          "LogInGoogle": "Mit Google anmelden",
          "SignUpGoogle": "Mit Google registrieren",
          "NoAccount": "Kein Konto?",
          "AlreadyAccount": "Bereits ein Konto?",
          "headerHomePage": "Erstelle interaktive Geschichten mit deinen eigenen Charakteren",
          "textHomePage": "Lass unsere künstliche Intelligenz die Zeilen deiner Charaktere interpretieren und erlebe einzigartige Abenteuer.",
          "PleaseFillFields": "Bitte fülle alle Felder aus.",
          "PhoneAlreadyUsedOrNonExistant": "Diese Telefonnummer wird bereits verwendet oder existiert nicht.",
          "CreateNewContact": "Bitte fülle alle Felder aus.",
          "Name": "Name",
          "EnterNameContact": "Gib den Namen des Kontakts ein",
          "DefaultName": "John Doe",
          "PhoneNumber": "Telefonnummer",
          "PlaceholderPhoneNumber": "Telefonnummer +33900000000",
          "Cancel": "Abbrechen",
          "RessourcesComplexes": "Komplexe Ressourcen",
          "Create": "Erstellen",
          "Edit": "Bearbeiten",
          "NameOfStory": "Geschichtentitel",
          "NameOfStoryPlaceholder": "Geschichtentitel",
          "AddToDatabase": "Füge die Geschichte zur Datenbank hinzu (Keine Sorge, du kannst sie später immer noch bearbeiten)",
          "LoadStory": "Geschichte laden",
          "ContactInformation": "Kontaktinformationen",
          "ContactAcquaintances": "Bekannte",
          "KnownInformation": "Bekannte Informationen",
          "AcquaintanceKnowledge": "Gib Informationen über ihn/sie ein",
          "User": "Benutzer",
          "Number": "Nummer",
          "EnterNumber": "Gib die Nummer ein",
          "Age": "Alter",
          "EnterAge": "Gib das Alter ein",
          "Gender": "Geschlecht",
          "EnterGender": "Gib das Geschlecht ein",
          "Male": "Männlich",
          "Female": "Weiblich",
          "OtherGender": "Anderes",
          "CharacterTraits": "Charaktereigenschaften (ernst, witzig, charismatisch...)",
          "EnterCharacterTraits": "Gib die Charaktereigenschaften ein (ernst, witzig, charismatisch...)",
          "PhysicalFeatures": "Physische Merkmale",
          "EnterPhysicalFeatures": "Gib die physischen Merkmale ein",
          "Particularities": "Besonderheiten (Sprachfehler, Manie, Sucht...)",
          "EnterParticularities": "Gib die Besonderheiten ein (Sprachfehler, Manie, Sucht...)",
          "CharacterIcon": "Symbol",
          "Interactive": "Interaktiv",
          "InitialContact": "Erster Kontakt",
          "MessageText": "Nachrichtentext",
          "EnterMessageText": "Schreibe hier deine Nachricht",
          "Author": "Autor",
          "EnterAuthorName": "Name des Autors",
          "AssistantAuthorChoice": "Dein neuer Charakter",
          "UserAuthorChoice": "Der Benutzer",
          "Date": "Datum",
          "EnterDate": "Datum (z.B. 2024-01-29)",
          "Time": "Uhrzeit",
          "EnterTime": "Uhrzeit (z.B. 14:30)",
          "AddMessage": "Nachricht hinzufügen",
          "GenerateJSON": "JSON generieren",
          "GeneralParameters": "Allgemeine Projektparameter",
          "NewStoryTitle": "Meine neue erstaunliche interaktive Geschichte",
          "StoryLanguage": "Sprache",
          "EnterStoryLanguage": "Gib die Sprache der Geschichte ein",
          "German": "Deutsch",
          "StoryContext": "Kontext",
          "EnterStoryContext": "Beispiel: Die Geschichte spielt im Jahr 2015 in Neapel, Italien. Carlo Rossi ist ein Mafioso in Il Giorno, einer italienischen Mafia. Nach mehreren Jahren in Neapel als Teil der lokalen Il Giorno-Fraktion, mit Salvatore Bianchi als Chef, schloss er sich schließlich der römischen Il Giorno-Fraktion an und wurde zu einem der einflussreichsten Männer. Eines Tages, als er nach Neapel zurückkehrte, um eine Angelegenheit zu regeln, wurde Salvatore Bianchi ermordet und Carlo wurde ohne Grund verhaftet.",
          "StoryDate": "Datum der Handlung",
          "EnterStoryDate": "Beispiel: 2015-07-11",
          "StoryAccess": "Zugriff",
          "PublicAccess": "Öffentlich",
          "LinkAccess": "Nicht referenziert",
          "LimitedAccess":"Privat",
          "EnterStoryAccess": "Wähle, wie Personen auf diese Geschichte zugreifen können",
          "StoryFinancialStatus": "Finanzieller Status",
          "EnterStoryFinancialStatus": "Wähle einen finanziellen Status",
          "TokenUser": "Kostenpflichtiger Zugriff für den Benutzer",
          "StoryDefaultModel": "Standardmodell",
          "EnterStoryDefaultModel": "Wähle ein Modell",
          "StoryHomeMessage": "Willkommensnachricht für den Benutzer",
          "EnterStoryHomeMessage": "Beispiel: Willkommen bei Giustizia per Telefono!",
          "StoryThumbnail": "Miniatur der Geschichte",
          "StoryMusic": "Hintergrundmusik",
          "MainCharacter": "Hauptfigur",
          "ErrorForfaitSoldout": "Fehler: Forfait ausverkauft",
          "ConversationWith": "Unterhaltung mit ",
          "SendMessage": "Schreibe eine Nachricht...",
          "Email": "E-Mail-Adresse",
          "Password": "Passwort",
          "DefaultModel": "Standardmodell",
          "CurrentlyUsedModel": "Aktuell verwendetes Modell",
          "APIKey": "API-Schlüssel",
          "EnterAPIKey": "Gib den API-Schlüssel ein",
          "AIModel": "KI-Modell",
          "YouNotSignedIn": "Du bist nicht angemeldet",
          "StoriaDescription": "Die weltweit erste interaktive Geschichtenplattform. Entdecke klassische Geschichten neu oder erstelle deine eigenen Charaktere und lass unsere KI ihre Zeilen interpretieren. Erlebe einzigartige und personalisierte Abenteuer und teile sie mit der Welt.",
          "AproposDeNous": "Über uns",
          "ConctactUs": "Kontaktiere uns",
          "ChoseRightModelForYou": "Wähle das richtige Modell für dich",
          "Documentation": "Dokumentation",
          "GettingStarted": "Erste Schritte",
          "DocumentationVideo": "Dokumentations video",
          "DocumentationText": "Dokumentations text",
          "DocumentationFreeVersion": "Dokumentation zur Verwendung der kostenlosen Version",
          "Tokens": "Tokens",
          "Contacts": "Kontakte",
          "AllStories": "Alle Geschichten",
          "NoStoriesFound": "Keine Geschichten gefunden",
          "StoriesIAlreadyStarted": "Geschichten, die ich schon begonnen habe",
          "MyPublicStories": "Meine öffentlichen Geschichten",
          "StartCreatingNewStory": "Beginne eine neue Geschichte zu erstellen",
          "Drafts": "Entwürfe",
          "CreateNewStory": "Neue Geschichte erstellen",
          "Profile": "Profil",
          "Settings": "Einstellungen",
          "AISettings": "KI-Einstellungen",
          "SelectAI": "Wähle eine KI aus",
          "Receivers": "Empfänger",
          "Senders": "Absender",
          "SelectReceivers": "Wähle Empfänger aus",
          "SelectSenders": "Wähle Absender aus",
          "Messages": "Nachrichten",
          "NonInitialContacts": "Nicht Initiale Kontakte",
          "InitialContacts": "Initiale Kontakte",
          "MadeByStoria": "Empfohlen von StorIA",
          "PleaseConnectToAccessPage": "Bitte melde dich an, um auf diese Seite zuzugreifen",
          "DownloadLocalAI": "Bitte lade die lokale KI herunter und installiere sie, indem du hier klickst",
          "AdvancedOptions": "Erweiterte Optionen",
          "NarrativeParameters": "Erzählparameter",
          "Pupup_ConfirmDelete": "Bestätigen",
          "Popup_TextConfirmDelete": "Sind Sie sicher, dass Sie diese Geschichte löschen möchten? Diese Aktion ist nicht rückgängig zu machen.",
          "Popup_TextConfirmDeleteAlreadyStarted": "Sind Sie sicher, dass Sie dieses Backup löschen möchten? Diese Aktion ist nicht rückgängig zu machen.",
          "Popup_Play": "Abspielen",
          "Popup_Edit": "Bearbeiten",
          "Popup_Duplicate": "Duplizieren und Bearbeiten",
          "Popup_GeneralParameters_MainCharacter": "Die Figur, die der Spieler verkörpert",
          "Popup_GeneralParameters_DefaultModel": "Standard-KI-Modell, das ausgewählt wird, wenn ein Benutzer die Geschichte startet. Es wird verwendet, um Charaktere zu verkörpern und auf Nachrichten zu antworten. Der Benutzer kann es individuell ändern, wenn gewünscht.",
          "Popup_GeneralParameters_StartDate": "Bei der ersten Ausführung der Geschichte durch den Benutzer wird dieses Datum von den verschiedenen Charakteren als aktuelles Datum betrachtet. Es dient auch als Ausgangspunkt für Ereignisse, die basierend auf dem im Spiel vorliegenden Datum ausgelöst werden können.",
          "Popup_GeneralParameters_CharacterTraits": "Sie können Charaktereigenschaften (ernsthaft, humorvoll, charismatisch usw.) hinzufügen, um der Figur eine deutlichere Persönlichkeit zu verleihen. Beachten Sie, dass Sie diese Charaktereigenschaften erwähnen müssen, wenn Sie möchten, dass sie von allen oder einigen anderen Kontakten bekannt sind.",
          "Popup_GeneralParameters_PhysicalFeatures": "Sie können physische Merkmale hinzufügen, um der Figur eine bessere Kohärenz zwischen den verschiedenen Beschreibungen zu verleihen, die sie von sich selbst geben könnte, sowie mit etwaigen Bildern oder Videos, die sie repräsentieren könnten und die Sie möglicherweise bereitgestellt haben. Beachten Sie, dass Sie diese physischen Merkmale erwähnen müssen, wenn Sie möchten, dass sie von allen oder einigen anderen Kontakten bekannt sind.",
          "Popup_GeneralParameters_Particularities": "Sie können hier jede andere Besonderheit über die Figur hinzufügen, sei es ein Tick, eine Sucht oder eine bestimmte Rolle im Kontext, wie zum Beispiel der Anführer der Mafia zu sein oder regelmäßig Tee mit den Frauen seiner Kollegen zu trinken. Beachten Sie, dass Sie diese Besonderheiten erwähnen müssen, wenn Sie möchten, dass sie von allen oder einigen anderen Kontakten bekannt sind.",
          "Popup_GeneralParameters_StoryHomeMessage": "Diese Nachricht wird dem Benutzer angezeigt, wenn er diese Geschichte zum ersten Mal startet. Sie können es verwenden, um den Kontext der Geschichte zu präsentieren: wie man spielt, wer Sie sind oder einfach nur, um ihn zu begrüßen!",
          "Popup_GeneralParameters_StoryContext": "Der Kontext Ihrer Geschichte entspricht den globalen Informationen, die allen Charakteren bekannt sind. Dies kann der Ort sein, an dem die Handlung stattfindet, das Datum, soziale Konventionen oder jede Information, die Sie für relevant halten. Beachten Sie, dass je vollständiger der Kontext ist, desto effektiver das KI-Modell sein wird. Wenn es jedoch zu überladen ist, wird das Modell jedem Element weniger Bedeutung beimessen.",
          "Popup_GeneralParameters_NarrativeParameters": "Diese Einstellungen geben an, wie die Charaktere reagieren, wenn die Geschichte beginnt.",
          "Popup_GeneralParameters_RessourcesComplexes": "Dieses Menü ermöglicht es Ihnen, Dateien (Bilder, Videos, Audiodateien usw.) hochzuladen, um sie mit der Syntax #%x%# einzufügen, wobei x der Index des anzuzeigenden Ressourcen ist.",
          "Popup_ContactForm_CharacterTraits": "Sie können Charaktereigenschaften (ernsthaft, humorvoll, charismatisch usw.) hinzufügen, um der Figur eine deutlichere Persönlichkeit zu verleihen. Beachten Sie, dass Sie diese Charaktereigenschaften erwähnen müssen, wenn Sie möchten, dass sie von allen oder einigen anderen Kontakten bekannt sind.",
          "Popup_ContactForm_PhysicalFeatures": "Sie können physische Merkmale hinzufügen, um der Figur eine bessere Kohärenz zwischen den verschiedenen Beschreibungen zu verleihen, die sie von sich selbst geben könnte, sowie mit etwaigen Bildern oder Videos, die sie repräsentieren könnten und die Sie möglicherweise bereitgestellt haben. Beachten Sie, dass Sie diese physischen Merkmale erwähnen müssen, wenn Sie möchten, dass sie von allen oder einigen anderen Kontakten bekannt sind.",
          "Popup_ContactForm_Particularities": "Sie können hier jede andere Besonderheit über die Figur hinzufügen, sei es ein Tick, eine Sucht oder eine bestimmte Rolle im Kontext, wie zum Beispiel der Anführer der Mafia zu sein oder regelmäßig Tee mit den Frauen seiner Kollegen zu trinken. Beachten Sie, dass Sie diese Besonderheiten erwähnen müssen, wenn Sie möchten, dass sie von allen oder einigen anderen Kontakten bekannt sind.",
          "Popup_ContactForm_InitialContact": "Geben Sie an, ob der Kontakt bei erstmaligem Start der Geschichte verfügbar ist oder nicht. Wenn der Kontakt anfangs nicht verfügbar ist, kann ihn der Benutzer entsperren, indem er die zugehörige Telefonnummer eingibt.",
          "Popup_ContactForm_Number": "Diese Nummer ermöglicht es, einen nicht anfänglichen Kontakt später im Spiel für die Diskussion verfügbar zu machen, wenn er von anderen Charakteren bekannt ist und vom Benutzer entdeckt wird. Sie dient auch als eindeutiger Bezeichner für jede Figur.",
          "Popup_ContactForm_Interactive": "Es ist nur möglich, mit interaktiven Kontakten zu interagieren. Nicht-interaktive Kontakte dienen dazu, der Geschichte mehr Kontext und Glaubwürdigkeit zu verleihen, indem sie beispielsweise Überwachungskamera-Warnungen oder Spam darstellen.",
          "Popup_ContactForm_KnownInformation": "Beschreiben Sie hier alles, was die gerade erstellte Figur über diese Figur weiß oder zu wissen glaubt, einschließlich der Informationen, die bereits im Erstellungsformular dieser Figur erwähnt wurden.",
          "Popup_ContactForm_ContactAcquaintances": "Kreuzen Sie jede Figur an, die die gerade erstellte Figur zumindest beim Namen kennt.",
          "Popup_Settings_APIKey": "Der API-Schlüssel ist das, was es Ihnen derzeit ermöglicht, mit den Charakteren der Geschichten zu sprechen, es sei denn, Sie verwenden ein lokales Modell. Er ist einzigartig für Sie und unterscheidet sich je nach den Modellen. Um einen zu erhalten, müssen Sie sich bei dem Dienst des ausgewählten Modellunternehmens registrieren und dann die angezeigten Anweisungen befolgen. Für GPT (3.5 und 4) gehen Sie zu https://platform.openai.com/api-keys. Für Mistral (7B, 8x7B, Tiny, Small, Medium und Large) gehen Sie zu https://console.mistral.ai/api-keys/.",
          "Popup_Settings_AIModel": "Je leistungsstärker die Modelle sind, desto realistischer und kohärenter sind die Antworten, die die Charaktere geben, jedoch ist die Erstellung dieser Antworten langwierig und kostspielig. Daher empfehlen wir Ihnen, verschiedene Modelle zu testen, um festzustellen, welches Qualitätsniveau Ihnen am besten gefällt. Heute sind Mistral Large und GPT-4 die leistungsstärksten Modelle. KI-Modelle sind die grundlegenden Komponenten, die es verschiedenen Charakteren ermöglichen, Ihnen zu antworten. Diese basieren auf neuronalen Netzen und sind in der Lage, einen natürlichen Antwortstil zu reproduzieren, indem sie die wahrscheinlichsten Wörter verwenden, um den Text Schritt für Schritt zu vervollständigen. Sie wurden automatisch auf einer riesigen Menge an Daten trainiert. Wenn Sie mehr erfahren möchten, empfehlen wir den Artikel https://de.wikipedia.org/wiki/Gro%C3%9Fes_Sprachmodell.",
          "DropFilesHere": "Ziehe und lege die Dateien hier ab ...",
          "DragNDropFilesOrClick": "Ziehe und lege die Dateien hier ab oder klicke, um Dateien auszuwählen",
          "OpenAIAPIKeyInstruction": "Anleitung zum OpenAI-API-Schlüssel: \nUm ein OpenAI-Modell zu verwenden, müssen Sie einen API-Schlüssel von der OpenAI-Website beziehen. \nSie können sich für ein OpenAI-Konto registrieren und einen API-Schlüssel durch die folgenden Schritte generieren: \n1. Gehen Sie zur OpenAI-Website (https://openai.com/) und erstellen Sie ein Konto, falls Sie noch keines haben. \n2. Melden Sie sich in Ihrem OpenAI-Konto an und navigieren Sie zum Abschnitt 'API-Schlüssel'. \n3. Klicken Sie auf die Schaltfläche 'Neuen geheimen Schlüssel erstellen', um einen neuen API-Schlüssel zu generieren. \n4. Kopieren Sie den generierten API-Schlüssel und verwenden Sie ihn in Ihrer Anwendung, um sich bei der OpenAI-API zu authentifizieren.",
          "AnthropicAPIKeyInstruction": "Anleitung zum Anthropic-API-Schlüssel: \nUm ein Anthropic-Modell zu verwenden, müssen Sie einen API-Schlüssel von der Anthropic-Website beziehen. \nSie können sich für ein Anthropic-Konto registrieren und einen API-Schlüssel durch die folgenden Schritte generieren: \n1. Gehen Sie zur Anthropic-Website (https://www.anthropic.com/) und erstellen Sie ein Konto, falls Sie noch keines haben. \n2. Melden Sie sich in Ihrem Anthropic-Konto an und navigieren Sie zum Abschnitt 'API'. \n3. Klicken Sie auf die Schaltfläche 'Neuen API-Schlüssel erstellen', um einen neuen API-Schlüssel zu generieren. \n4. Kopieren Sie den generierten API-Schlüssel und verwenden Sie ihn in Ihrer Anwendung, um sich bei der Anthropic-API zu authentifizieren.",
          "MistralAPIKeyInstruction": "Anleitung zum Mistral-API-Schlüssel: \nUm ein Mistral-Modell zu verwenden, müssen Sie einen API-Schlüssel von der Mistral-Website beziehen. \nSie können sich für ein Mistral-Konto registrieren und einen API-Schlüssel durch die folgenden Schritte generieren: \n1. Gehen Sie zur Mistral-Website (https://www.mistral.ai/) und erstellen Sie ein Konto, falls Sie noch keines haben. \n2. Melden Sie sich in Ihrem Mistral-Konto an und navigieren Sie zum Abschnitt 'API'. \n3. Klicken Sie auf die Schaltfläche 'Neuen API-Schlüssel erstellen', um einen neuen API-Schlüssel zu generieren. \n4. Kopieren Sie den generierten API-Schlüssel und verwenden Sie ihn in Ihrer Anwendung, um sich bei der Mistral-API zu authentifizieren.",
          "GroqAPIKeyInstruction": "Anleitung zum Groq-API-Schlüssel: \nUm ein Groq-Modell zu verwenden, müssen Sie einen API-Schlüssel von der Groq-Website beziehen. \nSie können sich für ein Groq-Konto registrieren und einen API-Schlüssel durch die folgenden Schritte generieren: \n1. Gehen Sie zur Groq-Website (https://www.groq.com/) und erstellen Sie ein Konto, falls Sie noch keines haben. \n2. Melden Sie sich in Ihrem Groq-Konto an und navigieren Sie zum Abschnitt 'API'. \n3. Klicken Sie auf die Schaltfläche 'Neuen API-Schlüssel erstellen', um einen neuen API-Schlüssel zu generieren. \n4. Kopieren Sie den generierten API-Schlüssel und verwenden Sie ihn in Ihrer Anwendung, um sich bei der Groq-API zu authentifizieren.",
          "DeepSeekAPIKeyInstruction": "Anleitung zum DeepSeek-API-Schlüssel: \nUm ein DeepSeek-Modell zu verwenden, müssen Sie einen API-Schlüssel von der DeepSeek-Website beziehen. \nSie können sich für ein DeepSeek-Konto registrieren und einen API-Schlüssel durch die folgenden Schritte generieren: \n1. Gehen Sie zur DeepSeek-Website (https://www.DeepSeek.com/) und erstellen Sie ein Konto, falls Sie noch keines haben. \n2. Melden Sie sich in Ihrem DeepSeek-Konto an und navigieren Sie zum Abschnitt 'API'. \n3. Klicken Sie auf die Schaltfläche 'Neuen API-Schlüssel erstellen', um einen neuen API-Schlüssel zu generieren. \n4. Kopieren Sie den generierten API-Schlüssel und verwenden Sie ihn in Ihrer Anwendung, um sich bei der DeepSeek-API zu authentifizieren.",
          "MyPrivateStories" : "Meine privaten Geschichten", 
          "FirstCreateContact":"Erstelle zuerst einen Kontakt",
          "FirstCreateMessage":"Erstelle zuerst eine Nachricht",
          "CreateEvent": "Ereignis erstellen",
          "EventDescription": "Ereignisbeschreibung",
          "EnterEventDescription": "Geben Sie die Ereignisbeschreibung ein",
          "EventDuration": "Ereignisdauer",
          "EventDurationDays": "Tage",
          "EnterEventDurationDays": "Geben Sie die Anzahl der Tage ein",
          "EventDurationHours": "Stunden",
          "EnterEventDurationHours": "Geben Sie die Anzahl der Stunden ein",
          "EventDurationMinutes": "Minuten",
          "EnterEventDurationMinutes": "Geben Sie die Anzahl der Minuten ein",
          "EventReceivers": "Charaktere, die das Ereignis erlebt haben",
          "SelectEventReceivers": "Wählen Sie die Charaktere aus, die das Ereignis erlebt haben",
          "EventTrigger": "Ereignisauslöser",
          "SelectEventTrigger": "Wählen Sie den Ereignisauslöser aus",
          "EventStartDate": "Ereignisstartdatum",
          "EnterEventStartDate": "Geben Sie das Ereignisstartdatum ein",
          "EventStartTime": "Ereignisstartzeit",
          "EnterEventStartTime": "Geben Sie die Ereignisstartzeit ein",
          "EventNbMessages": "Anzahl der auszutauschenden Nachrichten",
          "EnterEventNbMessages": "Geben Sie die Anzahl der auszutauschenden Nachrichten ein, um das Ereignis auszulösen",
          "EventNbMessageCharacters": "Betroffene Charaktere",
          "SelectEventNbMessageCharacters": "Wählen Sie die Charaktere aus, die von der Nachrichtenaustausch betroffen sind",
          "NewEvent": "Neues Ereignis",
          "PhoneNumberKnown": "Bekannte Telefonnummer",
          "AddEvent": "Hinzufügen",
          "SaveDate": "Gespeichert am",
          "CreationDate": "Erstellt am",
          "Popup_TextConfirmDeleteEvent": "Möchten Sie dieses Ereignis wirklich löschen? Diese Aktion ist nicht rückgängig zu machen.",
          "PopupEventItem_Receivers": "Betroffene Charaktere ",
          "PopupEventItem_Date": "Startdatum ",
          "PopupEventItem_NbMessages": "Anzahl der auszutauschenden Nachrichten, um das Ereignis auszulösen ",
          "PopupEventItem_NbMessagesCharacters": "Betroffene Charaktere durch den Nachrichtenaustausch ",
          "PopupEventItem_Duration": "Dauer ",
          "PopupEventItem_Description": "Beschreibung ",
          "days": "Tage",
          "hours": "Stunden",
          "minutes": "Minuten",
          "and": "und",
          "at": "um",
          "AddNewEvent": "Ereignis hinzufügen",
          "Error": "Fehler",
          "Player": " (Spieler)",
          "No description": "Keine Beschreibung",
          "Continue": "Fortsetzen",
          "PopUp_StoryInformation": "Zurück zur Geschichte Präsentation",
          "PopUp_SaveStory": "Speichern Sie Ihren Fortschritt",
          "PopUp_Music_On": "Stummschalten",
          "PopUp_Music_Off": "Musik einschalten",
          "PopUp_FullScreen_On": "Vollbildmodus",
          "PopUp_FullScreen_Off": "Vollbildmodus verlassen",
          "PopUp_AddContact": "Kontakt hinzufügen",
          "NbMessages": "Anzahl der Nachrichten",
          "EventConsequenceHeader": "Konsequenz",
          "EventTriggerHeader": "Auslöser",
          "EventEndStoryMessage": "Nachricht, die dem Spieler angezeigt wird",
          "EnterEventEndStoryMessage": "Geben Sie die Nachricht ein, die dem Spieler am Ende der Geschichte angezeigt wird, wenn sie durch dieses Ereignis ausgelöst wurde",
          "EventConsequenceType": "Konsequenztyp",
          "SelectEventConsequenceType": "Wählen Sie den Konsequenztyp aus",
          "Information": "Information",
          "EndStory": "Ende der Geschichte",
          "ExplanationContinueAfterEnd": "Trotz des Endes der Geschichte können Sie weiterhin mit den Charakteren sprechen.",
          "ContinueAfterEnd": "Weiter sprechen",
          "PopupEventItem_EndStoryMessage": "Nachricht",
          "LeaveStory": "Verlassen",
        }
      }
    },
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;

