import React, { useState, useEffect, useContext } from 'react';
import Messaging from '../Messaging/Messaging';
import Contacts from '../Contacts/Contacts';
import NewContact from '../Contacts/NewContact';
import { Button, Container, Header } from 'semantic-ui-react';
import Settings from '../Settings/Settings';
import StartModal from '../StartModal/StartModal';
import EndModal from '../EndModal/EndModal';
import AccessPrompt from '../Login/AccessPrompt';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { StoryContext } from '../Context/StoryContext';
import { EnvironmentContext } from '../Context/EnvironmentContext';
import { useParams } from 'react-router-dom';
import Login from '../Login/Login';
import SignUp from '../Login/SignUp';

export default function Story({ newStoryId }) {
  const [popUp, setPopUp] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [doWeNeedToDisplayModal, setDoWeNeedToDisplayModal] = useState(false);
  const mobile = window.innerWidth < 900;
  const [openGreetingMessage, setOpenGreetingMessage] = useState(true);
  const [dynTimeDiff, setDynTimeDiff] = useState(null);
  const env = useContext(EnvironmentContext);
  const story = useContext(StoryContext);
  const { storyId: inputStoryId } = useParams();
  const storyId = newStoryId || inputStoryId;

  useEffect(() => {
    story.setId(storyId);
    env.setUuidOfCurrentStory(storyId) // TODO To be discontinued
  }, [storyId])

  // Handle dynamic time (part 1)
  useEffect(() => {
    if (env.createMode) {
      story.setDynamicTime(story.parameters.start_date);
    } else {
      const intervalId = setInterval(() => {
        if (dynTimeDiff) {
          const currentDate = new Date();
          const currentDynTime = new Date(currentDate.getTime() - dynTimeDiff)
          story.setDynamicTime(currentDynTime);
        }
      }, 1000); // update only each second
      return () => clearInterval(intervalId);
    }

  }, [dynTimeDiff])
  // Handle dynamic time (part 2)
  useEffect(() => {
    // Local configuration
    setDynTimeDiff(new Date().getTime() - story.parameters.start_date.getTime());
  }, [story.parameters]);

  const fetchStoryDetails = async () => { // TODO Eventually move that in StoryContext directly? Because currently handled here, but also used in the parent Create (which is not a problem but may be cleaner otherwise)
    if (!env.user) return; // Make sure user is authenticated

    const storyRef = doc(db, "stories", storyId);
    const docSnap = await getDoc(storyRef);

    if (docSnap.exists()) {
      const storyData = docSnap.data();

      if (!story.alreadyStartedStories) {
        // TODO Handle this case more globally
        storyData.parameters.start_date = storyData.parameters?.start_date
          ? storyData.parameters.start_date.toDate()
          : new Date(); // this is another thing to handle
        story.setParameters(storyData.parameters || []);

        story.setContactList(storyData.contacts || []);
        story.setMessagesList(storyData.messages || []);
        story.setEventsList(storyData.events || []);
      } else {
        const { start_date, ...parametersToModify } = storyData.parameters || {};
        story.setParameters({ ...story.parameters, ...parametersToModify });
      }
      story.setSelectedContact(storyData.selectedContact || "");
      story.setCreationDate(storyData.creation_date || "");
      story.setHomeGreetingMessage(storyData.parameters.home_message || "");
      story.setContexteHistoire(storyData.parameters.context || "");
      story.setRessourcesComplexes(storyData.parameters.ressources_complexes || []);
      story.setMusic(storyData.parameters.music || "https://firebasestorage.googleapis.com/v0/b/storiagl.appspot.com/o/musics%2FSummer%20Somewhere%20In%20Cuba%20-%20Cumbia%20Deli.mp3?alt=media&token=1f14c263-44f6-4034-8d02-1c288a3d6139");

    } else {
      console.log("No such story!");
    }
  };

  const fetchStoryContext = async () => {
    if (!env.user) return; // Make sure user is authenticated
    // Ensure db is your initialized Firestore instance
    const storyDocRef = doc(db, `/prompts/francais`);

    try {
      const docSnap = await getDoc(storyDocRef);
      if (docSnap.exists()) {
        // Here you can return the context or set it in your component's state
        story.setPromptSystemeChoisi(docSnap.data().StorIA_events);
        return docSnap.data().StorIA_events;
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        return null;
      }
    } catch (error) {
      console.error("Error fetching story context: ", error);
      return null;
    }
  };

  useEffect(() => {
    if (!env.user || !env.apiKey) {

      return;
    } // Make sure user is authenticated
    const audioElement = env.audioRef.current;
    audioElement.loop = true;

    const playMusic = () => {
      if (env.createMode === false && audioElement) {
        if (audioElement.readyState === 4) {
          audioElement.play();
        } else {
          audioElement.addEventListener('canplaythrough', () => {
            audioElement.play();
          }, { once: true });
        }
      }
    };

    playMusic();

    return () => {
      audioElement.removeEventListener('canplaythrough', playMusic);
    };
  }, []); // Empty dependency array ensures it only runs on mount


  useEffect(() => {
    fetchStoryDetails();
    fetchStoryContext();
  }, [env.uuidOfCurrentStory, story.id, env.user]); // [story.user, story.uuidOfCurrentStory]

  return (
    <>
      {/* temp */}
      {/* <div style={{ color: 'white' }}>
        <label>{"(Temporary time display) " + story.dynamicTime.toLocaleString()}</label>
      </div> */}
      {!env.user ? (
        <Container textAlign='center'>
          <Header style={{ color: 'white' }}>Please sign up or login to continue to the story</Header>
          <SignUp />
        </Container>
      ) : (<>
        {!env.apiKey ? (
          <Container textAlign='center'>
            <Header style={{ color: 'white' }}>Please choose your model and provide your api key to continue to the story</Header>
            <Settings />
          </Container>
        ) : (<>
          <audio ref={env.audioRef} src={story.music} />
          <AccessPrompt isAuthenticated={!!env.user} onClose={() => { }} />
          <div className={popUp ? "app during-popup" : "app"} style={{ width: "100%" }}>
            {(!mobile || showContact) && (
              <div className={mobile ? "contacts-container mobile" : "contacts-container"}>
                <NewContact
                  popUp={popUp}
                  setPopUp={setPopUp}
                  story={story}
                />
                <Contacts
                  setPopUp={setPopUp}
                  setShowContact={setShowContact}
                  story={story}
                />
              </div>
            )}
            {mobile && (
              <Button className="ui icon add-menu" alt="Contact List Toggle" onClick={() => setShowContact(!showContact)}>
                <i className="align justify icon"></i>
              </Button>
            )}
            {(!mobile || !showContact) && (
              <div className="messaging-container">
                <Messaging
                  promptSysteme
                  Choisi={story.promptSystemeChoisi}
                  setDoWeNeedToDisplayModal={setDoWeNeedToDisplayModal}
                  setOpenGreetingMessage={setOpenGreetingMessage}
                />
              </div>
            )}
            {doWeNeedToDisplayModal && (
              <div className="settings-modal-overlay">
                <Settings
                  setDoWeNeedToDisplayModal={setDoWeNeedToDisplayModal}
                />
              </div>
            )}
            {(story.homeGreetingMessage && openGreetingMessage && !env.createMode) && (
              <div className="settings-modal-overlay">
                <StartModal
                  setOpenGreetingMessage={setOpenGreetingMessage}
                  openGreetingMessage={openGreetingMessage}
                />
              </div>
            )}
            {(story.endStoryMessageIdDisplayed && !env.createMode) && (
              <div className="settings-modal-overlay">
                <EndModal />
              </div>
            )}

          </div>
        </>
        )}
      </>
      )}
    </>
  );
}