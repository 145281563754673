
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Menu, Accordion, Form, FormField, FormInput, Input, Select, TextArea, Checkbox, Grid, Segment, Header, Icon, Image, Table } from 'semantic-ui-react';
import './CreateGeneralParameters.css';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FieldIssueChecker } from './FieldIssueChecker'
import { db, storage } from '../firebaseConfig';
import { getStorage, ref, uploadBytes, getDownloadURL, renameObject, updateMetadata, deleteObject } from "firebase/storage";
import { UploadMusic, UploadThumbnail, QuestionPopup, handleRessourcesComplexes } from "../Utils/Utils"
import { StoryContext, StoryProvider } from '../Context/StoryContext';
import { CreateContext } from '../Context/CreateContext';

const CreateForm = (props) => {

  const { t, i18n } = useTranslation();
  const [fieldChecker, setFieldChecker] = useState(new FieldIssueChecker());
  const story = useContext(StoryContext);
  const createContext = useContext(CreateContext)
  const [mainCharacter, setMainCharacter] = useState({})
  const [isMainCharacterLoaded, setIsMainCharacterLoaded] = useState(false);

  useEffect(() => { // Should maybe be moved to a useEffect in Create.js
    if (!isMainCharacterLoaded) {
      if (createContext.isNewStory) {
        setMainCharacter({
          name: "John",
          number: "0",
          age: '', //We should verify that it's a valid age in the future
          gender: 'male',
          character_traits: '',
          physical_features: '',
          particularities: '',
          icon: null,
          interactive: true,
          initial: false,
          acquaintances : []
        })
        setIsMainCharacterLoaded(true);
      }
      else if (story.mainCharacter?.name) {
        setMainCharacter(story.mainCharacter);
        setIsMainCharacterLoaded(true);
      }
    }

  }, [story.mainCharacter, createContext.isNewStory]);

  const handleFieldChange = (name, value) => {
    const fieldConfig = {
      age: {
        'NegativeAgeError': value < 0,
        'NotIntegerAgeError': parseInt(value) !== Number(value)
      },
      name: {
        'StoryTitleEmptyError': value.length === 0
      },
      context: {
        'StoryContextEmptyError': value.length === 0
      }
    };

    fieldChecker.checkFieldIssues(name, fieldConfig);
    setFieldChecker(fieldChecker.clone());
    props.setHasInvalidGeneralParameter(fieldChecker.hasFieldIssues());
  };

  useEffect(() => {
    const updateJson = () => { // TODO Handle this button that could be removed
      story.setContactList([
        mainCharacter,
        ...story.contactListWithoutMainCharacter
      ])
    };

    if (isMainCharacterLoaded) {
      updateJson();
    }
  }, [story.parameters, mainCharacter]);

  const handleStoryChange = (e, { name, value }) => {
    story.setParameters({ ...story.parameters, [name]: value });
    handleFieldChange(name, value);
  };

  const handleDateChange = (date) => {
    const name = 'start_date';
    const value = date ? date : new Date();
    handleStoryChange(null, { name, value });
  };

  const handleRessourcesComplexes = async (e) => {
    for (const file of e.target.files) {
      console.log(story.parameters)
      const pathRef = ref(storage, `RessourcesComplexes/${story.id}/${story.parameters.ressources_complexes.length + 1}`);
      await uploadBytes(pathRef, file);
      const downloadURL = await getDownloadURL(pathRef);
      console.log("Download URL:", downloadURL);
      story.setParameters({ ...story.parameters, ressources_complexes: [...story.parameters.ressources_complexes, downloadURL] });
      story.parameters.ressources_complexes.push(downloadURL);
      console.log(story.parameters.ressources_complexes)
      console.log(story.parameters.ressources_complexes)
    }
  };

  const handleDeleteRessource = async (index) => {
    const updatedRessources = [...story.parameters.ressources_complexes];
    const fileToDelete = updatedRessources[index - 1];
    updatedRessources.splice(index - 1, 1);
    story.setParameters({ ...story.parameters, ressources_complexes: updatedRessources });

    try {
      // Delete the file from Firebase Storage
      const fileRef = ref(storage, fileToDelete);
      await deleteObject(fileRef);
    } catch (error) {
      if (error.code === 'storage/unauthorized') {
        console.log('User does not have permission to delete the file. Skipping deletion.');
      } else {
        console.error('Error deleting file:', error);
      }
    }

    // Update the indices in Firebase Storage
    await updateRessourcesComplexesIndices(updatedRessources);
  };

  const updateRessourcesComplexesIndices = async (newRessources) => {
    for (let i = 0; i < newRessources.length; i++) {
      const fileRef = ref(storage, `RessourcesComplexes/${story.id}/${i + 1}`);
      const newFileRef = ref(storage, `RessourcesComplexes/${story.id}/${i + 1}`);
      const metadata = { path: newFileRef.fullPath };
      await updateMetadata(fileRef, metadata);
    }
  };

  const handleThumbnailChange = async (e) => {
    const file = e.target.files[0];

    const fileUrl = await UploadThumbnail(story.id, file)

    const reader = new FileReader();
    reader.onload = () => {
      story.setParameters({ ...story.parameters, thumbnail: fileUrl });
    };
    reader.readAsDataURL(file);
  };

  const handleMusicChange = async (e) => {
    const file = e.target.files[0];
    /*
    const reader = new FileReader();
    reader.onload = async () => {
      const musicUrl = await UploadMusic(storyJson.id, file);
      setStory((prevStory) => ({ ...prevStory, music: musicUrl }));
      //setStory({ ...story, music: reader.result });
    };
    reader.readAsDataURL(file);*/
    story.setParameters((prevStory) => ({ ...prevStory, music: file }));
  };

  const subpanels = {
    'advanced_narrative_parameters': [
      {
        key: 'advanced_narrative_parameters',
        title: t('AdvancedOptions'),
        content: [
          (<Form.Field icon='calendar alternate outline'>
            <label>
              {t('StoryDate')}
              <QuestionPopup message={t('Popup_GeneralParameters_StartDate')} />
            </label>
            <Input >
              <DatePicker
                selected={story.parameters.start_date ? new Date(story.parameters.start_date) : null}
                onChange={handleDateChange}
                dateFormat="yyyy-MM-dd"
                placeholderText={t('EnterStoryDate')}
                className='custom-datepicker'
              />
            </Input>
          </Form.Field>),
          (<Form.Field
            control={Select}
            label={<label>
              {t('StoryDefaultModel')}
              <QuestionPopup message={t('Popup_GeneralParameters_DefaultModel')} />
            </label>}
            name='default_model'
            placeholder={t('EnterStoryDefaultModel')}
            options={[
              { key: '1', text: t('Mistral-Medium'), value: 'Mistral-Medium' },
              { key: '2', text: t('Mistral-7B'), value: 'Mistral-7B' },
              { key: '3', text: t('Mistral-8x7B'), value: 'Mistral-8x7B' },
              { key: '4', text: t('Mistral-Tiny'), value: 'Mistral-Tiny' },
              { key: '5', text: t('Mistral-Small'), value: 'Mistral-Small' },
              { key: '6', text: t('Mistral-Large'), value: 'Mistral-Large' },
              { key: '7', text: t('OpenAI GPT 3.5'), value: 'OpenAI GPT 3.5' },
              { key: '8', text: t('OpenAI GPT 4'), value: 'OpenAI GPT 4' },
              { key: '9', text: t('IA locale'), value: 'IA locale' },
            ]}
            value={story.parameters.default_model}
            onChange={handleStoryChange}
            icon='code branch'
          />)
        ]
      }
    ],
  }

  const panels = [
    {
      key: 'general_parameters',
      title: {
        children: (
          <>
            <Icon name='sliders horizontal' style={{ "marginRight": '0.5em' }} />
            {t('GeneralParameters')}
          </>
        )
      },
      content: [
        (
          <Form>
            <Form.Field
              control={Input}
              label={t('NameOfStory')}
              name='name'
              placeholder={t('NameOfStoryPlaceholder')}
              value={story.parameters.name}
              onChange={handleStoryChange}
              icon='book'
              iconPosition='left'
              required
            />
            <Form.Field
              control={Select}
              label={t('StoryAccess')}
              name='access'
              placeholder={t('EnterStoryAccess')}
              options={[{ key: '1', text: t('PublicAccess'), value: 'public' },
              { key: '2', text: t('LinkAccess'), value: 'link' },
              { key: '3', text: t('LimitedAccess'), value: 'limited' }
              ]}
              value={story.parameters.access}
              onChange={handleStoryChange}
              icon='lock'
              required
            />
            <Form.Field
              control={TextArea}
              label={<label>
                {t('StoryHomeMessage')}
                <QuestionPopup message={t('Popup_GeneralParameters_StoryHomeMessage')} />
              </label>}
              name='home_message'
              placeholder={t('EnterStoryHomeMessage')}
              value={story.parameters.home_message}
              onChange={handleStoryChange}
              icon='pencil alternate'
            />
            <Form.Field
              control={Input}
              type="file"
              label={t('StoryThumbnail')}
              name="thumbnail"
              accept="image/*"
              onChange={handleThumbnailChange}
              icon='image'
              iconPosition='left'
            />
            {story.parameters.thumbnail ? (
              <Image
                src={story.parameters.thumbnail}
              />
            ) : "no thum"}
            <Form.Field
              control={Input}
              type="file"
              label={t('StoryMusic')}
              name="music"
              accept="music/*"
              onChange={handleMusicChange}
              icon='music'
              iconPosition='left'
            />
            {/* {story.music && (<audio
              src={story.music}
              controls
            />)} */}
          </Form>
        )
      ]
    },
    {
      key: 'narrative_parameters',
      title: {
        children: (
          <>
            <Icon name='book' style={{ "marginRight": '0.5em' }} />
            {t('NarrativeParameters')}
            <QuestionPopup message={t('Popup_GeneralParameters_NarrativeParameters')} />
          </>
        )
      },
      content: [
        (<Form>
          <Form.Field
            control={TextArea}
            label={<label>
              {t('StoryContext')}
              <QuestionPopup message={t('Popup_GeneralParameters_StoryContext')} />
            </label>}
            name='context'
            placeholder={t('EnterStoryContext')}
            value={story.parameters.context}
            onChange={handleStoryChange}
            icon='clipboard'
            required
          />
          <Form.Field
            control={Select}
            label={t('StoryLanguage')}
            name='language'
            placeholder={t('EnterStoryLanguage')}
            options={[{ key: '1', text: t('French'), value: 'francais' }]}
            value={story.parameters.language}
            onChange={handleStoryChange}
            icon='language'
            required
          />
          <Accordion as={Form} panels={subpanels.advanced_narrative_parameters} fluid styled />
        </Form>)
      ]
    },
    {
      key: 'ressources_complexes_parameters',
      title: {
        children: (
          <>
            <Icon name='file' style={{ "marginRight": '0.5em' }} />
            {t('RessourcesComplexes')}
            <QuestionPopup message={t('Popup_GeneralParameters_RessourcesComplexes')} />
          </>
        )
      },
      content: [
        (<Form>
          <Table>
            <Table.Body>
              {story.parameters.ressources_complexes?.map((ressource, index) => (
                <Table.Row key={index + 1}>
                  <Table.Cell>
                    <Image size='small' src={ressource} />
                  </Table.Cell>
                  <Table.Cell>{index + 1}</Table.Cell>
                  <Table.Cell>
                    <Button
                      icon="trash"
                      color="red"
                      onClick={() => handleDeleteRessource(index + 1)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <Form.Field
            control={Input}
            label={t('RessourcesComplexes')}
            name='ressources_complexes'
            type="file"
            onChange={(e) => handleRessourcesComplexes(e, story)}
            placeholder={t('EnterRessourcesComplexes')}
            icon='file'
            iconPosition='left'
          />
        </Form>)
      ]
    }
  ]

  return (
    <div style={{ background: '#00201f', color: '#000000', padding: '20px 0px' }}>
      <Accordion
        as={'h2'}
        // vertical
        defaultActiveIndex={[0, 1, 2, 3]}
        panels={panels}
        exclusive={false}
        fluid
        styled
      />
    </div>
  );
};

export default CreateForm;