import React from "react";
import { Button, Container, Image } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import './HomePage.css';
import { useTranslation } from 'react-i18next';

const HomePage = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const handleCreationClick = () => {
    navigate("/Create");
  };

  const handleHistoryClick = () => {
    navigate("/SelectStory");
  };

  return (
    <div className="hero">
        <Image src='./logoStoriaClean_720p.webp' className="hero-image" alt="Logo Fablia" size="medium" />
        <h1>{t('headerHomePage')}</h1>
        <p>{t('textHomePage')}</p>
        <div className="button-container">
          <Button className="button" onClick={handleHistoryClick} size="large" primary aria-label="Navigate to Story Mode">
            {t('Story Mode')}
          </Button>
          <Button className="button" onClick={handleCreationClick} size="large" primary aria-label="Navigate to Creation Mode">
            {t('Creation Mode')}
          </Button>
        </div>
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/y1yj-nvu6HQ?si=y9fhn3ajdSANM3Mc"
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </div>
  );
}

export default HomePage;