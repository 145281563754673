import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Form, Header, Modal, Segment, Button, Icon, Select, TextArea, Input, MessageList, FormField } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { CreateContext } from '../Context/CreateContext';
import { StoryContext } from '../Context/StoryContext';
import { handleRessourcesComplexes, convertSplittedDateToDate } from '../Utils/Utils';
import ShortUniqueId from 'short-unique-id';

const CreateNewMessagePopUp_CreationMode = props => {
    const { t } = useTranslation();
    const story = useContext(StoryContext);
    const createContext = useContext(CreateContext);
    const [newMessage, setNewMessage] = useState({
        senders: ["user"],
        receivers: [story.selectedContactName],
        author: 'assistant',
        message: '',
        date: new Date(), // Keep as Date object for compatibility with SemanticDatepicker
        heure: new Date().toLocaleTimeString('fr-FR'),
    });

    useEffect(() => {
        if (createContext.editMessage) {
            const editMessage = story.messagesList.find(message => message.id === createContext.editMessage);
            setNewMessage(editMessage);
        } else {
            const uid = new ShortUniqueId({ length: 15 });
            setNewMessage({
                id: uid.randomUUID(),
                ...newMessage,
            });
        }
    }, [createContext.editMessage]);

    const handleMessageChange = (e, { name, value }) => {
        if (name === 'sendersBasic' || name === 'senders' || name === 'receivers') {
            let updatedValue = Array.isArray(value) ? value : [value];
            if (name === 'sendersBasic') {
                name = 'senders' //Sould be improved but resolve each problem excepted the removing of the advanced fields which not reset the basic field
                if (value.includes('user')) {
                    updatedValue = ['user'];
                    setNewMessage(prevState => ({
                        ...prevState,
                        receivers: [story.selectedContactName]
                    }));
                } else {
                    updatedValue = [story.selectedContactName];
                    setNewMessage(prevState => ({
                        ...prevState,
                        receivers: ['user']
                    }));
                }
            }

            setNewMessage(prevState => ({
                ...prevState,
                [name]: updatedValue,
                author: updatedValue.includes('user') ? 'user' : 'assistant'
            }));
        } else if (name === 'date') {
            setNewMessage(prevState => ({
                ...prevState,
                [name]: value
            }));
        } else {
            setNewMessage(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleDateChange = (date) => {
        const name = 'date';
        const value = date ? date : new Date();
        handleMessageChange(null, { name, value });
    };

    const onClose = () => {
        createContext.setOpenPopUpAddNewMessage_CreationMode(false);
        createContext.setEditMessage(null);
    }

    const addMessage = () => {
        const updatedMessagesList = story.messagesList.filter(message => message.id !== createContext.editMessage);

        const formattedMessage = {
            ...newMessage,
            date: newMessage.date.toISOString().slice(0, 10)
        };
        story.setMessagesList([...updatedMessagesList, formattedMessage].sort((a, b) => {
            const date_a = convertSplittedDateToDate(a.date, a.heure);
            const date_b = convertSplittedDateToDate(b.date, b.heure);

            return date_a.getTime() - date_b.getTime();
        }));
        
        onClose();
    };

    const open = () => {
        createContext.openPopUpAddNewMessage_CreationMode(true);
    }

    const panels =
        [
            {
                key: 'BasicMessageForm',
                title: {
                    children: (<></>)
                },
                content: [
                    (<Form.Field
                        control={Select}
                        label={t('Senders')}
                        name='sendersBasic'
                        placeholder={t('SelectSenders')}
                        options={[
                            { key: 'user', text: t('User'), value: 'user' },
                            { key: 'selectedContact', text: story.selectedContactName, value: story.selectedContactName },
                        ]}
                        value={newMessage.senders[0]}
                        onChange={handleMessageChange}
                    />)
                ]
            },
            {
                key: 'AdvancedMessageForm',
                title: t('AdvancedOptions'),
                content: [
                    (<Form.Group widths='equal'>
                        <Form.Field
                            control={Select}
                            label={t('Senders')}
                            name='senders'
                            placeholder={t('SelectSenders')}
                            multiple
                            options={story.contactList.map((contact, index) => ({
                                key: index,
                                text: contact.name,
                                value: contact.name,
                            }))}
                            value={newMessage.senders}
                            onChange={handleMessageChange}
                        />
                        <Form.Field
                            control={Select}
                            label={t('Receivers')}
                            name='receivers'
                            placeholder={t('SelectReceivers')}
                            multiple
                            options={story.contactList.map((contact, index) => ({
                                key: index,
                                text: contact.name,
                                value: contact.name,
                            }))}
                            value={newMessage.receivers}
                            onChange={handleMessageChange}
                        />
                    </Form.Group>),
                    (<Form.Group widths='equal'>
                        <Form.Field>
                            <label>
                                {t('Date')}
                            </label>
                            <Input icon='calendar alternate outline'>
                            <DatePicker
                                selected={newMessage.date ? new Date(newMessage.date) : null}
                                onChange={handleDateChange}
                                dateFormat="yyyy-MM-dd"
                                placeholderText={t('EnterDate')}
                                className='custom-datepicker'
                            />
                            </Input>
                        </Form.Field>
                        <Form.Field
                            control={Input}
                            label={t('Time')}
                            name='heure'
                            placeholder={t('EnterTime')}
                            value={newMessage.heure}
                            onChange={handleMessageChange}
                            type='time'
                        />
                    </Form.Group>)
                ]
            }
        ]

    return (
        <Modal open={open} onClose={onClose} size="small">
            <Modal.Header>{t('CreateNewContact')}</Modal.Header>
            <Modal.Content>
                <Segment padded>
                    <Header as='h2'>
                        <Icon name='chat' />
                        <Header.Content>{t('Messages')}</Header.Content>
                    </Header>
                    <Form>
                        <Form.Field
                            control={TextArea}
                            label={t('MessageText')}
                            name='message'
                            placeholder={t('EnterMessageText')}
                            value={newMessage.message}
                            onChange={handleMessageChange}
                        />
                        <Form.Field
                            control={Input}
                            label={t('RessourcesComplexes')}
                            name='ressources_complexes'
                            type="file"
                            onChange={async (e) => {
                                await handleRessourcesComplexes(e, story);
                                setNewMessage((prevMessage) => ({
                                    ...prevMessage,
                                    message: `${prevMessage.message} #%${story.parameters.ressources_complexes.length+1}%#`,
                                  }));
                              }}
                            placeholder={t('EnterRessourcesComplexes')}
                            icon='file'
                            iconPosition='left'
                        />
                    </Form>

                    <Accordion as={Form} defaultActiveIndex={0} panels={panels} fluid />
                </Segment>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <Button positive onClick={addMessage}>
                    {createContext.editMessage ? t('Edit') : t('Create')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default CreateNewMessagePopUp_CreationMode;
