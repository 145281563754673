import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button, Select, TextArea, Input, Modal, Header } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { StoryContext } from '../Context/StoryContext';
import { CreateContext } from '../Context/CreateContext';
import ShortUniqueId from 'short-unique-id';

const CreateEvent = () => {
    const { t } = useTranslation();
    const story = useContext(StoryContext);
    const createContext = useContext(CreateContext);

    const defaultInformationConsequence = {
        type: 'information',
        description: '',
        receivers: [],
        durationDays: 0,
        durationHours: 0,
        durationMinutes: 1,
    };

    const defaultEndStoryConsequence = {
        type: 'endStory',
        message: '',
        activate: true,
    };

    const defaultEvent = {
        startDate: new Date(), // Keep as Date object for compatibility with SemanticDatepicker
        startTime: new Date().toLocaleTimeString(),
        nbMessages: 0,
        nbMessageCharacters: [],
        trigger: 'time',
        consequence: defaultInformationConsequence,
    };
    const [newEvent, setNewEvent] = useState({});

    useEffect(() => {
        console.log('createContext.editEvent: ', createContext.editEvent);
        if (createContext.editEvent) {
            const editEvent = story.eventsList.find(event => event.id === createContext.editEvent);
            setNewEvent(editEvent);
        } 
        else if (createContext.duplicateEvent) {
            const duplicateEvent = story.eventsList.find(event => event.id === createContext.duplicateEvent);
            const uid = new ShortUniqueId({ length: 15 });
            setNewEvent({
                ...duplicateEvent,
                id: uid.randomUUID()
            });
        }
        else {
            const uid = new ShortUniqueId({ length: 15 });
            setNewEvent({
                id: uid.randomUUID(),
                ...defaultEvent
            });
        }
    }, [createContext.editEvent, createContext.duplicateEvent]);

    const handleNewEventChange = (e, { name, value }) => {
        setNewEvent({ ...newEvent, [name]: value });
    };

    const handleDateChange = (date) => {
        const name = 'startDate';
        const value = date ? date : new Date();
        handleNewEventChange(null, { name, value });
    };

    const handleConsequenceTypeChange = (e, { value }) => {
        if (value === 'information' && newEvent.consequence?.type !== 'information') {
            setNewEvent({ ...newEvent, consequence: defaultInformationConsequence });
        } else if (value === 'endStory' && newEvent.consequence?.type !== 'endStory') {
            setNewEvent({ ...newEvent, consequence: defaultEndStoryConsequence });
        }
    };

    const handleConsequenceChange = (e, { name, value }) => {
        console.log({ ...newEvent.consequence, [name]: value });
        const newConsequence = { ...newEvent.consequence, [name]: value };
        setNewEvent({ ...newEvent, consequence: newConsequence });
    };

    const onClose = () => {
        createContext.setOpenPopUpAddNewEvent_CreationMode(false);
        createContext.setEditEvent(null);
        createContext.setDuplicateEvent(null);
    };

    const addEvent = () => {
        const updatedEventsList = story.eventsList.filter(event => event.id !== newEvent.id);

        console.log('newEvent: ', newEvent);

        const formattedEvent = {
            ...newEvent,
            startDate: newEvent.trigger === 'time' ? new Date(newEvent.startDate).toISOString().slice(0, 10) : null, // TODO: Stock as a date and convert to string when needed
            startTime: newEvent.trigger === 'time' ? newEvent.startTime : null,
            nbMessages: newEvent.trigger === 'nbMessages' ? newEvent.nbMessages : null,
            nbMessageCharacters: newEvent.trigger === 'nbMessages' ? newEvent.nbMessageCharacters : null,
        };

        const sortByTrigger = (a, b) => {
            return a.trigger === 'time' ? -1 : 1;
        };

        const sortByTime = (a, b) => {
            const startDate_a = new Date(`${a.startDate}T${a.startTime}`);
            const startDate_b = new Date(`${b.startDate}T${b.startTime}`);

            return startDate_a.getTime() - startDate_b.getTime();
        };

        const sortByDuration = (a, b) => {
            const duration_a = (a.consequence.type === 'information') ? 1440 * a.durationDays + 60 * a.durationHours + a.durationMinutes : 0;
            const duration_b = (b.consequence.type === 'information') ? 1440 * b.durationDays + 60 * b.durationHours + b.durationMinutes : 0;

            return duration_a - duration_b;
        };

        story.setEventsList([...updatedEventsList, formattedEvent].sort((a, b) => {
            if (a.trigger !== b.trigger) {
                return sortByTrigger(a, b);
            }

            if (a.trigger === 'time') {
                return sortByTime(a, b);
            }

            return sortByDuration(a, b);
        }));

        onClose();
    };

    const open = () => {
        createContext.openPopUpAddNewEvent_CreationMode(true);
    }

    return (
        <Modal open={open} onClose={onClose} size="small">
            <Modal.Header>{t('CreateEvent')}</Modal.Header>
            <Modal.Content>
                <Form>
                    {console.log('newEvent: ', newEvent)}

                    <Header as='h4'>{t('EventTriggerHeader')}</Header>

                    <Form.Field
                        name='trigger'
                        control={Select}
                        label={t('EventTrigger')}
                        placeholder={t('SelectEventTrigger')}
                        options={[
                            { key: 'time', text: t('Time'), value: 'time' },
                            { key: 'nbMessages', text: t('NbMessages'), value: 'nbMessages' },
                        ]}
                        value={newEvent.trigger}
                        onChange={handleNewEventChange}
                    />

                    {newEvent.trigger === 'time' && (
                        <Form.Group>
                            <Form.Field required>
                                <label>
                                    {t('EventStartDate')}
                                </label>
                                <Input 
                                    icon='calendar alternate outline'
                                >
                                <DatePicker
                                    selected={newEvent.startDate ? new Date(newEvent.startDate) : null}
                                    onChange={handleDateChange}
                                    dateFormat="yyyy-MM-dd"
                                    placeholderText={t('EnterEventStartDate')}
                                    className='custom-datepicker'
                                />
                                </Input>
                            </Form.Field>
                            <Form.Field
                                control={Input}
                                label={t('EventStartTime')}
                                name='startTime'
                                placeholder={t('EnterEventStartTime')}
                                value={newEvent.startTime}
                                onChange={handleNewEventChange}
                                type='time'
                                required
                            />
                        </Form.Group>
                    )}

                    {newEvent.trigger === 'nbMessages' && (
                        <Form.Group>
                            <Form.Field
                                control={Input}
                                label={t('EventNbMessages')}
                                name='nbMessages'
                                placeholder={t('EnterEventNbMessages')}
                                value={newEvent.nbMessages}
                                onChange={handleNewEventChange}
                                required
                            />
                            <Form.Field
                                control={Select}
                                label={t('EventNbMessageCharacters')}
                                name='nbMessageCharacters'
                                placeholder={t('SelectEventNbMessageCharacters')}
                                multiple
                                options={story.contactList
                                    .filter(contact => contact.number !== '0')
                                    .map((contact) => ({
                                        key: contact.number,
                                        text: contact.name,
                                        value: contact.number,
                                    }))}
                                value={newEvent.nbMessageCharacters}
                                onChange={handleNewEventChange}
                                required
                            />
                        </Form.Group>
                    )}

                    <Header as='h4'>{t('EventConsequenceHeader')}</Header>

                    <Form.Field
                        control={Select}
                        label={t('EventConsequenceType')}
                        name='type'
                        placeholder={t('SelectEventConsequenceType')}
                        options={[
                            { key: 'information', text: t('Information'), value: 'information' },
                            { key: 'endStory', text: t('EndStory'), value: 'endStory' },
                        ]}
                        value={newEvent.consequence?.type}
                        onChange={handleConsequenceTypeChange}
                        required
                    />

                    {newEvent.consequence?.type === 'information' && (
                        <>
                            <Form.Field
                                control={TextArea}
                                label={t('EventDescription')}
                                name='description'
                                placeholder={t('EnterEventDescription')}
                                value={newEvent.consequence?.description}
                                onChange={handleConsequenceChange}
                                required
                            />

                            {t('EventDuration')}
                            <Form.Group>
                                <Form.Field
                                    name='durationDays'
                                    control={Input}
                                    label={t('EventDurationDays')}
                                    placeholder={t('EnterEventDurationDays')}
                                    value={newEvent.consequence?.durationDays}
                                    onChange={handleConsequenceChange}
                                />
                                <Form.Field
                                    name='durationHours'
                                    control={Input}
                                    label={t('EventDurationHours')}
                                    placeholder={t('EnterEventDurationHours')}
                                    value={newEvent.consequence?.durationHours}
                                    onChange={handleConsequenceChange}
                                />
                                <Form.Field
                                    name='durationMinutes'
                                    control={Input}
                                    label={t('EventDurationMinutes')}
                                    placeholder={t('EnterEventDurationMinutes')}
                                    value={newEvent.consequence?.durationMinutes}
                                    onChange={handleConsequenceChange}
                                />
                            </Form.Group>

                            <Form.Field
                                control={Select}
                                label={t('EventReceivers')}
                                name='receivers'
                                placeholder={t('SelectEventReceivers')}
                                multiple
                                options={story.contactList
                                    .filter(contact => contact.number !== '0')
                                    .map((contact) => ({
                                        key: contact.number,
                                        text: contact.name,
                                        value: contact.number,
                                    }))}
                                value={newEvent.consequence?.receivers}
                                onChange={handleConsequenceChange}
                                required
                            />
                        </>
                    )}

                    {newEvent.consequence?.type === 'endStory' && (
                        <Form.Field
                            control={TextArea}
                            label={t('EventEndStoryMessage')}
                            name='message'
                            placeholder={t('EnterEventEndStoryMessage')}
                            value={newEvent.consequence?.message}
                            onChange={handleConsequenceChange}
                            required
                        />
                    )}
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button negative onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <Button positive onClick={addEvent}>
                    {t('AddEvent')}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default CreateEvent;