import React from 'react';
import { Container, Header, Segment, Image, Button } from 'semantic-ui-react';
import classes from "./GuideCreerCompte.module.css";

export default function CreerHistoire() {
    return (
        <Container className={classes.guideContainerChoisirHistoire} style={{ padding: '3em 0em' }}>
            <Segment basic textAlign="center">
                <Header as="h1" style={{ fontSize: '2.5em', color: '#2a2a2a' }}>Guide sur la Création d'Histoire</Header>
                <p style={{ fontSize: '1.33em', color: '#5a5a5a' }}>
                    Alors comme ca tu es intéressé par le fait de créer tes propres histoires ? Que ce soit pour impressionner tes potes ou pour créer une grosse histoire pour régaler le monde entier, tu es au bon endroit.
                </p>
            </Segment>

            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }}>Mode Histoire VS Mode Création</Header>
                <Image src="https://firebasestorage.googleapis.com/v0/b/storiagl.appspot.com/o/PublicImages%2FCreation1.png?alt=media&token=af847260-c2c1-4d77-8fb4-ff211bb77aa7" alt="Modèle IA" fluid />
                <p style={{ fontSize: '1.2em', color: '#5a5a5a' }}>
                    Première étape est de se rendre dans l'espace "Mode Création" en cliquant sur le bouton.
                </p>
            </Segment>

            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }}>Histoires déjà commencées VS Recommandées par StorIA VS Toutes les histoires</Header>
                <Image src="https://firebasestorage.googleapis.com/v0/b/storiagl.appspot.com/o/PublicImages%2FCreation2.png?alt=media&token=76fcc127-adb6-40a1-a45f-63fd7248ab3b" alt="Groq Llama 3.1" fluid />
                <p style={{ fontSize: '1.2em', color: '#5a5a5a' }}>
                    L'interface de gestion de vos histoire est très similaire à celle du "Mode Histoire". Cependant ici vous ne verrez que les histoires que VOUS avez créer.
                    Ils sont triés en deux catégories : 
                        Publiques : Les histoires que vous avez rendu publiques (elles sont donc visibles par tout le monde dans le Mode Histoire).
                        Privées : Les histoires que vous gardez privées (elles ne sont donc pas visibles par les autres personnes dans le Mode Histoire).

                    Vous avez un bouton "Créer une nouvelle histoire" qui vous permets de vous lancer dans la création d'une nouvelle histoire. Veuillez cliquer dessus.
                </p>
            </Segment>

            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }}>Qu'est ce que c'est que ce bazard ?</Header>
                <Image src="https://firebasestorage.googleapis.com/v0/b/storiagl.appspot.com/o/PublicImages%2FCreation3.png?alt=media&token=417c147f-ffc3-44e9-a6d5-666e6a52d969" alt="OpenAI GPT4o" fluid />
                <p style={{ fontSize: '1.2em', color: '#5a5a5a' }}>
                    Wow, qu'est ce que c'est que tout ce bazard ?
                    Ne t'en fait pas, tu va voir c'est plus simple que ca en a l'air.

                    L'interface de création d'histoire (aussi appelé "Editeur") est constituée de deux zones :
                    La première (en jaune) permets la gestion des parametres de ton histoire. On peut par exemple y définir le nom de l'histoire, la langue par défaut de l'histoire, la musique d'ambiance et pleins d'autres choses.
                    La deuxième (en bleu) est un prévisualiseur. Il permets de voir à quoi va ressembler ton histoire une fois créée. Il te permets également de rajouter des personnages et de définir des conversations initiales (pour que la personne qui va jouer à ton histoire, aussi appellée "Lecteur", se retrouve pas avec une page blanche).

                    Si tu as la moindre question, tu peux survoler avec ta sourie le petit "?" et tu aura plus d'informations.

                    Ne t'inquiètes pas, tout va s'expliquer juste après.
                </p>
            </Segment>

            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }}>Créer un personnage</Header>
                <Image src="https://firebasestorage.googleapis.com/v0/b/storiagl.appspot.com/o/PublicImages%2FCreation4.png?alt=media&token=de5e407c-4c59-414a-8a8f-20ea17db8114" alt="OpenAI GPT4o" fluid />
                <p style={{ fontSize: '1.2em', color: '#5a5a5a' }}>
                    La première chose à faire est de créer un personnage avec lequel on va interagir.

                    Pour cela, rien de plus simple :
                    Cliquer sur ce bouton et une interface va apparaitre pour vous permettre d'ajuster des informations à propos de votre personnage.
                </p>
            </Segment>

            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }}>Parametrer un personnage</Header>
                <Image src="https://firebasestorage.googleapis.com/v0/b/storiagl.appspot.com/o/PublicImages%2FCreation5.png?alt=media&token=d6a528bb-f0dd-4b78-a42a-93cd316b34b2" alt="OpenAI GPT4o" fluid />
                <p style={{ fontSize: '1.2em', color: '#5a5a5a' }}>
                    La zone 1 (en jaune) permets de donner des informations à propos de ton personnage. Tu peux lui donner un nom, un age, des traits de caractère, une photo de profil, et autre.
                    La plupart des champs sont optionnels donc tu peux les laisser vide si tu le souhaite mais les remplir peut aider l'IA à jouer un personnage plus précis. Ce n'est pas bien grave si tu écris quelque chose dans un mauvais champ, l'IA est souvent assez intelligente pour comprendre quand même la ou tu veux en venir donc n'hésite pas à expérimenter 😉.

                    Pour ce qui est de "Contact Initial", cela défini si tu veux que ton personnage soit débloqué dès le début de l'histoire ou si tu préfère qu'il soit débloqué plus tard dans l'histoire. (Je vais t'expliquer ca dans pas longtemps ne t'en fais pas).

                    Si tu as la moindre question, tu peux survoler avec ta sourie le petit "?" et tu aura plus d'informations.

                    La zone 2 (en bleu) permets de dire qui, dans l'histoire, ton personnage connait et qu'est ce qu'il sait de la personne. Par exemple, si je veux recréer Star Wars et que je créer un personnage Yoda et Dark Vador, je peux dire dans les paramètres de Yoda qu'il connait Dark Vador et qu'il le déteste.
                    A noter que vous ne verrez apparaitre que les personnages que vous avez créer. Si vous en rajouter, il faudra que vous retourniez dans les parametres du personnage (via le bouton avec une icone de stylo) pour cocher les nouveaux personnages si vous souhaitez qu'ils soient connus.

                    Une fois que tu es satisfait de ton personnage, tu peux cliquer sur le bouton "Créer"
                </p>
            </Segment>

            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }}>Prévisualiseur</Header>
                <Image src="https://firebasestorage.googleapis.com/v0/b/storiagl.appspot.com/o/PublicImages%2FCreation6.png?alt=media&token=3ea7983e-de14-420f-ac1a-98e0d636ac35" alt="OpenAI GPT4o" fluid />
                <p style={{ fontSize: '1.2em', color: '#5a5a5a' }}>
                    En réalité, c'est pensé comme un système de messagerie conventionnel (comme Message ou iMessage). C'est comme si tu conversait par SMS avec tes personnages. Ils peuvent t'envoyer du texte, des vidéos, des images, des pdfs, de l'audio, tout ce que tu veux.

                    La zone 1 permet de voir la liste des personnages.
                    Vous pouvez cliquer sur le stylo pour modifier ses parametres ou bien cliquer sur la poubelle pour le supprimer.
                    Tous vos personnages avec "Contact Initial" d'activé seront dans la zone "Contacts Initiaux" et seront donc visible dès que le Lecteur arrivera dans le jeu.
                    Les "Contacts Non Initiaux" quand à eux ne seront visibles qu'une fois débloqués.

                    La Zone 2 vous permets de voir la conversation que vous avez avec le contact actuellement selectionné. Pour voir la discussion avec un autre contact, il vous suffit de cliquer sur ce contact.
                    Dans le mode création, cela te permet de mettre un début de conversation. Cela peut être interressant pour retirer le syndrome de la page blanche et aussi de planter le décors et l'intrigue pour que ton Lecteur ne soit pas perdu. Pense en quelque sorte à une sorte d'introduction dans un livre ou un film.
                    Pour rajouter un message, clique sur le bouton "+" de la zone 2. Vas-y, je t'attends.
                </p>
            </Segment>

            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }}>Ajouter un message</Header>
                <Image src="https://firebasestorage.googleapis.com/v0/b/storiagl.appspot.com/o/PublicImages%2FCreation7.png?alt=media&token=af25e8c4-4ee6-4a1b-9421-0813174ad8e1" alt="OpenAI GPT4o" fluid />
                <p style={{ fontSize: '1.2em', color: '#5a5a5a' }}>
                    Normalement tu devrais voir une pop up comme celle ci.
                    Il te suffit d'écrire le message et de selectionner quel personne à écrit ce message (Le Lecteur ou bien le personnage avec lequel il interagit).
                    /!\ Tu peux rajouter une "Ressource Complexe", c'est à dire une image, une vidéo, un audio, un pdf, ce que tu veux. Cela va supprimer ce que tu as écris comme message et le remplacer par un tag de la forme #*X*# avec X le numéro de la référence (tu peux le retrouver en bas de la gestion des parametres). Ce tag permets au LLM d'identifier la ressource. 
                    Actuellement, on ne peux pas mettre du texte et des ressources complexes en meme temps pour des raisons techniques mais cela devrait bientôt être possible.
                </p>
            </Segment>

            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }}>Creer ton histoire</Header>
                <Image src="https://firebasestorage.googleapis.com/v0/b/storiagl.appspot.com/o/PublicImages%2FCreation8.png?alt=media&token=45f0deaa-c3b3-40a2-a533-aa42cfcee16d" alt="OpenAI GPT4o" fluid />
                <p style={{ fontSize: '1.2em', color: '#5a5a5a' }}>
                    Hé ben voilà, tout sait tout ce qu'il faut pour commencer à créer de super histoires.

                    Continue à créer ton histoire pour qu'elle corresponde exactement à ce que tu souhaites.
                    Soit créatif 😉, mais surtout fait toi plaisir. N'hésite pas à créer des histoires drôles, dramatiques, romantique ou bien fantastiques. Que ce soit des fan fictions, des créations originales, des privates jokes. 
                    La seule limite, c'est ton imagination ! 

                    Pour des fonctionnalités plus avancées, n'hésite pas à explorer, à expérimenter, à lire la documentation avancée (qui est le chapitre suivant), ou meme à nous contacter pour nous demander.
                </p>
            </Segment>

            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }}>Valider ton histoire</Header>
                <Image src="https://firebasestorage.googleapis.com/v0/b/storiagl.appspot.com/o/PublicImages%2FCreation9.png?alt=media&token=6bff0bf8-656a-4c4f-819c-ca99113f98c8" alt="OpenAI GPT4o" fluid />
                <p style={{ fontSize: '1.2em', color: '#5a5a5a' }}>
                    Ca y est ?
                    Ton histoire est prete ?
                    Alors il ne reste plus qu'à la valider en scrollant un peu et en cliquant sur ce bouton "Ajouter l'histoire".
                    Ne t'en fais pas si tout n'est pas parfait, tu pourra toujours revenir pour faire des ajustements. Pense juste à cliquer sur le bouton "Ajouter l'histoire" pour sauvegarder les changements, sinon ca ne sera pas sauvegardé 😉. 
                </p>
            </Segment>
            <Button primary floated="right" href="/guide/creation-parametres-avancés"><p>Continuer</p></Button>

        </Container>
    );
}
