import '../App.css';

// Primarily used in creation mode to ensure data integrity
export class FieldIssueChecker {
    constructor() {
        this.fieldIssues = [];
    }
  
    checkFieldIssues(fieldName, fieldConfig) {
      const fieldIssuesTriggers = fieldConfig[fieldName] || {};
  
      const newFieldIssues = [
        ...this.fieldIssues.filter(issue => !(issue in fieldIssuesTriggers)), // first add issues that are not related to this field
        ...Object.keys(fieldIssuesTriggers) // then add result from this field
            .filter(errorMsg => fieldIssuesTriggers[errorMsg])
      ];
  
      this.fieldIssues = newFieldIssues;
    }

    hasFieldIssues() {
        return this.fieldIssues.length > 0;
    }

    getReactMessage(t) {
      return <p className="field-error-text">
          {this.fieldIssues.length > 0 && this.fieldIssues.map(issue => (
          <>- {t(issue)}<br /></>
          ))}
      </p>
    }

    clone() {
        const clone = new FieldIssueChecker(this.fieldIssues);
        clone.fieldIssues = this.fieldIssues;
        return clone;
    }
}