import React, { useState, useEffect, useRef, useContext } from 'react';
import { doc, collection, getDoc, getDocs, deleteDoc } from 'firebase/firestore';
import '../Create/CreateListStories.css';
import { Button, Divider, Container, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import StoryItem from './StoryItem';
import { db } from '../firebaseConfig';
import { StoryContext } from '../Context/StoryContext';

const SelectStories = (props) => {
    const { env } = props;
    const storyContext = useContext(StoryContext);
    const { setContactList, setMessagesList, setEventsList } = storyContext;
    const { setUuidOfCurrentStory, setAlreadyStartedStories } = props.env;
    const [stories, setStories] = useState([]);
    const [storiesAlreadyStarted, setStoriesAlreadyStarted] = useState([]);
    let listOfIdsOfStoriesCreatedByStoria = ["yoL4xNq8Roup87SLUswF", "Za2kgJ5eqkuBETwf1CMg"];
    const [storiesCreatedByStoria, setStoriesCreatedByStoria] = useState([]);
    const [loadingMore, setLoadingMore] = useState(false);
    const [lastVisible, setLastVisible] = useState(null);
    const observer = useRef();
    const { t } = useTranslation();

    const fetchStories = async () => {
        const q = collection(db, "stories");
        const querySnapshot = await getDocs(q);
        const fetchedStories = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        fetchedStories.sort((a, b) => {
            const aDate = a.creation_date ? a.creation_date.toDate() : new Date(0);
            const bDate = b.creation_date ? b.creation_date.toDate() : new Date(0);
            return bDate - aDate;
        });
        setStories(fetchedStories.slice(0, 10));
        setLastVisible(fetchedStories[9]);
    };

    useEffect(() => {
        fetchStories();
    }, []);

    useEffect(() => {
        const loadMoreStories = async () => {
            if (loadingMore) return;

            setLoadingMore(true);

            const q = collection(db, "stories");
            const querySnapshot = await getDocs(q);
            const fetchedStories = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            fetchedStories.sort((a, b) => {
                const aDate = a.creation_date ? a.creation_date.toDate() : new Date(0);
                const bDate = b.creation_date ? b.creation_date.toDate() : new Date(0);
                return bDate - aDate;
            });

            const newStories = fetchedStories.filter(
                (story) => !stories.some((s) => s.id === story.id)
            );

            setStories((prevStories) => [...prevStories, ...newStories.slice(0, 10)]);
            setLastVisible(newStories[9]);
            setLoadingMore(false);
        };

        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && lastVisible) {
                loadMoreStories();
            }
        });

        if (lastVisible && lastVisible instanceof Element) {
            observer.current.observe(lastVisible);
        }

        return () => {
            if (observer.current) observer.current.disconnect();
        };
    }, [loadingMore, lastVisible, stories]);

    const fetchStoriesCreatedByStoria = async () => {
        const fetchedStories = [];

        for (const id of listOfIdsOfStoriesCreatedByStoria) {
            const storyRef = doc(db, "stories", id);
            const docSnap = await getDoc(storyRef);

            if (docSnap.exists()) {
                fetchedStories.push({ id: docSnap.id, ...docSnap.data() });
            } else {
                console.log("No such document!");
            }
        }
        fetchedStories.sort((a, b) => {
            const aDate = a.creation_date ? a.creation_date.toDate() : new Date(0);
            const bDate = b.creation_date ? b.creation_date.toDate() : new Date(0);
            return bDate - aDate;
        });
        setStoriesCreatedByStoria(fetchedStories);
    };

    const fetchStoriesAlreadyStarted = async () => {
        if (env.user) { // Make sure 'user' exists and is a string
            const q = collection(db, `users/${env.user.uid}/stories`);
            const querySnapshot = await getDocs(q);
            const fetchedStories = querySnapshot.docs.map(doc => ({
                idDoc: doc.id,
                ...doc.data(),
            }));
            fetchedStories.sort((a, b) => {
                const aDate = a.creation_date ? a.creation_date.toDate() : new Date(0);
                const bDate = b.creation_date ? b.creation_date.toDate() : new Date(0);
                return bDate - aDate;
            });
            setStoriesAlreadyStarted(fetchedStories);
        }
    };

    useEffect(() => {
        fetchStoriesCreatedByStoria();
        fetchStoriesAlreadyStarted();
    }, [env.user]);

    return (
        <>
            <Container>
                <Segment basic className="gridIalreadyStarted">
                    {(storiesAlreadyStarted.length > 0) &&
                        <div className="stories-hero">
                            <h2>{t('StoriesIAlreadyStarted')}</h2>
                            {storiesAlreadyStarted.length > 0 ? (
                                storiesAlreadyStarted.map((story) => (
                                    <StoryItem
                                        env={env}
                                        storyElement={story}
                                        fetchStoriesFunction={fetchStoriesAlreadyStarted}
                                        alreadyStarted={true}
                                        key={story.id + 5}
                                        deleteActived={true}
                                    ></StoryItem>
                                ))
                            ) : (
                                <p>{t('NoStoriesFound')}</p>
                            )}
                        </div>
                    }
                </Segment>

                <Divider section />
                <Segment basic className="stories-hero">
                        <h2>{t('MadeByStoria')}</h2>
                        {storiesCreatedByStoria.length > 0 ? (
                            storiesCreatedByStoria.map((story) => (
                                <StoryItem
                                    env={env}
                                    storyElement={story}
                                    fetchStoriesFunction={fetchStoriesCreatedByStoria}
                                    alreadyStarted={false}
                                    key={story.id + 5555}
                                    duplicateActived={story.duplication !== undefined ? story.duplication : true}
                                ></StoryItem>
                            ))
                        ) : (
                            <p>{t('NoStoriesFound')}</p>
                        )}
                </Segment>


                <Divider section />
                <Segment basic className="stories-hero">
                        <h2>{t('AllStories')}</h2>
                        {stories.length > 0 ? (
                            stories.map((story, index) => (
                                (story.parameters.access === 'public') && (
                                    <div style={{ display: 'flex' }} key={story.id} ref={index === stories.length - 1 ? el => setLastVisible(el) : null}>
                                        <StoryItem
                                            env={env}
                                            storyElement={story}
                                            fetchStoriesFunction={fetchStories}
                                            alreadyStarted={false}
                                            key={story.id + 9999999}
                                            editActived={story.owner === env.user?.uid}
                                            duplicateActived={story.duplication !== undefined ? story.duplication : true}
                                            deleteActived={story.owner === env.user?.uid}
                                        ></StoryItem>
                                    </div>
                                )
                            ))
                        ) : (
                            <p>{t('NoStoriesFound')}</p>
                        )}
                        {loadingMore && <p>Loading more stories...</p>}
                </Segment>

                <Divider section />
            </Container>

        </>
    );
};

export default SelectStories;
