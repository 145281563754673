import React, { useState } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from 'firebase/auth';
import { Button, Form, Message, Grid, Segment, Header, Container } from 'semantic-ui-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import "./SignUp.css";
import { auth } from '../firebaseConfig';
import { updateUserTokenCount, checkUserRegistration } from '../Utils/Utils';
import { useTranslation } from 'react-i18next';

function SignUp() {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  // Get the location where the user was before
  const from = location.state?.from || "/";

  const provider = new GoogleAuthProvider();

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError('');
    setEmailError('');
    setPasswordError('');

    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }

    if (password.length < 6) {
      setPasswordError('The password must be at least 6 characters long.');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      handleTokenInitialisationWhenCreateAccount(user);
      navigate(from); // Redirect back to the previous page
    } catch (error) {
      setError(error.message);
    }
  };

  const handleTokenInitialisationWhenCreateAccount = async (user) => {
    const isRegistered = await checkUserRegistration(user.uid);
    if (!isRegistered) {
      const updateSuccess = await updateUserTokenCount(user.uid, 100);
      if (!updateSuccess) {
        throw new Error('Failed to update user token count.');
      }
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;
      handleTokenInitialisationWhenCreateAccount(user);
      navigate(from); // Redirect back to the previous page
    } catch (error) {
      setError(error.message);
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <Container className="full-height">
      <Segment textAlign="center">
        <Grid textAlign="center" verticalAlign="middle" className="auth-form">
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" color="teal" textAlign="center">
              {t('SignUp')}
            </Header>
            <Form onSubmit={handleSignUp} size="large">
              <Segment stacked>
                <Form.Input
                  error={emailError}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fluid
                  icon="mail"
                  iconPosition="left"
                  placeholder="Email"
                  type="email"
                />
                <Form.Input
                  error={passwordError}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  type="password"
                />
                {error && <Message error header="Sign Up Failed" content={error} />}
                <Button type="submit" color="teal" fluid size="large">
                  {t('SignUp')}
                </Button>
              </Segment>
            </Form>
            <Button
              color="google plus"
              fluid
              size="large"
              onClick={handleGoogleSignUp}
              style={{ marginTop: '1rem' }}
            >
              {t('SignUpGoogle')}
            </Button>
            <Message>
              {t('AlreadyAccount')} <Link to="/login" state={{ from }}> {t('LogIn')}</Link>
            </Message>
          </Grid.Column>
        </Grid>
      </Segment>
    </Container>
  );
}

export default SignUp;
