import React from 'react';
import { Container, Header, Segment, Image, Button } from 'semantic-ui-react';
import classes from "./GuideCreerCompte.module.css";

export default function GuideCreerCompte() {
    return (
        <Container className={classes.guideContainer} style={{ padding: '3em 0em' }}>
            <Segment basic textAlign="center">
                <Header as="h1" style={{ fontSize: '2.5em', color: '#2a2a2a' }}>Guide de Création de Compte sur Biffe Corps</Header>
                <p style={{ fontSize: '1.33em', color: '#5a5a5a' }}>
                    Suivez les étapes ci-dessous pour créer votre compte et commencer votre aventure.
                </p>
            </Segment>

            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }}>Étape 1 : Accéder à la Page d'Inscription</Header>
                <Image src="https://firebasestorage.googleapis.com/v0/b/storiagl.appspot.com/o/PublicImages%2FSignUp1.png?alt=media&token=0923e16e-4688-47a7-a4c2-72142a77594b" alt="Formulaire d'inscription" fluid />
                <p style={{ fontSize: '1.2em', color: '#5a5a5a' }}>
                    Rendez-vous sur la page d'inscription pour commencer le processus de <a href="/signup">création de compte</a>.
                </p>
            </Segment>

            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }}>Étape 2 : Remplir le Formulaire</Header>
                <Image src="https://firebasestorage.googleapis.com/v0/b/storiagl.appspot.com/o/PublicImages%2FSignUp.png?alt=media&token=7faffe88-3cf5-4d1b-a0cd-c21b6415a03e" alt="Page d'inscription" fluid />
                <p style={{ fontSize: '1.2em', color: '#5a5a5a' }}>
                    Remplissez le formulaire d'inscription avec vos informations personnelles, à savoir adresse e-mail et mot de passe.
                </p>
            </Segment>

            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }}>C'est tout !</Header>
                <p style={{ fontSize: '1.2em', color: '#5a5a5a' }}>
                    Et oui, c'est tout. Ce n'était pas si dur hein ?
                    Maintenant, il ne vous reste plus qu'à profiter de l'application. 
                </p>
            </Segment>
            <Button primary floated="right" href="/guide/choisir-modele"><p>Continuer</p></Button>
        </Container>
    );
}
