import React, { useState } from 'react';
import { signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { Button, Form, Message, Grid, Segment, Header, Container } from 'semantic-ui-react';
import { Link, useNavigate } from 'react-router-dom';
import "./Login.css"
import { auth } from '../firebaseConfig'; // Assuming you have initialized firebase and exported auth from firebaseConfig
import { useTranslation } from 'react-i18next';

function Login() {
  const { t, i18n } = useTranslation();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setError('');
        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate('/');
        } catch (error) {
            setError(error.message);
        }
    };

    const handleGoogleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        try {
            await signInWithPopup(auth, provider);
            navigate('/');
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <Container className="full-height">
            <Segment textAlign="center">
                <Grid textAlign="center" verticalAlign="middle" className="auth-form">
                    <Grid.Column style={{ maxWidth: 450 }}>
                        <Header as="h2" color="teal" textAlign="center">
                            {t('LogIn')}
                        </Header>
                        <Form onSubmit={handleLogin} size="large">
                            <Segment stacked>
                                <Form.Input
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    fluid
                                    icon="mail"
                                    iconPosition="left"
                                    placeholder="Email"
                                    type="email"
                                />
                                <Form.Input
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    fluid
                                    icon="lock"
                                    iconPosition="left"
                                    placeholder="Password"
                                    type="password"
                                />
                                {error && <Message error header="Login Failed" content={error} />}
                                <Button type="submit" color="teal" fluid size="large">
                                    {t('LogIn')}
                                </Button>
                            </Segment>
                        </Form>
                        <Button
                            color="google plus"
                            fluid
                            size="large"
                            onClick={handleGoogleSignIn}
                            style={{ marginTop: '1rem' }}
                        >
                            {t('LogInGoogle')}
                        </Button>
                        <Message>
                            {t('NoAccount')}<Link to="/signup">{t('SignUp')}</Link>
                        </Message>
                    </Grid.Column>
                </Grid>
            </Segment>
        </Container>
    );
}

export default Login;