import React, { useContext } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import "./StartModal.css"
import { useTranslation } from 'react-i18next';
import { StoryContext } from '../Context/StoryContext';



const StartModal = ({openGreetingMessage, setOpenGreetingMessage}) => {
  const { t } = useTranslation();
  const story = useContext(StoryContext);
  
  return (
    <Modal open={openGreetingMessage} size="tiny">
      <Modal.Header><h1>{story.parameters.name}</h1></Modal.Header>
      <Modal.Content>
        <div className="description">{story.homeGreetingMessage}</div>
      </Modal.Content>
      <Modal.Actions>
          <Button style={{ backgroundColor: '#004F78', color: '#fff' }} onClick={()=>setOpenGreetingMessage(false)}>
            {t('Continue')}
          </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default StartModal;