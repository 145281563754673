import React, { useState } from 'react';
import { Container, Header, Segment, Form, Button, Grid, Icon, Divider, List } from 'semantic-ui-react';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import classes from "./FootersPages.module.css"

export default function ContactUs() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            await addDoc(collection(db, 'contacts'), {
                name,
                email,
                message,
                timestamp: new Date(),
            });
            setSuccessMessage('Your message has been sent successfully!');
            setName('');
            setEmail('');
            setMessage('');
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    return (
        <Container style={{ padding: '3em 0em' }}>
            <Segment basic textAlign="center">
                <Header as="h1" style={{ fontSize: '2.5em', color: '#fff'  }}>Contact Us</Header>
                <p style={{ fontSize: '1.33em', color: '#fff'  }}>
                    We’d love to hear from you! Reach out with any questions or feedback.
                </p>
            </Segment>
            
            <Divider section />

            <Segment>
                <Grid  columns={2} textAlign="left">
                    <Grid.Column width={8}>
                        <Header as="h2" style={{ fontSize: '2em' }}>Get in Touch</Header>
                        <p style={{ fontSize: '1.2em' }}>
                            Feel free to reach us via the contact form or directly through the details below.
                        </p>
                        <Header as="h3">Contact Information</Header>
                        <p><Icon name="mail" /> marin.luet@gmail.com</p>
                        <p><Icon name="phone" /> (+33) 07 69 22 32 74</p>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Header as="h2" style={{ fontSize: '2em' }}>Send Us a Message</Header>
                        <Form onSubmit={handleSubmit}>
                            <Form.Field>
                                <label>Name</label>
                                <input 
                                    placeholder='Your Name' 
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required 
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Email</label>
                                <input 
                                    type="email" 
                                    placeholder='Your Email' 
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required 
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Message</label>
                                <textarea 
                                    placeholder='Your Message' 
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    required 
                                />
                            </Form.Field>
                            <Button primary type='submit'>Submit</Button>
                        </Form>
                        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
                    </Grid.Column>
                </Grid>
            </Segment>
            
            <Divider section />
            
            <Segment className={`${classes.aboutus}`} textAlign="center" basic>
                <Header as="h3">Contact Us</Header>
                <List horizontal divided link size="large">
                    <List.Item as="a" href="mailto:marin.luet@gmail.com">Email Us</List.Item>
                    <List.Item as="a" href="https://www.facebook.com/profile.php?id=100092842307846">Facebook</List.Item>
                    <List.Item as="a" href="https://discord.gg/eygzMAVF2X">Discord</List.Item>
                </List>
            </Segment>
        </Container>
    );
}
