import React, { useContext, useEffect, useState, useMemo } from 'react';
import './Message.css';
import { useTranslation } from 'react-i18next';
import { getStorage, ref, getMetadata, getDownloadURL } from 'firebase/storage';
import { StoryContext } from '../Context/StoryContext';
import { Button, Card } from 'semantic-ui-react';
import { EnvironmentContext } from '../Context/EnvironmentContext';
import { CreateContext } from '../Context/CreateContext';
import moment from 'moment';
import 'moment/locale/fr';

const Message = ({ author, date, time, message, erreur, receivers, senders, messageId }) => {
    const story = useContext(StoryContext);
    const env = useContext(EnvironmentContext);
    const createContext = useContext(CreateContext)
    const { t } = useTranslation();
    const nameClass = author === "user" ? "player" : (author === "system" ? "system" : "ordi");

    const textContent = typeof message === 'string' ? message : '';

    const safeSenders = Array.isArray(senders) ? senders : [senders];
    const safeReceivers = Array.isArray(receivers) ? receivers : [receivers];

    const messageParts = useMemo(() => textContent.split('§-'), [textContent]);
    const [renderedParts, setRenderedParts] = useState(messageParts.map((part) => part));

    // Merge of date and time
    const messageDate = new Date(`${date}T${time}`);

    useEffect(() => {
        const handleMedia = async (part) => {
            const match = part.match(/#%(\d+)%#/);
            if(!match) return part;
            if (match && story.parameters.ressources_complexes && match[1] <= story.parameters.ressources_complexes.length) {
                const resourceId = match[1];
                const mediaUrl = `RessourcesComplexes/${story.id}/${resourceId}`;
                const mediaRef = ref(getStorage(), mediaUrl);

                try {
                    const metadata = await getMetadata(mediaRef);
                    const type = metadata.contentType;
                    const downloadUrl = await getDownloadURL(mediaRef);

                    if (type.startsWith('image/')) {
                        return <img src={downloadUrl} alt="Media" width="100px" />;
                    } else if (type.startsWith('video/')) {
                        return (
                            <video controls width="300px" height="auto">
                                <source src={downloadUrl} type={type} />
                                Your browser does not support the video tag.
                            </video>
                        );
                    } else if (type.startsWith('audio/')) {
                        return (
                            <audio controls>
                                <source src={downloadUrl} type={type}/>
                                Your browser does not support the audio element.
                            </audio>
                        );
                    } else {
                        return part;
                    }
                } catch (error) {
                    console.error("Error fetching media:", error);
                    return part;
                }
            } else {
                return part;
            }
        };

        const processMessageParts = async () => {
            const processedParts = await Promise.all(messageParts.map(handleMedia));
            setRenderedParts(processedParts);
        };

        processMessageParts();
    }, [messageParts, story.ressourcesComplexes, story.id]);

    const handleDeleteMessage = async () => {
        const updatedMessagesList = story.messagesList.filter(message => message.id !== messageId);
        story.setMessagesList(updatedMessagesList);
    }

    const handleEditMessage = async () => {
        
    }

    return (
        <div className={`message-container ${nameClass}`}>
            {renderedParts.map((part, index) => (
                <div key={index} className={`message ${index !== 0 ? 'scene-description' : ''}`}>
                    {part}
                    {env.createMode && 
                    <div className="group-buttons" >
                        <Button.Group>
                            <Button
                                icon='pencil alternate'
                                color='green'
                                className="message-buttons"
                                style={{ marginLeft: "0px" }}
                                onClick={(e) => {
                                    createContext.setEditMessage(messageId);
                                    createContext.setOpenPopUpAddNewMessage_CreationMode(true);
                                }}
                            />
                            <Button
                                icon='trash'
                                color='red'
                                className="delete-message-button"
                                onClick={(event) => {
                                    handleDeleteMessage();
                                    event.stopPropagation();
                                }}
                                style={{ marginLeft: "0px" }}
                            />
                        </Button.Group>
                    </div>
                }
                </div>
            ))}
            {erreur ? (
                <div className="erreur">
                    {t('ErrorForfaitSoldout')}
                </div>
            ) : (
                <div className="date">
                    {(new Date(story.dynamicTime) - messageDate < 604800000) ? // if the message is less than a week old
                    moment(messageDate).format('ddd[, ]HH:mm') :
                    moment(messageDate).format('DD/MM/YYYY[, ]HH:mm')
                    }
                    {safeSenders.length !== 1 && ` | envoyé par : ${safeSenders.join(', ')}`} 
                    {safeReceivers.length !== 1 && ` | reçu par : ${safeReceivers.join(', ')}`}
                </div>
            )}

        </div>
    );
};

export default Message;
