import React from 'react';
import { Container, Header, Segment, Grid, Card, Button, Divider, Icon } from 'semantic-ui-react';
import Settings from '../Settings/Settings';
import classes from "./FootersPages.module.css"

const models = [
    {
        title: 'OpenAI GPT-4o',
        description: 'An advanced model with excellent story comprehension, making it ideal for immersive experiences. Note: This option may incur higher costs.',
        image: 'OpenAI.png',
    },
    {
        title: 'Groq Llama-3.1-70B',
        description: 'A powerful open-source model by Meta, offering rapid responses via Groq technology. Available for free with generous rate limits, though reasoning may be slightly less refined than GPT-4o.',
        image: 'Groq.png',
    },
    {
        title: 'Local',
        description: 'Run models locally using your hardware with Ollama support. If unfamiliar, an installer will guide you through the setup when you select "Local" in AI settings.',
        image: 'Local.png',
    },
];

export default function TutoChooseModel() {
    return (
        <Container style={{ padding: '3em 0em' }}>
            <Segment basic textAlign="center">
                <Header as="h1" style={{ fontSize: '2.5em', color: '#fff' }}>Choose the Right AI for Your Story</Header>
                <p style={{ fontSize: '1.2em', color: '#fff' }}>
                    Select a model that aligns with your storytelling needs for the best interactive experience.
                </p>
            </Segment>

            <Divider section />

            <Segment basic textAlign="center">
                <Header as="h2" style={{ fontSize: '2em', color: '#fff' }}>Recommended Models</Header>
                <Grid stackable columns={3}>
                    {models.map((model, index) => (
                        <Grid.Column key={index}>
                            <Card fluid raised style={{ transition: 'transform 0.2s', boxShadow: '0 16px 32px rgba(0,0,0,0.5)' }}>
                                <img 
                                    src={model.image} 
                                    alt={`${model.title} logo`} 
                                    style={{ width: '100%', height: '180px', objectFit: 'cover' }}
                                />
                                <Card.Content>
                                    <Card.Header style={{ fontSize: '1.4em', color: '#3b3b3b' }}>{model.title}</Card.Header>
                                    <Card.Description style={{ color: '#666', fontSize: '1em' }}>
                                        {model.description}
                                    </Card.Description>
                                </Card.Content>
                                {/* <Card.Content extra>
                                    <Button primary fluid style={{ marginTop: '10px' }}>
                                        <Icon name="check circle outline" aria-label="Select" />
                                        Select {model.title}
                                    </Button>
                                </Card.Content> */}
                            </Card>
                        </Grid.Column>
                    ))}
                </Grid>
            </Segment>

            <Divider section />

            <Segment basic textAlign="center">
                <Card fluid>
                    <Card.Content>
                        <Card.Header style={{ fontSize: '1.4em', color: '#3b3b3b' }}>
                            Now that you know better, pick the AI that you want to use down below:
                        </Card.Header>
                        <Divider />
                        <Card.Description>
                            <Settings />
                        </Card.Description>
                    </Card.Content>
                </Card>
            </Segment>
        </Container>
    );
}
