import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Login from './Login';
import SignUp from './SignUp';
import { Button, Modal } from 'semantic-ui-react';

export default function AccessPrompt({ isAuthenticated, onClose }) {
    const { t } = useTranslation();
    const [showLogin, setShowLogin] = useState(false);
    const [showSignUp, setShowSignUp] = useState(false);

    return (
        <Modal open={!isAuthenticated} onClose={onClose}>
            {!showLogin && !showSignUp && (
                <Modal.Content>
                    <Modal.Header>{t('PleaseConnectToAccessPage')}</Modal.Header>
                    <Button onClick={() => setShowLogin(true)}>{t('LogIn')}</Button>
                    <Button onClick={() => setShowSignUp(true)}>{t('SignUp')}</Button>
                </Modal.Content>
            )}
            {showLogin && (
                <Login onClose={() => setShowLogin(false)} />
            )}
            {showSignUp && (
                <SignUp onClose={() => setShowSignUp(false)} />
            )}
        </Modal>
    );
}