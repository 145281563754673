import React, { useContext, useEffect, useRef, useState } from 'react';
import { Image, Menu, Button, Container, Icon, List, Grid, Header, Segment } from 'semantic-ui-react';
import { Navigate, Route, Routes } from 'react-router-dom';
import "./App.css"
import { Link } from 'react-router-dom';
import Story from './Stories/Story';
import Create from './Create/Create';
import {
    auth,
    signOut,
    onAuthStateChanged,
    db,
} from './firebaseConfig';
import Login from './Login/Login';
import SignUp from './Login/SignUp';
import HomePage from './HomePage/HomePage';
import Settings from './Settings/Settings';
import { onSnapshot, doc } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import CreateListStories from './Create/CreateListStories';
import SelectStories from './Stories/SelectStory';
import LanguageDropdown from './Utils/LanguageDropdown'; // Import the LanguageDropdown component
import AdminPage from './Utils/AdminPage';
import { StoryProvider } from './Context/StoryContext'
import { EnvironmentContext } from './Context/EnvironmentContext'
import { CreateProvider } from './Context/CreateContext';
import AboutUs from './FootersPages/AboutUs';
import ContactUs from './FootersPages/ContactUs';
import TutoChooseModel from './FootersPages/TutoChooseModel';
import GettingStarted from './FootersPages/GettingStarted';
import DocumentationVideo from './FootersPages/DocumentationVideo';
import DocumentationTexteCarousel from './FootersPages/DocumentationTexte';
import GuideCreerCompte from './FootersPages/Guide/GuideCreerCompte';
import ChoisirModele from './FootersPages/Guide/ChoisirModele';
import ChoisirHistoire from './FootersPages/Guide/SelectionHistoire';
import CreerHistoire from './FootersPages/Guide/CreerHistoire';

export default function App() {
    const location = useLocation();
    const refWhereToScroll = useRef(null);
    const env = useContext(EnvironmentContext);
    const [loading, setLoading] = useState(true);
    let unsubscribeUserSettings = () => { };
    let unsubscribeTokenCount = () => { };

    const { t } = useTranslation();

    const handleSignOut = async () => {
        try {
            await signOut(auth);
        } catch (error) {
            console.error("Error signing out", error);
        }
    };

    const handleFullScreenChange = () => {
        env.setIsFullScreen(!!document.fullscreenElement);
    };

    useEffect(() => {
        document.addEventListener("fullscreenchange", handleFullScreenChange);

        const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
            env.setUser(currentUser);
            setLoading(false);
            unsubscribeTokenCount();
            unsubscribeUserSettings();

            if (currentUser) {
                const userRef = doc(db, 'users', currentUser.uid);

                // Subscribe to token count changes
                unsubscribeTokenCount = onSnapshot(userRef, (doc) => {
                    if (doc.exists() && doc.data().tokenCount !== undefined) {
                        env.setUserTokenCount(doc.data().tokenCount);
                    } else {
                        env.setUserTokenCount(0);
                    }
                });

                // Subscribe to user settings changes (apiKey and model)
                unsubscribeUserSettings = onSnapshot(userRef, (doc) => {
                    if (doc.exists()) {
                        const data = doc.data();
                        if (data.apiKey) env.setApiKey(data.apiKey);
                        if (data.model) env.setModelChosen(data.model);
                    }
                }, (error) => {
                    console.error("Error getting user settings:", error);
                });
            } else {
                env.setUserTokenCount(0);
                env.setApiKey('');
                env.setModelChosen('mistral-small'); // reset to default or according to your app logic
            }
        });

        return () => {
            document.removeEventListener("fullscreenchange", handleFullScreenChange);

            unsubscribeAuth();
            unsubscribeTokenCount();
            unsubscribeUserSettings();
        };
    }, []);

    if (loading) {
        return; // Wait environment crucial values (user, token count, etc.) to be set before going anywhere
    }

    const fixed = true;
    return (
        <>
            <div
                className='flexyAppContainer'
                style={{ height: location.pathname.startsWith('/Histoire') ? "100vh" : "unset" }}
            >
                {!env.isFullScreen && (
                    <Menu fixed='top' inverted={!fixed} pointing={!fixed} secondary={!fixed} size="large" style={{ backgroundColor: '#00201f', border: 'none', boxShadow: 'none' }}>
                        <Container>
                            <Menu.Item as={Link} to="/" active style={{ backgroundColor: '#00201f', color: '#fff' }}>
                                {t('Home')}
                            </Menu.Item>
                            <Menu.Item as={Link} to="/SelectStory" style={{ backgroundColor: '#00201f', color: '#fff' }}>
                                {t('Story Mode')}
                            </Menu.Item>
                            <Menu.Item as={Link} to="/Create" style={{ backgroundColor: '#00201f', color: '#fff' }}>
                                {t('Creation Mode')}
                            </Menu.Item>
                            <Menu.Item position='right' style={{ backgroundColor: '#00201f', color: '#fff' }}>
                                {!env.user ? (
                                    <>
                                        <Button as={Link} to="/login" inverted >
                                            {t('LogIn')}
                                        </Button>
                                        <Button as={Link} to="/signup" style={{ marginLeft: '0.5em' }}>
                                            {t('SignUp')}
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        {env.user.photoURL && (
                                            <Image src={env.user.photoURL} avatar alt="Profile Image" />
                                        )}
                                        <span style={{ color: '#fff' }}>{env.user.displayName || env.user.email} - {t("Tokens")}: {env.userTokenCount}</span>
                                        <Button onClick={handleSignOut} style={{ marginLeft: '0.5em', backgroundColor: '#004F78', color: '#fff' }}>
                                            {t('SignOut')}
                                        </Button>
                                    </>
                                )}
                                <Menu.Item position='right' as={Link} to="/settings">
                                    <Icon name="setting" inverted />
                                </Menu.Item>
                                <Menu.Item position='right'>
                                    <LanguageDropdown />
                                </Menu.Item>
                            </Menu.Item>
                        </Container>
                    </Menu >
                )}
                <Container className={`main-content ${env.isFullScreen ? 'fullscreen' : ''}`}>
                    <Routes>
                        <Route path="/" element={<HomePage />} />
                        <Route path="/AdminPage" element={<AdminPage />} />
                        <Route path="/Histoire/:storyId" element={
                            <StoryProvider>
                                <Story />
                            </StoryProvider>
                        } />
                        <Route path="/Create" element={
                            <CreateListStories />}
                        />
                        <Route
                            path="/SelectStory"
                            element={
                                <StoryProvider>
                                    <SelectStories env={env} />
                                </StoryProvider>
                            }
                        />
                        <Route
                            path="/Create/Edit/:storyId"
                            element={
                                !env.user ? (
                                    <Navigate to="/login" replace />
                                ) : (
                                    <StoryProvider>
                                        <CreateProvider>
                                            <Create />
                                        </CreateProvider>
                                    </StoryProvider>
                                )
                            }
                        />
                        <Route path="/login" element={<Login />} />
                        <Route path="/signup" element={<SignUp />} />
                        <Route path="/settings" element={<Settings env={env} />} />
                        <Route path="/AboutUs" element={<AboutUs />} />
                        <Route path="/ContactUs" element={<ContactUs />} />
                        <Route path="/tutoChooseModel" element={<TutoChooseModel />} />
                        <Route path="/GettingStarted" element={<GettingStarted />} />
                        <Route path="/DocumentationVideo" element={<DocumentationVideo />} />
                        <Route path="/DocumentationTexte" element={<DocumentationTexteCarousel />} />
                        <Route path="/guide/creer-compte" element={<GuideCreerCompte />} />
                        <Route path="/guide/choisir-modele" element={<ChoisirModele />} />
                        <Route path="/guide/selectionner-histoire" element={<ChoisirHistoire />} />
                        <Route path="/guide/creer-histoire" element={<CreerHistoire />} />
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes >
                </Container >
                <span ref={refWhereToScroll} />
                {
                    !env.isFullScreen && !(location.pathname.startsWith('/Histoire')) && (
                        <Segment inverted vertical style={{ backgroundColor: "#00201f" }} cet>
                            <Container >
                                <Grid divided inverted stackable>
                                    <Grid.Row centered>
                                        <Grid.Column width={3}>
                                            <Header inverted as='h4' content='About' />
                                            <List link inverted>
                                                <List.Item href="/AboutUs" as='a'>{t('AproposDeNous')}</List.Item>
                                                <List.Item href="/ContactUs" as='a'>{t('ConctactUs')}</List.Item>
                                                <List.Item href="/tutoChooseModel" as='a'>{t('ChoseRightModelForYou')}</List.Item>
                                            </List>
                                        </Grid.Column>
                                        <Grid.Column width={3}>
                                            <Header inverted as='h4' content={t('Documentation')} />
                                            <List link inverted>
                                                <List.Item href="/GettingStarted" as='a'>{t('GettingStarted')}</List.Item>
                                                <List.Item href="/DocumentationVideo" as='a'>{t('DocumentationVideo')}</List.Item>
                                                <List.Item href="/DocumentationTexte" as='a'>{t('DocumentationText')}</List.Item>
                                            </List>
                                        </Grid.Column>
                                        <Grid.Column width={7}>
                                            <Header as='h4' inverted>
                                                Fablia
                                            </Header>
                                            <p>
                                                {t('StoriaDescription')}
                                            </p>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Container>
                        </Segment>
                    )
                }
            </div >
        </>
    );
}