import React, { useEffect, useState } from 'react';
import { Dropdown, Form, Input } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import Markdown from 'react-markdown';

const DropDownSelectionAI = (props) => {
  const [modelDropDownOptions, setModelDropDownOptions] = useState([]);
  const [models, setModels] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [isLocalAISelected, setIsLocalAISelected] = useState(false);
  const [isCustomAISelected, setIsCustomAISelected] = useState(false);
  const { t } = useTranslation();

  const fetchModels = async () => {
    try {
      const modelsCollectionRef = collection(db, 'models');
      const modelDocs = await getDocs(modelsCollectionRef);
      const fetchedModels = [];

      for (const modelDoc of modelDocs.docs) {
        const modelId = modelDoc.id;
        const modelData = modelDoc.data();
        const providers = modelData.providers || [];

        for (const provider of providers) {
          const providerCollectionRef = collection(db, `models/${modelId}/${provider}`);
          const providerDocs = await getDocs(providerCollectionRef);

          providerDocs.forEach((providerDoc) => {
            fetchedModels.push({
              id: providerDoc.id,
              ...providerDoc.data(),
              provider,
            });
          });
        }
      }

      return fetchedModels;
    } catch (error) {
      console.error('Error fetching models:', error);
    }
  };

  const getProviderName = (modelName) => modelName.split(' ')[0];

  useEffect(() => {
    const loadModels = async () => {
      const fetchedModels = await fetchModels();
      const options = fetchedModels.map((model) => ({
        key: model.id,
        text: model.name,
        value: model.name,
        image: { avatar: true, src: require(`../assets/${getProviderName(model.name)}.png`) || 'default_logo.png' },
      }));

      setModels(fetchedModels);
      setModelDropDownOptions(options);
    };

    loadModels();
  }, []);

  const handleChange = (e, { value }) => {
    const selected = models.find((model) => model.name === value);
    setSelectedModel(selected);
    props.setFormValues({ ...props.formValues, model: selected });
    setIsLocalAISelected(value === 'locale');
    setIsCustomAISelected(value === 'custom');
  };

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    props.setFormValues({ ...props.formValues, model: { ...props.formValues.model, [field]: value } });
  };

  return (
    <>
      <Dropdown
        placeholder={t('SelectAI')}
        fluid
        selection
        options={modelDropDownOptions}
        onChange={handleChange}
      />
      {isLocalAISelected && (
        <>
          <a href="https://your-local-ai-download-link">{t('DownloadLocalAI')}_windows</a>
          <br />
          <a href="https://your-local-ai-download-link-linux">{t('DownloadLocalAI')}_linux</a>
          <br />
          <a href="https://your-local-ai-download-link-mac">{t('DownloadLocalAI')}_macos</a>
        </>
      )}
      {isCustomAISelected && (
        <Form>
          <Form.Field>
            <label>{t('CustomModel')}</label>
            <Input
              placeholder={t('CustomModelPlaceholder')}
              value={props.formValues.model.model || ''}
              onChange={(e) => handleInputChange(e, 'model')}
            />
          </Form.Field>
          <Form.Field>
            <label>{t('CustomTemperature')}</label>
            <Input
              type="number"
              step={0.1}
              placeholder={t('CustomTemperaturePlaceholder')}
              value={props.formValues.model.temperature || ''}
              onChange={(e) => handleInputChange(e, 'temperature')}
            />
          </Form.Field>
          <Form.Field>
            <label>{t('CustomAPIUrl')}</label>
            <Input
              placeholder={t('CustomAPIUrlPlaceholder')}
              value={props.formValues.model.APIurl || ''}
              onChange={(e) => handleInputChange(e, 'APIurl')}
            />
          </Form.Field>
        </Form>
      )}
      {['OpenAI', 'Anthropic', 'Mistral', 'Groq', 'DeepSeek'].some(provider =>
        props.formValues.model?.name?.startsWith(provider)
      ) && (
        <Markdown>{t(`${getProviderName(props.formValues.model?.name)}APIKeyInstruction`)}</Markdown>
      )}
    </>
  );
};

export default DropDownSelectionAI;
