import React, { useState, useEffect, useContext } from 'react';
import '../App.css';
import { useTranslation } from 'react-i18next';
import { Accordion, Form, Header, Modal, Segment, Button, Checkbox, Input, Icon, Select, Grid } from 'semantic-ui-react';
import ShortUniqueId from 'short-unique-id';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { uploadIcon } from '../Utils/ContactIcons';
import { FieldIssueChecker } from './FieldIssueChecker'
import { AcquaintanceBase, AcquaintanceEditType } from './AcquaintanceBase';
import { QuestionPopup } from '../Utils/Utils';
import { StoryContext } from '../Context/StoryContext';
import { CreateContext } from '../Context/CreateContext';
import { isContactMainCharacter } from "../Utils/Utils"
import EventsContainer from '../Events/EventsContainer';

const CreateNewContactPopUp_CreationMode = () => {
  const { t } = useTranslation();

  const [newContact, setNewContact] = useState({});
  const [acquaintanceBase, _] = useState(new AcquaintanceBase(setNewContact));
  const [contacts, setContacts] = useState([]);
  const [fieldChecker, setFieldChecker] = useState(new FieldIssueChecker());
  const story = useContext(StoryContext);
  const createContext = useContext(CreateContext);
  const [hasInitialChanged, setHasInitialChanged] = useState(false);

  useEffect(() => {
    if (createContext.editContact) {
      const editContact = story.contactList.find(contact => contact.number === createContext.editContact)
      setNewContact(editContact);
      acquaintanceBase.rebaseFromContact(editContact);

    } else {
      setNewContact({
        name: t('DefaultName'),
        number: new ShortUniqueId({ dictionary: 'number' }).randomUUID(11),
        age: '', //We should verify that it's a valid age in the future
        gender: 'male',
        character_traits: '',
        physical_features: '',
        particularities: '',
        icon: null,
        interactive: true,
        initial: true,
        acquaintances: [],
      });
    }
  }, [createContext.editContact, story.contactList, t]);

  const toggleInteractive = () => {
    setNewContact(prevState => ({
      ...prevState,
      interactive: !prevState.interactive,
    }));
  };

  const handleCreateContact = async () => {
    const updatedContactList = [...story.contactList];
    if(hasInitialChanged){ // TODO Change initial attribute directly.
      if (newContact.initial) {
        story.setContactList([...story.contactList, JSON.parse('{"name":"' + newContact.name + '"}')]);
      } else {
        // Remove the contact from the initial contact list
        const updatedContactList = story.contactList.filter(contact => contact.name !== newContact.name);
        story.setContactList(updatedContactList);
      }
    }
    if (!createContext.editContact) {
      updatedContactList.push(newContact);
      if (newContact.initial) {
        story.setContactList([...story.contactList, JSON.parse('{"name":"' + newContact.name + '"}')]);
      }
    } else {
      const index = updatedContactList.findIndex((contact) => contact.number === createContext.editContact);

      if (index !== -1) {
        updatedContactList.splice(index, 1, newContact);
      } else {
        throw new Error("impossible de modifier le contact");
      }
    }

    story.setContactList(updatedContactList);
    story.setSelectedContact(newContact.number)

    onClose();
  };

  const onClose = () => {
    createContext.setOpenPopUpAddNewContact_CreationMode(false);
    createContext.setEditContact(null);
  };

  const handleContactChange = (e, { name, value }) => {
    setNewContact({ ...newContact, [name]: value });

    const fieldConfig = {
      number: {
        'ContactNumNotNumericError': isNaN(value),
        'ContactNumAlreadyExistError': contacts.some(contact => contact.number === value)
      },
      age: {
        'NegativeAgeError': value < 0,
        'NotIntegerAgeError': value && parseInt(value) !== Number(value)
      },
      name: {
        'ContactNameEmptyError': value.length === 0
      }
    };
    fieldChecker.checkFieldIssues(name, fieldConfig);
    setFieldChecker(fieldChecker.clone());
  };

  const handleAcquaintanceChange = (e, { name, acquaintanceName, value: knowledge }) => {
    acquaintanceBase.handleEdit(name, acquaintanceName, knowledge);
  };

  const handleIconChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
  
    try {
      const iconURL = await uploadIcon(story.id, newContact.number, file);
  
      // Store the file object directly in the state, do not upload yet
      setNewContact({ ...newContact, icon: iconURL });
    } catch (error) {
      console.error("Error uploading icon:", error);
    }
  };

  const toggleInitial = () => {
    setNewContact({ ...newContact, initial: !newContact.initial });
    setHasInitialChanged(!hasInitialChanged);
  };

  const panels =
  [
    {
      key: 'ContactPopUp',
      title: t('AdvancedOptions'),
      content: [
        // Only available today for the create mode due to the lack of synchronisation 
        // between the contact number and the others parameters which use this number as key
        (!createContext.editContact && <Form.Field
          control={Input}
          label={<label>
            {t('Number')}
              <QuestionPopup message={t('Popup_ContactForm_Number')}/>
            </label>}
          name='number'
          placeholder={t('EnterNumber')}
          value={newContact.number}
          onChange={handleContactChange}
          icon='phone'
          iconPosition='left'
          required
        />),
        (<Form.Field
          control={Checkbox}
          toggle
          label={<label>
            {t('Interactive')}
              <QuestionPopup message={t('Popup_ContactForm_Interactive')}/>
            </label>}
          name='interactive'
          checked={newContact.interactive}
          onChange={toggleInteractive}
        />),
        (newContact.initial && <Form.Field
          control={Checkbox}
          toggle
          label={t('FirstSelectedContact')}
          name='firstSelectedContact'
          checked={createContext.firstSelectedContact === newContact.number}
          onChange={() => createContext.setFirstSelectedContact(newContact.number)}
        />)
      ]
    }
  ]

  return (
    <Modal open={createContext.openPopUpAddNewContact_CreationMode} onClose={onClose}>
      <Modal.Header>{t('CreateNewContact')}</Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Row columns={newContact.number === story.mainCharacter.number ? 1 : (createContext.editContact ? 3 : 2)}>
            <Grid.Column>
              <Segment padded>
                <Header as='h2'>
                  <Icon name='address book' />
                  <Header.Content>{t('ContactInformation')}</Header.Content>
                </Header>
                <Form>
                  <Form.Field
                    control={Input}
                    label={t('Name')}
                    name='name'
                    placeholder={t('EnterNameContact')}
                    value={newContact.name}
                    onChange={handleContactChange}
                    icon='user'
                    iconPosition='left'
                    required
                  />
                  <Form.Field
                    control={Input}
                    label={t('Age')}
                    name='age'
                    placeholder={t('EnterAge')}
                    value={newContact.age}
                    type='number' //It isn't restrictive in Firefox, we should block that
                    onChange={handleContactChange}
                    icon='birthday cake'
                    iconPosition='left'
                  />
                  <Form.Field
                    control={Select}
                    label={t('Gender')}
                    name='gender'
                    placeholder={t('EnterGender')}
                    options={[{ key: '1', text: t('Male'), value: 'male' },
                    { key: '2', text: t('Female'), value: 'female' },
                    { key: '3', text: t('OtherGender'), value: 'other' }
                    ]}
                    value={newContact.gender}
                    onChange={handleContactChange}
                    icon='venus mars'
                  />
                  <Form.Field
                    control={Input}
                    label={<label>
                      {t('CharacterTraits')}
                        <QuestionPopup message={t('Popup_ContactForm_CharacterTraits')}/>
                      </label>}
                    name='character_traits'
                    placeholder={t('EnterCharacterTraits')}
                    value={newContact.character_traits}
                    onChange={handleContactChange}
                    icon='lightning'
                    iconPosition='left'
                  />
                  <Form.Field
                    control={Input}
                    label={<label>
                      {t('PhysicalFeatures')}
                        <QuestionPopup message={t('Popup_ContactForm_PhysicalFeatures')}/>
                      </label>}
                    name='physical_features'
                    placeholder={t('EnterPhysicalFeatures')}
                    value={newContact.physical_features}
                    onChange={handleContactChange}
                    icon='male'
                    iconPosition='left'
                  />
                  <Form.Field
                    control={Input}
                    label={<label>
                      {t('Particularities')}
                        <QuestionPopup message={t('Popup_ContactForm_Particularities')}/>
                      </label>}
                    name='particularities'
                    placeholder={t('EnterParticularities')}
                    value={newContact.particularities}
                    onChange={handleContactChange}
                    icon='info circle'
                    iconPosition='left'
                  />
                  <Form.Field
                    control={Input}
                    type="file"
                    label={t('CharacterIcon')}
                    name="image"
                    accept="image/*"
                    onChange={handleIconChange}
                    icon='file image'
                    iconPosition='left'
                    required
                  />
                  <Form.Field
                    control={Checkbox}
                    label={<label>
                      {t('InitialContact')}
                        <QuestionPopup message={t('Popup_ContactForm_InitialContact')}/>
                      </label>}
                    name='initial'
                    checked={newContact.initial}
                    onChange={toggleInitial}
                    toggle
                  />

                  <Accordion as={Form} panels={panels} fluid styled/>

                </Form>
              </Segment>
            </Grid.Column>
            {(newContact.number !== story.mainCharacter.number) && <Grid.Column>
              <Segment padded>
                <Header as='h2'>
                  <Icon name='address book' />
                  <Header.Content>
                    {<>
                      {t('ContactAcquaintances')}
                      <QuestionPopup message={t('Popup_ContactForm_ContactAcquaintances')}/>
                    </>}
                  </Header.Content>
                </Header>
                <Form>
                  {story.contactList
                    .filter(contact => contact.number !== createContext.editContact)
                    .map(contact => {
                      const isAcquaintance = acquaintanceBase.isAcquaintance(contact.name),
                        acquaintanceKnowledge = acquaintanceBase.getAcquaintanceKnowledge(contact.name),
                        acquaintancePhoneNumberKnowledge = acquaintanceBase.getAcquaintancePhoneNumberKnowledge(contact.name);
                      return <>
                        <Form.Field
                          control={Checkbox}
                          label={!isContactMainCharacter(contact) ? contact.name : `${contact.name} (${t('MainCharacter').toLowerCase()})`}
                          name={AcquaintanceEditType.Toggle}
                          acquaintanceName={contact.name}
                          checked={isAcquaintance}
                          onChange={handleAcquaintanceChange}
                        />
                        {isAcquaintance && (<>
                          <Form.Field
                            control={Input}
                            label={<label>
                              {t('KnownInformation')}
                                <QuestionPopup message={t('Popup_ContactForm_KnownInformation')}/>
                              </label>}
                            name={AcquaintanceEditType.ModifyKnowledge}
                            placeholder={t('AcquaintanceKnowledge')}
                            acquaintanceName={contact.name}
                            value={acquaintanceKnowledge}
                            onChange={handleAcquaintanceChange}
                            required
                          />
                          {contact.number !== '0' &&
                            <Form.Field
                              control={Checkbox}
                              toggle
                              label={t('PhoneNumberKnown')}
                              name={AcquaintanceEditType.ModifyPhoneNumberKnowledge}
                              acquaintanceName={contact.name}
                              checked={acquaintancePhoneNumberKnowledge}
                              onChange={handleAcquaintanceChange}
                            />
                          }
                        </>)}
                    </>
                  })}
                </Form>
              </Segment>
            </Grid.Column>}
            {createContext.editContact && newContact.number !== story.mainCharacter.number &&
              <Grid.Column>
                <EventsContainer 
                    disposition={'vertical'}
                    receiversList={[newContact.number]}
                />
              </Grid.Column>
            }
          </Grid.Row>
        </Grid>


      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button positive disabled={fieldChecker.hasFieldIssues()} onClick={handleCreateContact}>
          {!!createContext.editContact ? t('Edit') : t('Create')}
        </Button>

        {fieldChecker.getReactMessage(t)}
      </Modal.Actions>
    </Modal>
  );
};

export default CreateNewContactPopUp_CreationMode;
