import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig'; // Assuming you have a firebaseConfig file
import { collection, getDocs } from 'firebase/firestore';
import './AdminPage.css'; // Ensure your CSS is properly linked

const AdminPage = () => {
    const [stories, setStories] = useState([]);

    useEffect(() => {
        const fetchStories = async () => {
            const q = collection(db, "stories");
            const querySnapshot = await getDocs(q);
            const fetchedStories = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
            }));
            setStories(fetchedStories);
        };

        fetchStories();
    }, []);

    return (
        <>
            <div className='Admin'>
                <h1>Admin Page</h1>
                <div className="storiesHolders">
                    {stories.length > 0 ? (
                        stories.map((story) => (
                            <div key={story.id} className='StoryItems'>
                                <p>ID: {story.id}</p>
                                <p>Name: {story.parameters.name || "Unnamed Story"}</p>
                            </div>
                        ))
                    ) : (
                        <p>No stories found.</p>
                    )}
                </div>
            </div>
        </>
    );
};

export default AdminPage;
