export const AcquaintanceEditType = {
    Toggle: 'isAcquaintance',
    ModifyKnowledge: 'acquaintanceKnowledge',
    ModifyPhoneNumberKnowledge: 'acquaintancePhoneNumberKnowledge',
  };

export class AcquaintanceBase {
  constructor(setNewContact) {
    // acquaintances dict format: key(contact name) => { enabled: boolean, knowledge: string }
    this.acquaintanceDict = {} 
    this.setNewContact = setNewContact;
  }

  rebaseFromContact(contact) {
    this.acquaintanceDict = Object.assign({},
      ...contact.acquaintances?.map((acquaintance) => ({
        [acquaintance.name]: {
          knowledge: acquaintance.knowledge,
          phoneNumberKnowledge: acquaintance.phoneNumberKnowledge,
          enabled: true
        }
      })));
  }

  handleEdit(editType, acquaintanceName, knowledge = undefined) {
    const acquaintance = this.acquaintanceDict[acquaintanceName];

    if (editType === AcquaintanceEditType.Toggle && acquaintance) { // toggle acquaintance on/off
      acquaintance.enabled ^= true; // inversion
    } else if (editType === AcquaintanceEditType.ModifyPhoneNumberKnowledge && acquaintance) {
        acquaintance.phoneNumberKnowledge ^= true;
    } else { // create, enable or modify acquaintance in dictionary
      this.acquaintanceDict[acquaintanceName] = {
        ...acquaintance, // if there was already a knowledge (even disabled)
        enabled: true,
        ...(editType === AcquaintanceEditType.ModifyKnowledge && { knowledge: knowledge })
      };
    }

    this.#updateContact();
  }

  isAcquaintance(contactName) {
    const acquaintance = this.acquaintanceDict[contactName];
    return !!acquaintance?.enabled;
  }

  getAcquaintanceKnowledge(contactName) {
    const acquaintance = this.acquaintanceDict[contactName];
    return acquaintance?.knowledge;
  }

  getAcquaintancePhoneNumberKnowledge(contactName) {
    const acquaintance = this.acquaintanceDict[contactName];
    return acquaintance?.phoneNumberKnowledge;
  }

  #updateContact() {
      // convert acquaintanceDict to correct save format (contact.acquaintances)
      this.setNewContact(prevState => ({
        ...prevState,
        acquaintances: Object.entries(this.acquaintanceDict)
          .filter(([_, acquaintanceInfos]) => acquaintanceInfos.enabled)
          .map(([acquaintanceName, acquaintanceInfos]) => ({
            name: acquaintanceName,
            knowledge: acquaintanceInfos.knowledge ?? '',
            phoneNumberKnowledge: acquaintanceInfos.phoneNumberKnowledge ?? false,
          }))
      }));
  }
}