import React from 'react';
import { Container, Header, Segment, Image, Button } from 'semantic-ui-react';
import classes from "./GuideCreerCompte.module.css";

export default function ChoisirModele() {
    return (
        <Container className={classes.guideContainerChoisirModele} style={{ padding: '3em 0em' }}>
            <Segment basic textAlign="center">
                <Header as="h1" style={{ fontSize: '2.5em', color: '#2a2a2a' }}>Guide sur la Sélection de l'IA</Header>
                <p style={{ fontSize: '1.33em', color: '#5a5a5a' }}>
                    Suivez les étapes ci-dessous pour choisir l'IA la plus adaptée à vos besoins.
                </p>
            </Segment>

            {/* Modèle choisi par le créateur de l'histoire */}
            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }}>Modèle Choisi par le Créateur de l'Histoire</Header>
                <Image src="https://via.placeholder.com/600x400" alt="Modèle IA" fluid />
                <p style={{ fontSize: '1.2em', color: '#5a5a5a' }}>
                    Le créateur de l'histoire peut proposer automatiquement à ses lecteurs une certaine IA, que ce soit pour des raisons de performance ou parce qu'il l'a conçue spécifiquement en tenant compte des caractéristiques de l'IA. Une pop-up apparaîtra pour vous demander si vous souhaitez passer à cette IA (recommandé), mais vous n'êtes pas obligé.
                </p>
            </Segment>

            {/* Le choix de la qualité de la réponse */}
            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }}>Choix de la Qualité de la Réponse (Recommandé)</Header>
                <Image src="/OpenAI.png" alt="OpenAI GPT4o" fluid size='medium' />
                <p style={{ fontSize: '1.2em', color: '#5a5a5a' }}>
                    Si vous êtes prêt à investir dans une IA, nous vous recommandons le modèle <strong>OpenAI GPT4o</strong>. C'est le modèle le plus puissant que nous proposons, un peu moins rapide que Groq Llama 3.1 70B, mais parfaitement utilisable. D'autres IA payantes sont disponibles, mais elles offrent généralement des performances inférieures.
                </p>
            </Segment>

            {/* Le choix gratuit recommandé */}
            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }}>Choix de la Gratuitée (moins performant)</Header>
                <Image src="/Groq.png" alt="Groq Llama 3.1" fluid size='medium' />
                <p style={{ fontSize: '1.2em', color: '#5a5a5a' }}>
                    Si vous souhaitez utiliser une IA gratuitement, nous vous recommandons le modèle <strong>Groq Llama 3.1 70B</strong>. C'est un modèle rapide, puissant et gratuit. Notez cependant qu'il peut rencontrer des problèmes avec des histoires très complexes. Des limites d'utilisation par minute et par jour s'appliquent. Si vous les dépassez, attendez quelques minutes avant de réessayer ou contactez le support.
                </p>
            </Segment>

            {/* Le choix de la rapidité de réponse */}
            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }}>Choix de la Rapidité de Réponse (Très peu performant)</Header>
                <Image src="/Groq.png" alt="Groq Llama 3.1 8B" fluid size='medium' />
                <p style={{ fontSize: '1.2em', color: '#5a5a5a' }}>
                    Si la rapidité de réponse est primordiale, nous vous recommandons le modèle <strong>Groq Llama 3.1 8B</strong>. C'est le modèle le plus rapide et gratuit, mais il peut être limité et faire des erreurs de traduction.
                </p>
            </Segment>

            {/* Le choix local */}
            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }}>Choix Local</Header>
                <div style={{ backgroundColor: 'white', borderRadius: "15px", padding: "10px" }}>
                    <Image src="/Local.png" alt="Installation Locale" fluid size='medium' />
                </div>
                <p style={{ fontSize: '1.2em', color: '#5a5a5a' }}>
                    Si vous préférez faire tourner l'IA sur votre propre ordinateur, vous pouvez utiliser notre installateur spécial. Allez dans les paramètres, puis sélectionnez "Local" dans la liste des IA disponibles. Suivez simplement les instructions à l'écran. Nous utilisons <strong>Ollama avec Llama 3.1 8B</strong>, il n'est donc pas nécessaire de le réinstaller si vous l'avez déjà. Pour d'autres modèles, veuillez utiliser l'IA "Custom".
                </p>
            </Segment>

            {/* Le choix custom */}
            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }}>Choix Custom</Header>
                <div style={{ backgroundColor: 'white', borderRadius: "15px", padding: "10px" }}>
                    <Image src="/Custom.png" alt="IA Custom" fluid size='medium' />
                </div>
                <p style={{ fontSize: '1.2em', color: '#5a5a5a' }}>
                    <strong>/!\\ Pour Utilisateurs Avancés /!/\\</strong> Si aucune des IA proposées ne vous convient et que vous souhaitez utiliser votre propre modèle ou un autre service d'inférence, sélectionnez l'IA "Custom" dans le menu. Remplissez les champs concernant l'URL de l'API (qui peut être locale), le nom du modèle et la température. En cas de problème, contactez notre support, nous sommes très réactifs !
                </p>
            </Segment>
            <Button primary floated="right" href="/guide/selectionner-histoire"><p>Continuer</p></Button>

        </Container>
    );
}
