import React, { useContext } from 'react';
import { db } from '../firebaseConfig';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, listAll, getDownloadURL, uploadBytes } from 'firebase/storage';
import ShortUniqueId from 'short-unique-id';
import '../Create/CreateListStories.css';
import { useTranslation } from 'react-i18next';
import { deleteFolder } from '../Utils/Utils';
import { StoryContext } from '../Context/StoryContext';

export default function StoryItem({ env, storyElement, fetchStoriesFunction, alreadyStarted, editActived, duplicateActived, deleteActived }) {
    const storyContext = useContext(StoryContext);
    const { t } = useTranslation();

    const navigate = useNavigate();

    const changeStory = async (id, alreadyStarted) => {
        if (env.setUuidOfCurrentStory) {
            if (!alreadyStarted) {
                env.setAlreadyStartedStories(false);
                env.setUuidOfCurrentStory(id);
            }
            else {
                await env.setAlreadyStartedStories(true);
                
                // Correctly use template literals with backticks for variable substitution
                const storyRef = doc(db, `users/${env.user.uid}/stories/${id}`);
                const docSnap = await getDoc(storyRef);

                if (docSnap.exists()) { // Check if the document exists
                    const storyData = docSnap.data();

                    // Assuming setInitialContactList and setMessagesList update your component's state
                    await storyContext.setContactList(storyData.contacts);
                    await storyContext.setMessagesList(storyData.messages);
                    await storyContext.setEventsList(storyData.events);
                    await storyContext.setParameters({...storyContext.parameters, start_date: storyData.currentTime.toDate()});
                    env.setUuidOfCurrentStory(storyData.id);
                } else {
                    console.log("No such document!");
                }
            }
        }
    };

    const handleStoryClick = (storyId) => {
        const path = `/Histoire/${storyId}`;
        navigate(path);
    };

    const handleEditClick = (storyId) => {
        const path = `/Create/Edit/${storyId}`;
        navigate(path);
    };

    // Function to duplicate a folder (including icons here)
    async function duplicateFolder(sourceFolder, destinationFolder) {
        const storage = getStorage();

        try {
            // Get the list of files in the source folder
            const sourceFolderRef = ref(storage, sourceFolder);
            const fileList = await listAll(sourceFolderRef);

            // Duplicate each file in the new folder
            await Promise.all(fileList.items.map(async (item) => {
                const downloadURL = await getDownloadURL(item);
                const response = await fetch(downloadURL);
                const blob = await response.blob();

                const destinationFileRef = ref(storage, `${destinationFolder}/${item.name}`);
                await uploadBytes(destinationFileRef, blob);
            }));

            console.log('Folder duplicated successfully!');
        } catch (error) {
            console.error('Error duplicating folder:', error);
        }
    }

    async function duplicateStory(storyId, newId) {
        try {
            // Get the record to duplicate
            const storyRef = doc(db, 'stories', storyId);
            const storySnap = await getDoc(storyRef);

            if (storySnap.exists()) {
                // Create a new record with the new ID and retrieved data
                const newStoryRef = doc(db, 'stories', newId);
                await setDoc(newStoryRef, { ...storySnap.data(), id: newId, creation_date: new Date() });

                await duplicateFolder(`contacts/icons/${storyId}`, `contacts/icons/${newId}`);

                console.log('Story duplicated successfully!');
                return newId;
            } else {
                console.log('The story to duplicate does not exist.');
                return null;
            }
        } catch (error) {
            console.error('Error duplicating story:', error);
            return null;
        }
    }

    const handleDuplicateClick = async (storyId) => {
        const newId = new ShortUniqueId({ length: 20 }).randomUUID();

        const result = await duplicateStory(storyId, newId);
        if (result !== null) {
            const path = `/Create/Edit/${newId}`;
            navigate(path);
        }
    };

    const handleDeleteClick = async (storyId, alreadyStarted) => {
        if (env.user) {
            if (!alreadyStarted) {
                const storyRef = doc(db, `stories/${storyId}`);
                const docSnap = await getDoc(storyRef);
                if (docSnap.exists() && storyId !== "") {
                    // Delete the folder from the storage
                    const storage = getStorage();
                    await deleteFolder(`contacts/icons/${storyId}`);
                    await deleteFolder(`musics/${storyId}`);
                    await deleteFolder(`thumbnails/${storyId}`);
                    await deleteFolder(`RessourcesComplexes/${storyId}`);

                    // Delete the document
                    await deleteDoc(storyRef);

                    // Refresh the list of stories
                    fetchStoriesFunction();
                } else {
                    console.log("No such document!");
                }
            }
            else {
                const storyRef = doc(db, `users/${env.user.uid}/stories/${storyId}`);
                const docSnap = await getDoc(storyRef);
                if (docSnap.exists()) {
                    await deleteDoc(storyRef);

                    // Refresh the list of stories
                    fetchStoriesFunction();
                } else {
                    console.log("No such document!");
                }
            }
        }
    };

    return (
        <Popup
            trigger={
                <div
                    key={storyElement.id}
                    className="story-item"
                    onClick={async () => {
                        handleStoryClick(storyElement.id);
                        await changeStory(alreadyStarted ? storyElement.idDoc : storyElement.id, alreadyStarted);
                    }}
                >
                    <img src={storyElement.parameters.thumbnail || 'imageUnavailable.png'} alt="Story Visual" style={{ width: 'auto', height: '100px' }} />
                    <div className="story-info">
                        <div className="story-name">{storyElement.parameters.name || "Unnamed Story"}</div>
                        <div className="story-description">{`${alreadyStarted ? t('SaveDate') : t('CreationDate')} ${storyElement.creation_date ? storyElement.creation_date.toDate().toLocaleDateString() : "No date"}`}</div>
                    </div>
                    <Button.Group>
                        <Popup
                            trigger={
                                <Button
                                    className="story-buttons"
                                    onClick={async (e) => {
                                        e.stopPropagation(); // Disables the redirection from the div zone
                                        handleStoryClick(storyElement.id);
                                        await changeStory(alreadyStarted ? storyElement.idDoc : storyElement.id, alreadyStarted);
                                    }}
                                    icon
                                    primary
                                    aria-label="Navigate to Story Mode"
                                >
                                    <Icon name='play'></Icon>
                                </Button>
                            }
                            content={t('Popup_Play')}
                            on='hover'
                            position='bottom center'
                        />
                        {editActived &&
                            <Popup
                                trigger={
                                    <Button
                                        className="story-buttons"
                                        onClick={(e) => {
                                            e.stopPropagation(); // Disables the redirection from the div zone
                                            handleEditClick(storyElement.id);
                                        }}
                                        icon
                                        primary
                                        aria-label="Navigate to Creation Mode"
                                    >
                                        <Icon name='pencil'></Icon>
                                    </Button>
                                }
                                content={t('Popup_Edit')}
                                on='hover'
                                position='bottom center'
                            />
                        }
                        {duplicateActived &&
                            <Popup
                                trigger={
                                    <Button
                                        className="story-buttons"
                                        onClick={(e) => {
                                            e.stopPropagation(); // Disables the redirection from the div zone
                                            handleDuplicateClick(storyElement.id);
                                        }}
                                        icon
                                        primary
                                        aria-label="Duplicate the story"
                                    >
                                        <Icon name='copy'></Icon>
                                    </Button>
                                }
                                content={t('Popup_Duplicate')}
                                on='hover'
                                position='bottom center'
                            />
                        }
                        {deleteActived &&
                            <Popup
                                trigger={
                                    <Button
                                        className="delete-story-button"
                                        onClick={(e) => {
                                            e.stopPropagation(); // Disables the redirection from the div zone
                                        }}
                                        icon
                                        color='brown'
                                        aria-label="Delete the story"
                                    >
                                        <Icon name='trash'></Icon>
                                    </Button>
                                }
                                content={
                                    <div>
                                        <div>{alreadyStarted ? t('Popup_TextConfirmDeleteAlreadyStarted') : t('Popup_TextConfirmDelete')}</div>
                                        <Button 
                                            className="delete-story-button"
                                            color='brown' 
                                            content={t('Pupup_ConfirmDelete')}
                                            onClick={async (e) => {
                                                e.stopPropagation(); // Disables the redirection from the div zone
                                                await handleDeleteClick(alreadyStarted ? storyElement.idDoc : storyElement.id, alreadyStarted);
                                            }}
                                        />
                                    </div>
                                }
                                on='click'
                                position='top right'
                            />
                        }
                    </Button.Group>
                </div>
            }
            content={storyElement.parameters.home_message  || t("No description")}
            on='hover'
            position='top center'
            wide
            mouseEnterDelay={300}
        />
    );
}
