import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import imageCompression from 'browser-image-compression';
import defaultIcon from '../../src/assets/default.png';

export function getIconPath(storyId, number) {
  return `contacts/icons/${storyId}/${number.replace("+", "")}`;
}

export async function uploadIcon(storyId, number, file) {
  if (!file) return;

  // Compress image using browser-image-compression
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 480,
    useWebWorker: true,
    initialQuality: 0.8, // Set initial quality to 80%
  };

  try {
    const compressedFile = await imageCompression(file, options);

    // Convert the compressed image to webp using canvas
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(compressedFile);

      img.onload = async () => {
        try {
          const canvas = document.createElement("canvas");
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0);
          const webpFile = canvas.toDataURL("image/webp", 0.8); // Set quality to 80%

          const storageRef = ref(getStorage(), getIconPath(storyId, number));

          // Convert the webp data URL to a Blob
          const response = await fetch(webpFile);
          const blob = await response.blob();

          // Upload the compressed and converted image
          await uploadBytes(storageRef, blob);

          const downloadURL = await getDownloadURL(storageRef);
          resolve(downloadURL);
        } catch (error) {
          reject(error);
        }
      };

      img.onerror = (error) => {
        reject(error);
      };
    });
  } catch (error) {
    console.error("Image compression or upload failed:", error);
  }
}

export async function fetchIconSource(storyId, number, setIconSource) {
    const iconPath = getIconPath(storyId, number);
    const storage = getStorage();
    const iconRef = ref(storage, iconPath);

    try {
        const url = await getDownloadURL(iconRef);
        setIconSource(url);
    } catch (error) {
        setIconSource(defaultIcon);
    }
}

export async function getIconSource(storyId, number, setIconSource) {
  const iconPath = getIconPath(storyId, number);
  const storage = getStorage();
  const iconRef = ref(storage, iconPath);

  try {
      const url = await getDownloadURL(iconRef);
      return url;
  } catch (error) {
      return defaultIcon;
  }
}

export function getDefaultIcon() {
   return defaultIcon; 
}