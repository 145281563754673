import React, { createContext, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

const EnvironmentContext = createContext();
// TODO Rename to AppContext

const EnvironmentProvider = ({ children }) => {
    const [playMusicBool, setPlayMusicBool] = useState(true)
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isItAnAdvancedUser, setIsItAnAdvancedUser] = useState(false);
    const [userTokenCount, setUserTokenCount] = useState(0);
    const [modelChosen, setModelChosen] = useState('mistral-small');
    const [apiKey, setApiKey] = useState('');
    const [user, setUser] = useState(null);
    const [alreadyStartedStories, setAlreadyStartedStories] = useState(false);
    const [uuidOfCurrentStory, setUuidOfCurrentStory] = useState('l4dVqXlfWapXnOzBNiJr')
    const location = useLocation();
    const createMode = location.pathname.startsWith('/Create');
    const audioRef = useRef(null);

    const value = {
        playMusicBool, setPlayMusicBool,
        audioRef,
        isFullScreen, setIsFullScreen,
        isItAnAdvancedUser, setIsItAnAdvancedUser,
        userTokenCount, setUserTokenCount,
        modelChosen, setModelChosen,
        apiKey, setApiKey,
        user, setUser,
        alreadyStartedStories, setAlreadyStartedStories,
        uuidOfCurrentStory, setUuidOfCurrentStory,
        createMode
    }

    return (
        <EnvironmentContext.Provider value={value}>
            {children}
        </EnvironmentContext.Provider>
    );
}

export { EnvironmentProvider, EnvironmentContext };