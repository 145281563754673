
import React, { useState, useEffect, useRef, useContext } from 'react';
import './Messaging.css';
import Message from './Message.js';
import { Button, Popup } from 'semantic-ui-react';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/fr';
import { useTranslation } from 'react-i18next';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { decrementTokenCount, interpolateTemplate, getDynamicTimeString } from '../Utils/Utils.js';
import { EnvironmentContext } from '../Context/EnvironmentContext.js';
import { CreateContext } from '../Context/CreateContext.js';
import { StoryContext } from '../Context/StoryContext.js';
import StartModal from '../StartModal/StartModal';
import { LlmEngine } from './LlmEngine';

const Messaging = ({setOpenGreetingMessage}) => {
  const { t } = useTranslation();
  const [newMessage, setNewMessage] = useState('');
  const [endOfGame, setEndOfGame] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [context, setContext] = useState([]);
  const [waitingCharacters, setWaitingCharacters] = useState([]);
  const scrollRef = useRef(null);
  const axiosClient = useRef(null);
  const llmEngine = useRef(null);
  const story = useContext(StoryContext);
  const env = useContext(EnvironmentContext);
  const create = useContext(CreateContext);

  useEffect(() => {
    if (env.modelChosen?.APIurl && env.apiKey) {
      // axiosClient.current = axios.create({
      //   baseURL: env.modelChosen.APIurl,
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'Accept': 'application/json',
      //     'Authorization': `Bearer ${env.apiKey}`
      //   }
      // });
      axiosClient.current = 1;

      llmEngine.current = new LlmEngine(axiosClient.current);
    }
  }, [env.modelChosen, env.apiKey]);

  useEffect(() => {
    llmEngine.current.regenerateContext(story); 
  }, [story.messagesList, story.selectedContact, story.promptSystemeChoisi]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [story.messagesList, story.selectedContact, story.contactList]);

  useEffect(() => {
    const handleFullScreenChange = () => setIsFullScreen(document.fullscreenElement);
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => document.removeEventListener("fullscreenchange", handleFullScreenChange);
  }, []);

  const sendMessageToGPT = async () => {
    setWaitingCharacters(prev => [...prev, story.selectedContactName]);

    const responseMessage = await llmEngine.current.sendMessage(newMessage, context, env.modelChosen, env.apiKey, env);

    if (responseMessage) {
      decrementTokenCount(env.user)
      addMessage(story.selectedContactName, "user", responseMessage);
    }

    setWaitingCharacters(current => current.filter(contact => contact !== story.selectedContactName));
    scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "start" });
  };

  const toggleFullScreen = async () => {
    if (!document.fullscreenElement) {
      try {
        await document.documentElement.requestFullscreen();
      } catch (e) {
        console.error(e);
      }
    } else {
      try {
        await document.exitFullscreen();
      } catch (e) {
        console.error(e);
      }
    }
  };

  const addMessage = (senders, receivers, messageText) => {
    const currentDate = moment(story.dynamicTime).format('YYYY-MM-DD');
    const currentTime = moment(story.dynamicTime).format('HH:mm:ss');
    const messageSenders = Array.isArray(senders) ? senders : [senders];
    const messageReceivers = Array.isArray(receivers) ? receivers : [receivers];

    const newMessage = {
      senders: messageSenders,
      receivers: messageReceivers,
      message: messageText,
      date: currentDate,
      heure: currentTime,
    };

    story.setMessagesList(prevMessages => [...prevMessages, newMessage]);
  };

  const sendMessage = async () => {
    if (!(env.user.uid && env.apiKey)) {
      story.setDoWeNeedToDisplayModal(true);
      return;
    }
    if (newMessage.trim() && !endOfGame) {
      addMessage("user", story.selectedContactName, newMessage);
      setNewMessage('');
      if (story.selectedContactRecord?.interactive) {
        await sendMessageToGPT();
      }
    } else if (newMessage.trim() && endOfGame) {
      const currentDate = moment().format('ddd[, ]HH:mm');
      story.messagesList.push({ senders: 'user', receivers: story.selectedContactName, message: newMessage, date: currentDate, gameEnded: true });
    }
    verifyEndOfStory();
  };

  const verifyEventByMessages = (event) => {
    let nbMessages = 0;
    story.messagesList.forEach(message => {
      for (let character of event.nbMessageCharacters) {
        const characterName = story.contactList.find(contact => contact.number === character)?.name; // TODO: change to number
        if (message.senders.includes(characterName) || message.receivers.includes(characterName)) {
            nbMessages++;
            break;
        }
      }
    });
    if (nbMessages >= event.nbMessages) {
      event.startDate = story.dynamicTime.toISOString().slice(0, 10);
      event.startTime = story.dynamicTime.toLocaleTimeString();
    }
  };

  const verifyEndOfStory = () => {
    console.log("Verify end of story", story.eventsList);
    const eventEnd = story.eventsList.find(event => 
      event.consequence.type === 'endStory' &&
      event.consequence.activate &&
      story.dynamicTime >= new Date(`${event.startDate}T${event.startTime}`)
    );

    if (eventEnd) {
      //setEndOfGame(true);
      console.log("End of game");
      story.setEndStoryMessageIdDisplayed(eventEnd.id);
    }
  };
    
  const handleSaveStory = async () => {
    console.log("Saving story", story);
    const toSave = {
      contacts: story.contactList,
      messages: story.messagesList,
      events: story.eventsList,
      id: story.id,
      creation_date: new Date(),
      currentTime: story.dynamicTime,
      parameters: 
        {
          name: story.parameters.name,
          thumbnail: story.parameters.thumbnail,
        },
    };
    await addDoc(collection(db, `users/${env.user.uid}/stories`), toSave);
  };

  const handleKeyPress = async (e) => {
    if (e.keyCode === 13) {
      await sendMessage();
    }
  };

  const handleInputChange = (e) => {
    setNewMessage(e.target.value);
  };

  const toggleMusic = () => {
    if(!story.parameters.music){
      return
    }
    if(env.audioRef == null){
      console.error("pas d'audio")
      return
    }
    if (env.audioRef.current != null) {
      if (env.audioRef.current.paused) {
        if (!env.createMode) {
          env.audioRef.current.play();
        }
      } else {
        env.audioRef.current.pause()
      }
    }
    if (env.createMode) {
      env.audioRef.current.pause();
    }
  }

  return (
    <div className="messaging">
      <div className="messaging-title">
        <div>{t('ConversationWith') + ((story.selectedContactName === "undefined" || story.selectedContactName === "user") ? "" : story.selectedContactName)}</div>
        {!env.createMode && (
          <div className="options">
            <Popup
              trigger={
                <Button
                  icon="info"
                  aria-label="Story Information"
                  className="fullscreen-button"
                  onClick={() => setOpenGreetingMessage(true)}
                  disabled={env.createMode}
                />
              }
              content={t('PopUp_StoryInformation')}
              position='top center'
            />
            <Popup
              trigger={
                <Button
                  icon="save"
                  aria-label="Save Story"
                  className="save-button"
                  onClick={handleSaveStory}
                  disabled={env.createMode}
                />
              }
              content={t('PopUp_SaveStory')}
              position='top center'
            />
            <Popup
              trigger={
                <Button
                  icon={"volume " + (env.audioRef.current?.paused ? "off" : "up")}
                  aria-label="Music"
                  className="fullscreen-button"
                  onClick={toggleMusic}
                  disabled={env.createMode || !story.parameters.music}
                />
              }
              content={env.audioRef.current?.paused ? t('PopUp_Music_Off') : t('PopUp_Music_On')}
              position='top center'
            />
            <Popup
              trigger={
                <Button
                  icon={isFullScreen ? "compress" : "expand"}
                  aria-label="Toggle Fullscreen"
                  className="fullscreen-button"
                  onClick={toggleFullScreen}
                  disabled={env.createMode}
                />
              }
              content={isFullScreen ? t('PopUp_FullScreen_Off') : t('PopUp_FullScreen_On')}
              position='top center'
            />
          </div>
        )}
      </div>
      <div className="messages-container">
        <div className="messages-historic">
          <div className="messages-inner-container">
            {story.messagesList
              .filter(message =>
                (message.senders.includes(story.selectedContactName) && message.receivers.includes('user')) ||
                (message.senders.includes('user') && message.receivers.includes(story.selectedContactName))
              )
              .map((message, index) => {
                return <Message
                  key={index}
                  receivers={message.receivers}
                  senders={message.senders}
                  storyId={story.id}
                  message={message.message}
                  messageId={message.id}
                  erreur={message?.gameEnded}
                  date={message.date}
                  time={message.heure}
                  author={message.senders[0]}
                />
              }
              )}
              {story.messagesList.length === 0 && env.createMode && (
              <div className="empty-message-firstCreate">
                {story.contactList.length === 1 && (
                  t('FirstCreateContact')
                )}
                {story.contactList.length === 1 ? '' : (
                  t('FirstCreateMessage')
                )}
              </div>
            )}
            {waitingCharacters.includes(story.selectedContactName) && (
              <div className="loading-dots">
                <span></span>
                <span></span>
                <span></span>
              </div>
            )}
            <div ref={scrollRef} className="scroll-offset"></div>
          </div>
        </div>
        {!env.createMode && (
          <div className="send-box">
            <input
              className="message-input"
              type="text"
              placeholder={t('SendMessage')}
              onKeyDown={handleKeyPress}
              value={newMessage}
              onChange={handleInputChange}
            />
            <Button className="ui icon blue button" aria-label="Send Button" onClick={sendMessage} color='blue'>
              <i className="send icon"></i>
            </Button>
          </div>
        )}
      </div>
      <div className='btn-createMessage-CreateMode'>
      {(env.createMode && (story.contactList.length > 1)) &&
        <Button
          icon='add'
          className='btn-createMessage-CreateMode'
          color='blue'
          onClick={(e) => create.setOpenPopUpAddNewMessage_CreationMode(true)}
        />
      }
      </div>
    </div>
  );
};

export default Messaging;