import React, { useContext } from 'react';
import { Button, Modal } from 'semantic-ui-react';
import "./EndModal.css"
import { useTranslation } from 'react-i18next';
import { StoryContext } from '../Context/StoryContext';



const StartModal = ({}) => {
  const { t } = useTranslation();
  const story = useContext(StoryContext);

  const handleClose = () => {
    story.setEventsList(story.eventsList.map(event => {
      if (event.id === story.endStoryMessageIdDisplayed) {
        event.consequence.activate = false;
      }
      return event;
    }))
    story.setEndStoryMessageIdDisplayed("");
  }

  const goBack = () => {
    story.setEndStoryMessageIdDisplayed("");
    window.history.go(-1); // May be replaced by a redirection to the story list
  }
  
  return (
    <Modal open={story.endStoryMessageIdDisplayed !== ""} size="tiny">
      <Modal.Content>
        <div className="description">
          {story.eventsList.find(event => event.id === story.endStoryMessageIdDisplayed).consequence.message}
        </div>
        <div className="explanation">
          {t('ExplanationContinueAfterEnd')}
        </div>
      </Modal.Content>
      <Modal.Actions>
          <Button
            negative
            onClick={goBack}
          >
            {t('LeaveStory')}
          </Button>
          <Button 
            style={{ backgroundColor: '#004F78', color: '#fff' }} 
            onClick={handleClose}
          >
            {t('ContinueAfterEnd')}
          </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default StartModal;