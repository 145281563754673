import React, { useContext, useEffect, useState } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import './ContactItem.css';
import { Button, Segment } from 'semantic-ui-react';
import { fetchIconSource, getDefaultIcon } from '../Utils/ContactIcons'; // todo change path of this
import { useTranslation } from 'react-i18next';
import { StoryContext } from '../Context/StoryContext.js';
import { EnvironmentContext } from '../Context/EnvironmentContext.js';

const ContactItem = ({number, name, setShowContact, onEditContact, onDeleteContact }) => {
    const { t } = useTranslation();
    const story = useContext(StoryContext);
    const env = useContext(EnvironmentContext);
    const [iconSource, setIconSource] = useState(getDefaultIcon());
    const [isSelected, setIsSelected] = useState(false);
    const backgroundColor = isSelected ? "#5f99f6" : ""; // Highlight color for selected contact

    useEffect(() => {
        if (number) {
            fetchIconSource(story.id, number, setIconSource);
        }
    }, [number, story.totalContactList, story.contactList, story.contactListWithoutMainCharacter]);

    useEffect(() => {
        setIsSelected(story.selectedContact === number)
    }, [story.selectedContact])

    return (
        <div className={env.createMode ? "contact-item-createMode" : "contact-item"} style={{ backgroundColor }} onClick={() => {
                if(story.mainCharacter.number != number){
                    story.setSelectedContact(number); 
                    setShowContact(false);
                }
        }}>
            <img src={iconSource} className="contact-image" alt="contact" />
            <div className='contact-information'>
                <div className="contact-name">
                    {story.mainCharacter.number === number ? name + t("Player") : name}
                </div>
                {env.createMode &&
                    <Button.Group style={{ marginTop: "10px" }}>
                        <Button
                            icon='pencil'
                            color='green'
                            onClick={() => onEditContact(number)}
                            className='contact-buttons'
                        />
                        {(number !== story.mainCharacter.number) && <Button
                            icon='trash'
                            color='red'
                            className="delete-contact-button"
                            onClick={(event) => {
                                event.stopPropagation();
                                onDeleteContact(name);
                            }}
                        />}
                    </Button.Group>
                }
            </div>
        </div>
    );
}

export default ContactItem;
