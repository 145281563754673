import React, { createContext, useState } from 'react';

const CreateContext = createContext();

const CreateProvider = ({ children }) => {
    const [editContact, setEditContact] = useState(null);
    const [editMessage, setEditMessage] = useState(null);
    const [editEvent, setEditEvent] = useState(null); // Contains the event id if it is in edit mode
    const [duplicateEvent, setDuplicateEvent] = useState(null); // Contains the event id if it is in duplicate mode
    const [isNewStory, setIsNewStory] = useState(false);
    const [openPopUpAddNewContact_CreationMode, setOpenPopUpAddNewContact_CreationMode] = useState(false);
    const [openPopUpAddNewMessage_CreationMode, setOpenPopUpAddNewMessage_CreationMode] = useState(false);
    const [openPopUpAddNewEvent_CreationMode, setOpenPopUpAddNewEvent_CreationMode] = useState(false);
    const [firstSelectedContact, setFirstSelectedContact] = useState(null);

    const value = {
        editContact, setEditContact,
        editMessage, setEditMessage,
        editEvent, setEditEvent,
        duplicateEvent, setDuplicateEvent,
        isNewStory, setIsNewStory,
        openPopUpAddNewContact_CreationMode, setOpenPopUpAddNewContact_CreationMode,
        openPopUpAddNewMessage_CreationMode, setOpenPopUpAddNewMessage_CreationMode,
        openPopUpAddNewEvent_CreationMode, setOpenPopUpAddNewEvent_CreationMode,
        firstSelectedContact, setFirstSelectedContact
    }

    return (
        <CreateContext.Provider value={value}>
            {children}
        </CreateContext.Provider>
    );
}

export { CreateProvider, CreateContext };