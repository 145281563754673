import React, { useState, useEffect, useContext, useCallback, useRef } from 'react';
import { Button, Grid, Loader, Message } from 'semantic-ui-react';
import { collection, getDocs, doc, query, where, setDoc, Timestamp } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';
import CreateEvent from './CreateEvent';
import ShortUniqueId from 'short-unique-id';

import { db } from '../firebaseConfig';
import { useTranslation } from 'react-i18next';
import Story from '../Stories/Story';
import '../App.css';
import CreateGeneralParameters from './CreateGeneralParameters';
import CreateNewContactPopUp_CreationMode from './CreateNewContactPopUp_CreationMode';
import CreateNewMessagePopUp_CreationMode from './CreateNewMessagePopUp_CreationMode';
import { UploadMusic, captureAndUploadThumbnail, deleteFolder } from '../Utils/Utils';
import { StoryContext } from '../Context/StoryContext';
import { CreateContext } from '../Context/CreateContext';
import EventsContainer from '../Events/EventsContainer';
import { EnvironmentContext } from '../Context/EnvironmentContext';

const Create = () => {
    const storyContext = useContext(StoryContext);
    const createContext = useContext(CreateContext);
    const env = useContext(EnvironmentContext);
    const { t } = useTranslation();
    const uid = new ShortUniqueId({ length: 20 });
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const [stories, setStories] = useState([]);
    const [hasInvalidGeneralParameter, setHasInvalidGeneralParameter] = useState(false);

    const { storyId: inputStoryId } = useParams();
    const [storyId, setStoryId] = useState(inputStoryId);

    // Ref to store the previous scroll position
    const scrollPositionRef = useRef(0);

    useEffect(() => {
        if (inputStoryId === 'new' && !createContext.isNewStory) {
            createContext.setIsNewStory(true);
            const newStoryId = uid.randomUUID();
            setStoryId(newStoryId);
        }
        // Functional but not ideal, should be handled in a more appropriate way in a more clear context
        else if (!createContext.isNewStory && !createContext.firstSelectedContact) {
            createContext.setFirstSelectedContact(storyContext.selectedContact);
        }
            
    }, [inputStoryId, createContext.isNewStory, uid]);

    const fetchUserStories = useCallback(async () => {
        const q = query(collection(db, "stories"), where("owner", "==", env.user.uid));
        const querySnapshot = await getDocs(q);
        const fetchedStories = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...JSON.parse(doc.data().generalParameters || '{}')
        }));
        setStories(fetchedStories);
    }, [env.user.uid]);

    // Warn before leaving the page during upload
    const handleBeforeUnload = (e) => {
        if (loading) {
            e.preventDefault();
            e.returnValue = '';
        }
    };

    const saveStory = async (manualSave = false) => {
        if (!env.user?.uid) {
            console.error("User not authenticated");
            setError('User not authenticated.');
            return;
        }

        if (manualSave) {
            setLoading(true); // Begin loading only for manual save
            setError(''); // Reset any previous errors
            setSuccess(false); // Reset success state
        }

        // Attempt to capture and upload a thumbnail if one does not already exist
        if (!storyContext.parameters.thumbnail) {
            storyContext.parameters.thumbnail = await captureAndUploadThumbnail(storyId);
        }

        // Check if the music parameter is a file and not an URL
        if (storyContext.parameters.music && !(typeof storyContext.parameters.music === 'string')) {
            await deleteFolder(`musics/${storyId}`);
            storyContext.parameters.music = await UploadMusic(storyId, storyContext.parameters.music);
        }

        const storyData = {
            id: storyId,
            owner: env.user.uid,
            parameters: storyContext.parameters,
            messages: storyContext.messagesList,
            events: storyContext.eventsList,
            contacts: storyContext.contactList,
            selectedContact: storyContext.selectedContact, // TODO Handle selectedContact in a more appropriate way
            creation_date: storyContext.creationDate || Timestamp.now(),
        };

        // Save the story in Firestore
        try {
            await setDoc(doc(db, "stories", storyId), storyData);
            console.log("StoryJson is now written with ID: ", storyId);

            if (manualSave) {
                setStories(prevStories => [...prevStories, storyData]);
                setSuccess(true); // Indicate success after upload completes
            }
        } catch (e) {
            console.error("Error adding or updating document: ", e);
            if (manualSave) setError('Failed to upload the story.'); // Set error message for manual save
        }

        if (manualSave) {
            setLoading(false); // End loading regardless of outcome
        }
    };

    useEffect(() => {
        if (!env.user?.uid) {
            return;
        }
        fetchUserStories();
    }, [env.user?.uid, fetchUserStories]);

    useEffect(() => {
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [loading]);

    // Save scroll position before re-render and restore it after
    useEffect(() => {
        const handleScrollPosition = () => {
            scrollPositionRef.current = window.scrollY;
        };

        window.addEventListener('scroll', handleScrollPosition);

        return () => {
            window.removeEventListener('scroll', handleScrollPosition);
        };
    }, []);

    useEffect(() => {
        window.scrollTo(0, scrollPositionRef.current);
    });

    return (
        <div style={{ marginLeft: "30px", marginRight: "30px" }}>
            <div style={{ background: '#00201f', color: '#ffffff', padding: '20px 0' }}>
                <Grid columns={2} stackable>
                    <Grid.Column width={5}>
                        <CreateGeneralParameters isItAnAdvancedUser={env.isItAnAdvancedUser} setHasInvalidGeneralParameter={setHasInvalidGeneralParameter} />
                    </Grid.Column>
                    <Grid.Column width={11}>
                        <Grid row={2}>
                            <Grid.Row height={10}>
                                <Story newStoryId={storyId} />
                            </Grid.Row>
                            <Grid.Row height={1}>
                                <EventsContainer 
                                    disposition={window.innerWidth < 900 ? 'vertical' : 'horizontal'}
                                    receiversList={storyContext.contactList.map(contact => contact.number)}
                                />
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid>
                {createContext.openPopUpAddNewContact_CreationMode && (
                    <CreateNewContactPopUp_CreationMode />
                )}
                {createContext.openPopUpAddNewMessage_CreationMode && (
                    <CreateNewMessagePopUp_CreationMode />
                )}
                {createContext.openPopUpAddNewEvent_CreationMode && (
                    <CreateEvent />
                )}
            </div>
            {loading && <Loader active inline='centered' style={{ color: "white" }}>Uploading your story...</Loader>}
            {success && <Message success header='Success!' content='Your story has been added.' />}
            {error && <Message error header='Error' content={error} />}
            <Button onClick={() => saveStory(true)} primary loading={loading} disabled={loading || hasInvalidGeneralParameter} style={{ margin: "0 auto", display: "block" }} >
                {loading ? 'Uploading...' : t('AddToDatabase')}
            </Button>
        </div>
    );
};

export default Create;
