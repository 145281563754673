import { db } from '../firebaseConfig';
import { doc, setDoc,getFirestore,increment, getDoc, FieldValue, updateDoc } from "firebase/firestore";
import { getStorage, uploadBytes, getDownloadURL, ref, listAll, deleteObject } from "firebase/storage";
import { ref as firebaseRef } from "firebase/storage";
import html2canvas from 'html2canvas';
import imageCompression from 'browser-image-compression';
import { Buffer } from 'buffer';
import { Popup, Icon } from 'semantic-ui-react';
import { useContext } from 'react';
import { StoryContext } from '../Context/StoryContext';
import { storage } from '../firebaseConfig';

const updateUserTokenCount = async (userId, tokenCount) => {
    try {
        const tokenDocRef = doc(db, 'users', userId);

        // Set the token count in the user's document
        // If the document does not exist, it will be created
        await setDoc(tokenDocRef, { tokenCount }, { merge: true });

        console.log("Document written or updated");
        return true; // Success indicator
    } catch (error) {
        console.error('Error updating token count:', error);
        return false; // Failure indicator
    }
};
function interpolateTemplate(templateString, values) {
    return templateString.replace(
        /\$\[(?<arrayKey>[^\]]+?)(?:,\s*"(?<mapParam>(?:(?:\$(?<conditionDelimiterInArray>\?+)\[(?:[^\],]+),\s*"(?:[^"]+)",\s*"(?:(?:[^\?]|\k<conditionDelimiterInArray>\?+\[|\]\k<conditionDelimiterInArray>\?+)+?)",\s*"(?:(?:[^\?]|\k<conditionDelimiterInArray>\?+\[|\]\k<conditionDelimiterInArray>\?+)+?)"\]\k<conditionDelimiterInArray>(?!\?))|[^"])+?)",\s*"(?<joinParam>[^"]+)")\]|\$(?<conditionDelimiter>\?+)\[(?<conditionKey>[^\],]+),\s*"(?<testParam>[^"]+)",\s*"(?<trueParam>(?:[^\?]|\k<conditionDelimiter>\?+\[|\]\k<conditionDelimiter>\?+)+?)",\s*"(?<falseParam>(?:[^\?]|\k<conditionDelimiter>\?+\[|\]\k<conditionDelimiter>\?+)+?)"\]\k<conditionDelimiter>(?!\?)|\$\{(?<key>[^},]+)?\}/g,
        (match, arrayKey, mapParam, conditionDelimiterInArray, joinParam, conditionDelimiter, conditionKey, testParam, trueParam, falseParam, key) => {
            //If the captured item is composed, such as acquaintances, a mapping and joining treatment
            // is effected. This complexe procedure was selected to avoid the eval usage, too dangerous
            if (conditionKey) {
                const trimmedKey = conditionKey.trim();
                if (values.hasOwnProperty(trimmedKey)) {
                    return values[trimmedKey] === testParam ? interpolateTemplate(trueParam, values) : interpolateTemplate(falseParam, values);
                }
                else {
                    return '';
                }
            }
            //If the captured item is composed, such as acquaintances, a mapping and joining treatment
            // is effected. This complexe procedure was selected to avoid the eval usage, too dangerous
            else if (arrayKey) {
                const trimmedKey = arrayKey.trim();
                if (values.hasOwnProperty(trimmedKey)) {
                    return values[trimmedKey].map(item => interpolateTemplate(mapParam, item)).join(joinParam);
                }
                else {
                    return '';
                }
            }
            else if (key) {
                const trimmedKey = key.trim();
                if (values.hasOwnProperty(trimmedKey)) {
                    return values[trimmedKey];
                }
                else {
                    console.log(key, "Key not found");
                    return '';
                }
            }
            else { return ''; }
    });
}

const captureAndUploadThumbnail = async (storyId) => {
    if (!document.getElementsByClassName('app')[0]) return null;

    const canvas = await html2canvas(document.getElementsByClassName('app')[0]);
    const blob = await new Promise(resolve => canvas.toBlob(resolve));
    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 480,
        useWebWorker: true,
        initialQuality: 0.8, // Set initial quality to 80%
        convertSize: 500000, // Set maximum converted file size to 500 KB
        fileType: 'image/webp', // Convert to webp format
    };
    const compressedFile = await imageCompression(blob, options);
    const blobCompressed = new Blob([compressedFile], { type: 'image/webp' });

    const screenshotPath = `thumbnails/${storyId}/screenshot.png`;
    const fileRef = firebaseRef(getStorage(), screenshotPath);
    await uploadBytes(fileRef, blobCompressed);
    return getDownloadURL(fileRef);
};
const convertToWebP = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);
                canvas.toBlob(
                    (blob) => {
                        const webpFile = new File([blob], 'thumbnail.webp', {
                            type: 'image/webp',
                        });
                        resolve(webpFile);
                    },
                    'image/webp',
                    0.8 // Adjust the WebP quality as needed (0 to 1)
                );
            };
            img.src = reader.result;
        };
        reader.readAsDataURL(file);
    });
};
const UploadThumbnail = async (storyId, thumbnailFile) => {
    if (!thumbnailFile) return null;
    const storage = getStorage();
    const oldScreenShotRef = ref(storage, `thumbnails/${storyId}/screenshot.png`);

    // Check if the file exists
    const fileExists = await getDownloadURL(oldScreenShotRef)
        .then(() => true)
        .catch(() => false);

    if (fileExists) {
        // Delete the file
        await deleteObject(oldScreenShotRef);
    }

    // Compress the image
    const options = {
        maxSizeMB: 0.5, // Adjust the maximum file size as needed (in MB)
        maxWidthOrHeight: 480, // Adjust the maximum width or height as needed
        useWebWorker: true,
    };

    try {
        const compressedFile = await imageCompression(thumbnailFile, options);

        // Convert the compressed file to WebP format
        const webpFile = await convertToWebP(compressedFile);

        const thumbnailPath = `thumbnails/${storyId}/screenshot.webp`;
        const fileRef = ref(getStorage(), thumbnailPath);
        await uploadBytes(fileRef, webpFile);
        return getDownloadURL(fileRef);
    } catch (error) {
        console.error('Error compressing or converting the image:', error);
        // Handle the error as needed
    }
};
async function deleteFolder(folderPath) {
    const storage = getStorage();
    const folderRef = ref(storage, folderPath);
    const files = await listAll(folderRef);

    files.items.forEach(async (file) => {
        await deleteObject(file);
    });
}
const UploadMusic = async (storyId, musicFile) => {
    if (!musicFile) return null;
    const musicPath = `musics/${storyId}/MainMusic.mp3`; // or the appropriate file type
    const fileRef = ref(getStorage(), musicPath);
    await uploadBytes(fileRef, musicFile);
    return getDownloadURL(fileRef);
};
const decrementTokenCount = async (userId) => {
    try {
      const tokenDocRef = doc(db, 'users', userId);
      const tokenDoc = await getDoc(tokenDocRef);
      if (tokenDoc.exists()) {
        const tokenCount = tokenDoc.data().tokenCount;
        if (tokenCount > 0) {
          await updateDoc(tokenDocRef, { tokenCount: tokenCount - 1 });
          return true; // Return true on successful decrement
        } else {
          console.error('Token count is already 0');
          return false; // Return false if token count is already 0
        }
      } else {
        console.error('User document not found');
        return false; // Return false if user document not found
      }
    } catch (error) {
      console.error('Error decrementing token count:', error);
      return false; // Return false on error
    }
  };

async function checkUserRegistration(uid) {
    const userRef = doc(db, 'users', uid);
    const docSnap = await getDoc(userRef);
    return docSnap.exists(); // Use exists() as a method
}
export function isContactMainCharacter(contact) {
    if (!contact) {
        return false;
    }
    return parseInt(contact.number) === 0
}
function QuestionPopup({message}) {
    return (
        <Popup
            trigger={
            <Icon name='question circle' color='grey' style={{ marginLeft: '5px' }}/>
            }
            content={message}
            on={['hover', 'click']}
            wide
            hoverable
        />
    )
}
const handleRessourcesComplexes = async (e, story) => {
    if (!e || !e.target || !e.target.files) {
        return;
    }

    for (const file of e.target.files) {
        const pathRef = ref(storage, `RessourcesComplexes/${story.id}/${story.parameters.ressources_complexes.length + 1}`);
        await uploadBytes(pathRef, file);
        const downloadURL = await getDownloadURL(pathRef);
        
        const updatedRessourcesComplexes = [...story.parameters.ressources_complexes, downloadURL];
        story.setParameters({ ...story.parameters, ressources_complexes: updatedRessourcesComplexes });
    }
}

export function getDynamicTimeString(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hour = String(date.getHours()).padStart(2, '0');
    const minute = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    const dateString = `${day}/${month}/${year} - ${hour}:${minute}:${seconds}`;
    return dateString;
}

// timeString can be either in format "4:34:56 PM" or "16:34:56". It will work in both cases.
export function convertSplittedDateToDate(date, timeString) {
    const parts = timeString.split(/[:, ]+/);
    const hours = parseInt(parts[0]);
    const minutes = parseInt(parts[1]);
    const seconds = parseInt(parts[2]);
    const isPM = parts[3] === "PM";

    const timeDate = new Date(date);
    timeDate.setHours(isPM ? hours + 12 : hours, minutes, seconds, 0);

    return timeDate;
}

export {handleRessourcesComplexes ,UploadThumbnail, UploadMusic, deleteFolder, interpolateTemplate, updateUserTokenCount, checkUserRegistration, decrementTokenCount, captureAndUploadThumbnail, QuestionPopup };
