import React from 'react';
import { Container, Header, Segment, List, Icon } from 'semantic-ui-react';
import classes from "./FootersPages.module.css";

export default function DocumentationTexteLinks() {
    const steps = [
        { title: "Étape 1 : Créer un compte", path: "/guide/creer-compte", description: "Inscrivez-vous pour commencer à utiliser Biffe Corps." },
        { title: "Étape 2 : Choisir votre modèle IA", path: "/guide/choisir-modele", description: "Trouvez le modèle d'IA adapté à vos besoins." },
        { title: "Étape 3a : Sélectionner une histoire", path: "/guide/selectionner-histoire", description: "Choisissez une histoire pour vivre une aventure unique." },
        { title: "Étape 3b : Créer votre propre histoire", path: "/guide/creer-histoire", description: "Créez une histoire personnalisée selon vos préférences." },
        { title: "Étape 4 : Devenez un expert !", path: "/guide/creation-parametres-avancés", description: "Découvrez encore plus de possibilités pour créer des histoires encore plus interractives et maitrîsées." }
    ];

    return (
        <Container className={classes.documentationLinks} style={{ padding: '3em 0em' }}>
            <Segment basic textAlign="center">
                <Header as="h1" style={{ fontSize: '2.5em', color: '#2a2a2a' }}>Guide de Démarrage avec Biffe Corps</Header>
                <p style={{ fontSize: '1.33em', color: '#5a5a5a', marginBottom: '2em' }}>
                    Cliquez sur une étape pour accéder à des instructions détaillées et des illustrations.
                </p>
            </Segment>

            <Segment basic>
                <List divided relaxed>
                    {steps.map((step, index) => (
                        <List.Item href={step.path} key={index} className={classes.stepItem}>
                            <Icon name="arrow right" size="large" color="blue" />
                            <List.Content>
                                <List.Header as="a"  style={{ fontSize: '1.5em', color: '#0056b3' }}>
                                    {step.title}
                                </List.Header>
                                <List.Description style={{ color: '#5a5a5a' }}>{step.description}</List.Description>
                            </List.Content>
                        </List.Item>
                    ))}
                </List>
            </Segment>
        </Container>
    );
}
