import React, { useContext, useState, useEffect } from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';

import '../Events/EventItem.css';
import { useTranslation } from 'react-i18next';
import { StoryContext } from '../Context/StoryContext';
import { getIconSource } from '../Utils/ContactIcons'; // todo change path of this

export default function EventItem({ eventElement, handleDeleteEvent, handleDuplicateEvent, handleEditEvent }) {
    const { t } = useTranslation();
    const storyContext = useContext(StoryContext);
    const [iconSourcesReceivers, setIconSourcesReceivers] = useState({});
    const [iconSourcesNbMessages, setIconSourcesNbMessages] = useState([]);

    async function loadIconsReceivers() {
        const iconSources = await Promise.all(
            eventElement.consequence.receivers.map(async (receiverNumber) => {
                const iconSource = await getIconSource(storyContext.id, receiverNumber);
                return { receiverNumber, iconSource };
            })
        );

        const newIconSources = iconSources.reduce((acc, { receiverNumber, iconSource }) => {
            acc[receiverNumber] = iconSource;
            return acc;
        }, {});

        setIconSourcesReceivers(newIconSources);
    }

    async function loadIconsNbMessages() {
        const sourcesNbMessages = await Promise.all(
            eventElement.nbMessageCharacters.map(async (receiverNumber) => {
                const iconSource = await getIconSource(storyContext.id, receiverNumber);
                return { receiverNumber, iconSource };
            })
        );

        const newIconSources = sourcesNbMessages.reduce((acc, { receiverNumber, iconSource }) => {
            acc[receiverNumber] = iconSource;
            return acc;
        }, {});

        setIconSourcesNbMessages(newIconSources);
    }

    useEffect(() => {
        if (eventElement.consequence.type === 'information') {
            loadIconsReceivers();
        }
    }, [eventElement.consequence.receivers]);

    useEffect(() => {
        if (eventElement.trigger === 'nbMessages') {
            loadIconsNbMessages();
        }
    }, [eventElement.nbMessageCharacters, eventElement.trigger]);

    return (
        <Popup
            trigger={
                <div className="event-item">
                    <div className="event-item-text">
                        {
                            (eventElement.consequence.type === 'information') ?
                                eventElement.consequence.description
                            : ((eventElement.consequence.type === 'endStory') ?
                                eventElement.consequence.message
                            : '')
                        }
                    </div>
                    <Button.Group>
                        <Popup
                            trigger={
                                <Button
                                    className="event-buttons"
                                    onClick={async (e) => {
                                        e.stopPropagation(); // Disables the redirection from the div zone
                                        handleEditEvent(eventElement.id);
                                    }}
                                    icon
                                    primary
                                    aria-label="Modify this event"
                                >
                                    <Icon name='pencil'></Icon>
                                </Button>
                            }
                            content={t('Popup_Edit')}
                            on='hover'
                        />
                        <Popup
                            trigger={
                                <Button
                                    className="event-buttons"
                                    onClick={async (e) => {
                                        e.stopPropagation(); // Disables the redirection from the div zone
                                        handleDuplicateEvent(eventElement.id);
                                    }}
                                    icon
                                    primary
                                    aria-label="Duplicate this event"
                                >
                                    <Icon name='copy'></Icon>
                                </Button>
                            }
                            content={t('Popup_Duplicate')}
                            on='hover'
                        />
                        <Popup
                            trigger={
                                <Button
                                    className="delete-event-button"
                                    onClick={async (e) => {
                                        e.stopPropagation(); // Disables the redirection from the div zone
                                    }}
                                    icon
                                    color='brown'
                                    aria-label="Delete this event"
                                >
                                    <Icon name='trash'></Icon>
                                </Button>
                            }
                            content={
                                <div>
                                    <div>{t('Popup_TextConfirmDeleteEvent')}</div>
                                    <Button
                                        className="delete-event-button"
                                        color='brown'
                                        content={t('Pupup_ConfirmDelete')}
                                        onClick={async (e) => {
                                            e.stopPropagation(); // Disables the redirection from the div zone
                                            await handleDeleteEvent(eventElement.id);
                                        }}
                                    />
                                </div>
                            }
                            on='click'
                            position='top right'
                        />
                    </Button.Group>
                </div>
            }
            content={
                <div>
                    {eventElement.consequence.type === 'information' && 
                        <>
                            <div>{`${t('PopupEventItem_Receivers')}: `}</div>
                            {
                                eventElement.consequence.receivers.map((receiverNumber) => {
                                    const contactReceiverInfo = storyContext.contactList.find(contact => contact.number === receiverNumber);
                                    return <Popup
                                        key={receiverNumber}
                                        trigger={<img src={iconSourcesReceivers[receiverNumber]} className="event-contact-image" alt={contactReceiverInfo.name} />}
                                        content={contactReceiverInfo.name}
                                        on='hover'
                                        position='top center'
                                    />
                                })
                            }
                        </>
                    }
                    {(eventElement.trigger === 'time') &&
                        <div>{`${t('PopupEventItem_Date')}: ${eventElement.startDate} ${t('at')} ${eventElement.startTime}`}</div>
                    }
                    {(eventElement.consequence.type === 'information') &&
                        <div>{`${t('PopupEventItem_Duration')}: ${eventElement.consequence.durationDays} ${t('days')}, ${eventElement.consequence.durationHours} ${t('hours')} ${t('and')} ${eventElement.consequence.durationMinutes} ${t('minutes')}`}</div>
                    }
                    {(eventElement.trigger === 'nbMessages') &&
                        <>
                            <div>{`${t('PopupEventItem_NbMessages')}: ${eventElement.nbMessages}`}</div>
                            <div>{`${t('PopupEventItem_NbMessagesCharacters')}: `}</div>
                            {
                                eventElement.nbMessageCharacters.map((receiverNumber) => {
                                    const contactNbMessagesInfo = storyContext.contactList.find(contact => contact.number === receiverNumber);
                                    return <Popup
                                        key={receiverNumber}
                                        trigger={<img src={iconSourcesNbMessages[receiverNumber]} className="event-contact-image" alt={contactNbMessagesInfo.name} />}
                                        content={contactNbMessagesInfo.name}
                                        on='hover'
                                        position='top center'
                                    />
                                })
                            }
                        </>
                    }
                    {(eventElement.consequence.type === 'information') &&
                        <>
                            <div>{`${t('PopupEventItem_Description')}: `}</div>
                            <div>{eventElement.consequence.description}</div>
                        </>
                    }
                    {(eventElement.consequence.type === 'endStory') &&
                        <>
                            <div>{`${t('PopupEventItem_EndStoryMessage')}: `}</div>
                            <div>{eventElement.consequence.message}</div>
                        </>
                    }
                </div>
            }
            on={['click', 'hover']}
            wide='very'
            hoverable
            mouseEnterDelay={200}
            mouseLeaveDelay={100}
        />
    );
}