
import axios from 'axios';
import { verifyEventByMessages, interpolateTemplate, getDynamicTimeString } from '../Utils/Utils.js';

export class LlmEngine {
  constructor(axiosClient) {
    this.axiosClient = axiosClient;
    this.context = null;
  }

  async regenerateContext(story) {
    story.eventsList.forEach(event => {
      if (event.startDate === null) {
        this.#verifyEventByMessages(story, event);
      }
    });

    const [mainCharacter, contactInfo] = [story.mainCharacter, story.selectedContactRecord];

    const values = {
      contexteHistoire: story.contexteHistoire || ' ',
      language: story.parameters?.language || 'english',
      prediction: Math.random() < 0.15 ? 'true' : ' ',
      nbRessources: (story.parameters?.ressources_complexes?.length && story.parameters.ressources_complexes.length > 0) ? story.parameters.ressources_complexes.length : ' ',
      selectedContactName: contactInfo?.name || ' ',
      selectedContactNumber: contactInfo?.number ? `+${contactInfo.number}` : ' ',
      selectedContactAge: contactInfo?.age || ' ',
      selectedContactCharacterTraits: contactInfo?.character_traits || ' ',
      selectedContactGender: contactInfo?.gender || 'other',
      selectedContactParticularities: contactInfo?.particularities || ' ',
      selectedContactPhysicalFeatures: contactInfo?.physical_features || ' ',
      mainCharacterName: mainCharacter?.name || ' ',
      mainCharacterAge: mainCharacter?.age || ' ',
      mainCharacterCharacterTraits: mainCharacter?.character_traits || ' ',
      mainCharacterGender: mainCharacter?.gender || 'other',
      mainCharacterParticularities: mainCharacter?.particularities || ' ',
      mainCharacterPhysicalFeatures: mainCharacter?.physical_features || ' ',
      dynamicTime: getDynamicTimeString(story.dynamicTime) || ' ',
      acquaintances: contactInfo?.acquaintances
        .map(acquaintance => {
          return {
            name: acquaintance.name || ' ',
            knowledge: acquaintance.knowledge || ' ',
            phoneNumberKnowledge: acquaintance.phoneNumberKnowledge ? story.contactList.find(contact => contact.name === acquaintance.name)?.number : ' ',
          }}) || [],
  
      // // Each undifined or null field of each event must be replaced by an empty string
      events: story.eventsList
        .filter(event => {
          if (!event.receivers.includes(contactInfo?.name || '')) {return false;} // TODO replace it by number
          if (event.startDate === null || event.startTime === null) {return false;}
          const eventStartDate = new Date(`${event.startDate}T${event.startTime}`);
          return eventStartDate < story.dynamicTime;
        })
        .map(event => {
          return {
            startDate: event.startDate || ' ',
            startTime: event.startTime || ' ',
            durationDays: event.durationDays !== undefined ? event.durationDays : ' ',
            durationHours: event.durationHours !== undefined ? event.durationHours : ' ',
            durationMinutes: event.durationMinutes !== undefined ? event.durationMinutes : ' ',
            description: event.description || ' ',
          };
        }) || [],
    };

    let contexteDeLhistoire = interpolateTemplate(story.promptSystemeChoisi, values);
    let contextFormatted = contexteDeLhistoire.split("\\n").join("\n");
    let newContext = [{ "role": "system", "content": contextFormatted }];
    let previousMessageRole = null;
    let previousMessageContent = '';

    // TODO senders/receivers should also be identified by numbers!!
    story.messagesList?.forEach(message => {
      if (message.senders.includes(story.selectedContactName) || message.receivers.includes(story.selectedContactName)) {
        const role = message.senders.includes(story.selectedContactName) ? "assistant" : "user";
        if (role === previousMessageRole) {
          previousMessageContent += `\n${message.message}`;
        } else {
          if (previousMessageRole === "assistant") {
            newContext.push({ "role": previousMessageRole, "content": previousMessageContent });
          } else if (previousMessageRole === "user") {
            newContext.push({ "role": previousMessageRole, "content": previousMessageContent });
          }
          previousMessageRole = role;
          previousMessageContent = message.message;
        }
      }
    });

    if (previousMessageRole === "assistant") {
      newContext.push({ "role": previousMessageRole, "content": previousMessageContent });
    } else if (previousMessageRole === "user") {
      newContext.push({ "role": previousMessageRole, "content": previousMessageContent });
    }

    this.context = newContext
  }

  async sendMessage(message, context, modelChosen, apiKey, env) {
    
    if (!this.axiosClient) {
      console.error("Axios client is not initialized.");
      return;
    } 

    while (!this.context[0]) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }

    const payload = {
      messages: [...this.context, { role: "user", content: message }],
      temperature: modelChosen.temperature,
      model: modelChosen.model,
      stream: false
    };

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': modelChosen.name !== 'IA en local' ? `Bearer ${apiKey}` : undefined
    };

    try {
      const response = await axios.post(modelChosen.APIurl, payload, { headers });
      const responseMessage = modelChosen.name === 'IA en local' ? response.data.message.content : response.data.choices[0].message.content;
      return responseMessage;
    } catch (error) {
      console.error("Error while sending message to GPT: ", error.response ? error.response.data : error.message);
      return null;
    } 
  }

  #verifyEventByMessages(story, event) {
    let nbMessages = 0;
    story.messagesList.forEach(message => {
      for (let character of event.nbMessageCharacters) {
        if (message.senders.includes(character) || message.receivers.includes(character)) {
            nbMessages++;
            break;
        }
      }
    });
    if (nbMessages >= event.nbMessages) {
      event.startDate = story.dynamicTime.toISOString().slice(0, 10);
      event.startTime = story.dynamicTime.toLocaleTimeString();
    }
  }
}