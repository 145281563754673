import React, { useContext, useEffect } from 'react';
import './Contacts.css'; // Ensure this CSS file includes the updated styles
import ContactItem from './ContactItem.js';
import { Button, Header, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { deleteObject, getStorage, ref } from 'firebase/storage';
import { StoryContext } from '../Context/StoryContext.js';
import { EnvironmentContext } from '../Context/EnvironmentContext.js';
import { CreateContext } from '../Context/CreateContext.js';
import { isContactMainCharacter } from "../Utils/Utils"

const Contacts = (props) => {
    // translation
    const { t } = useTranslation();
    const story = useContext(StoryContext);
    const createContext = useContext(CreateContext);
    const env = useContext(EnvironmentContext);

    const handleDeleteContact = async (contactNum) => {
        const storage = getStorage();
        const contactToDelete = story.contactList.find(contact => contact.number === contactNum);
        const desertRef = ref(storage, "contacts/icons/" + story.id + "/" + contactToDelete.number);
        // Delete the file
        deleteObject(desertRef).then(() => {
            // File deleted successfully
            console.log("suppressed")
        }).catch((error) => {
            // Uh-oh, an error occurred!
            console.log("error ", error)
        });
        const updatedContactsList = story.contactList.filter(contact => contact.number !== contactNum);
        story.setContactList(updatedContactsList);
        story.setSelectedContact(null);
    };

    const handleEditContact = (contactNum) => {
        createContext.setEditContact(contactNum);
        createContext.setOpenPopUpAddNewContact_CreationMode(true);
    };

    useEffect(() => {
        const contact = story.contactList.find(contact => contact.number === story.selectedContact);
        if (!story.selectedContact || isContactMainCharacter(story.selectedContact) || !contact || !contact.initial) {
            // it means selectedContact is incorrect and needs to be replaced to the first contact
            const firstContact = story.contactList.find(contact => !isContactMainCharacter(contact));
            if (firstContact) {
                story.setSelectedContact(firstContact.number);
            }
        }
    }, [story.contactList])

    return (
        <div className="contacts">
            <div className="contact-title">
                {!env.createMode && (
                    <Button
                        icon="arrow alternate circle left outline"
                        inverted
                        style={{ marginRight: "20px" }}
                        onClick={() => window.history.go(-1)} 
                    />
                )}
                {t('Contacts')}
                <Popup
                    trigger={
                        <Button
                            icon="add"
                            size="large"
                            circular
                            className="ui icon circular grey button"
                            style={{ marginLeft: "10px" }}
                            onClick={() => env.createMode ? createContext.setOpenPopUpAddNewContact_CreationMode(true) : props.setPopUp(true)}
                        />
                    }
                    content={t('PopUp_AddContact')}
                    position='top center'
                    on='hover'
                />
            </div>
            <div className="contact-lists">
                {env.createMode && (
                    <Header inverted>{t('InitialContacts')}</Header>
                )}
                <div className="contact-list">
                    {story.contactListWithoutMainCharacter
                        .filter(contact => contact.initial)
                        .map(contact => (
                            <ContactItem
                                story={story}
                                setShowContact={props.setShowContact}
                                key={contact.number}
                                number={contact.number}
                                name={contact.name}
                                onDeleteContact={handleDeleteContact}
                                onEditContact={handleEditContact}
                                createMode={env.createMode}
                            />
                        ))}
                </div>
                {env.createMode && (
                    <>
                        <Header inverted>{t('NonInitialContacts')}</Header>
                        <div className="contact-list-hidden">
                            {story.contactListWithoutMainCharacter
                                .filter(contact => !contact.initial && contact.name !== "user") // TODO Put that in utils directly
                                .map(contact => (
                                    <ContactItem
                                        key={contact.number}
                                        setShowContact={props.setShowContact}
                                        onDeleteContact={handleDeleteContact}
                                        onEditContact={handleEditContact}
                                        number={contact.number}
                                        name={contact.name}
                                    />
                                ))}
                        </div>
                    </>
                )}
            </div>
            <div className='contact-mainCharacter'>
                <ContactItem
                    key={story.mainCharacter ? story.mainCharacter.number : ''}
                    setShowContact={props.setShowContact}
                    number={story.mainCharacter ? story.mainCharacter.number : ''}
                    name={story.mainCharacter ? story.mainCharacter.name : ''}
                    onEditContact={handleEditContact}
                />
            </div>
        </div>
    );
}

export default Contacts;
