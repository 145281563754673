import React, { useContext, useState, useEffect } from 'react';
import { Button, Grid } from 'semantic-ui-react';

import { useTranslation } from 'react-i18next';
import { StoryContext } from '../Context/StoryContext';
import { CreateContext } from '../Context/CreateContext';
import EventItem from './EventItem';

export default function EventsContainer({disposition, receiversList}) {
    const { t } = useTranslation();
    const storyContext = useContext(StoryContext);
    const createContext = useContext(CreateContext);

    const handleEditEvent = async (eventId) => {
        createContext.setEditEvent(eventId);
        createContext.setOpenPopUpAddNewEvent_CreationMode(true);
    };

    const handleDuplicateEvent = async (eventId) => {
        createContext.setDuplicateEvent(eventId);
        createContext.setOpenPopUpAddNewEvent_CreationMode(true);
    };

    const handleDeleteEvent = async (eventIndex) => {
        const newEventsList = await storyContext.eventsList.filter((event) => event.id !== eventIndex);
        storyContext.setEventsList(newEventsList);
    };

    return (
        <div className={disposition === 'horizontal' ? 'horizontal-event-container' : 'vertical-event-container'}>
            <Button
                className='add-event-button'
                // icon='add' 
                color='blue' 
                onClick={(e) => createContext.setOpenPopUpAddNewEvent_CreationMode(true)}
            >
                {t('AddNewEvent')}
            </Button>
            <div className={disposition === 'horizontal' ? 'horizontal-event-zone' : 'vertical-event-zone'}>
                {storyContext.eventsList.map((event) => (
                    (event.consequence.type === 'endStory' ||
                    (event.consequence.type === 'information' &&
                    receiversList.some(receiver => event.consequence.receivers.includes(receiver)))) &&
                    <EventItem
                        key={event.id} 
                        eventElement={event} 
                        handleDeleteEvent={handleDeleteEvent}
                        handleDuplicateEvent={handleDuplicateEvent}
                        handleEditEvent={handleEditEvent} 
                    />
                ))}
            </div>
        </div>
    );
}
