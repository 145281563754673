import React, { useState } from 'react';
// styling
import '../App.css';
import { Button, Modal, Form, Input, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';


const NewContact = props => {
  const { t } = useTranslation();
  const [number, setPhoneNumber] = useState('');
  const [errorOn, setErrorOn] = useState(false);
  const [errorContent, setErrorContent] = useState('');

  const { setPopUp, popUp } = props
  const { story } = props

  const handleCreateContact = () => {
    // Vérifier si les champs sont valides
    if (number.trim() === '') {
      setErrorOn(true);
      setErrorContent(t('PleaseFillFields'));
      return;
    }

    // Vérifier si le numéro de téléphone est déjà utilisé et est présent dans les numéro déjà possible
    if (!contactInfIsCorrect(number)) {
      setErrorOn(true);
      setErrorContent(t('PhoneAlreadyUsedOrNonExistant'));
      return;
    }

    // Activer le contact
    const contact = story.contactList.find((contact) => contact.number === number.replace('+', ''))
    contact.initial = true

    // Réinitialiser les champs
    setPhoneNumber('');
    setErrorOn(false);

    // Fermer la fenêtre modale
    onClose();
  };
  const onClose = () => {
    setPopUp(false)
  }

  const open = () => {
    setPopUp(true)
  }

  function contactInfIsCorrect(number) {
    return story.contactList.find((contact) => contact.number === number.replace('+', ''))
  }

  return (
    popUp &&
    <Modal open={popUp} onClose={onClose} size="tiny">
      <Modal.Header>{t('CreateNewContact')}</Modal.Header>
      <Modal.Content>
        <Form error={errorOn}>
          <Form.Field>
            <label>{t('PhoneNumber')}</label>
            <Input
              placeholder={t('PlaceholderPhoneNumber')}
              value={number}
              onChange={(e) => setPhoneNumber(e.target.value.replace(/ /g, ''))}
            />
          </Form.Field>
          <Message
            error
            header={t('Error')}
            content={errorContent}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button negative onClick={onClose}>
          {t('Cancel')}
        </Button>
        <Button positive onClick={handleCreateContact}>
          {t('Create')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default NewContact;
