import React from 'react';
import { Container, Header, Segment, Grid, List, Divider, Icon, Button } from 'semantic-ui-react';
import classes from "./FootersPages.module.css";

export default function GettingStarted() {
    return (
        <Container className={`${classes.gettingStarted}`} style={{ padding: '3em 0em' }}>
            <Segment basic textAlign="center">
                <Header as="h1" style={{ fontSize: '2.5em', color: '#2a2a2a' }}>Getting Started</Header>
                <p style={{ fontSize: '1.33em', color: '#5a5a5a', marginBottom: '2em' }}>
                    Ready to dive into creating immersive experiences? Here’s a quick guide to get you started with Biffe Corps.
                </p>
            </Segment>

            <Divider section />

            <Segment basic>
                <Grid stackable columns={2} verticalAlign="middle" textAlign="center">
                    <Grid.Column width={8}>
                        <Icon name="user plus" size="huge" color="blue" />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Header as="h2" style={{ fontSize: '2em', color: '#2a2a2a' }}>Step 1: Create an Account</Header>
                        <p style={{ fontSize: '1.2em', color: '#5a5a5a', marginBottom: '1em' }}>
                            Join us by creating an account and start your journey.
                        </p>
                        <Button primary size="large" href="/signup">Sign Up</Button>
                    </Grid.Column>
                </Grid>
            </Segment>

            <Divider section />

            <Segment basic>
                <Grid stackable columns={2} verticalAlign="middle" textAlign="center">
                    <Grid.Column width={8}>
                        <Header as="h2" style={{ fontSize: '2em', color: '#2a2a2a' }}>Step 2: Select your AI</Header>
                        <p style={{ fontSize: '1.2em', color: '#5a5a5a', marginBottom: '1em' }}>
                            Choose the AI that best suits your story’s needs.
                        </p>
                        <Button primary size="large" href="/tutoChooseModel">Which one fits your needs</Button>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Icon name="book" size="huge" color="blue" />
                    </Grid.Column>
                </Grid>
            </Segment>

            <Divider section />

            <Segment basic>
                <Grid stackable columns={2} verticalAlign="middle" textAlign="center">
                    <Grid.Column width={8}>
                        <Icon name="play circle" size="huge" color="blue" />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Header as="h2" style={{ fontSize: '2em', color: '#2a2a2a' }}>Step 3a: Select a Story to Play</Header>
                        <p style={{ fontSize: '1.2em', color: '#5a5a5a', marginBottom: '1em' }}>
                            Find and play from a selection of available stories.
                        </p>
                        <Button primary size="large" href="/SelectStory">Select a Story</Button>
                    </Grid.Column>
                </Grid>
            </Segment>

            <Divider section />

            <Segment basic>
                <Grid stackable columns={2} verticalAlign="middle" textAlign="center">
                    <Grid.Column width={8}>
                        <Header as="h2" style={{ fontSize: '2em', color: '#2a2a2a' }}>Step 3b: Create Your Own Story</Header>
                        <p style={{ fontSize: '1.2em', color: '#5a5a5a', marginBottom: '1em' }}>
                            Start building your unique story from scratch.
                        </p>
                        <Button primary size="large" href="/Create/Edit/new">Create Your Story</Button>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Icon name="pencil alternate" size="huge" color="blue" />
                    </Grid.Column>
                </Grid>
            </Segment>

            <Divider section />

            <Segment basic textAlign="center">
                <Header as="h2" style={{ fontSize: '2em', color: '#2a2a2a' }}>Connect with Us</Header>
                <p style={{ fontSize: '1.33em', color: '#5a5a5a', marginBottom: '1.5em' }}>
                    Have questions? Reach out and join our community!
                </p>
                <List horizontal divided link size="large">
                    <List.Item as="a" href="mailto:support@biffecorps.com" style={{ color: '#1e70bf' }}>Email Us</List.Item>
                    <List.Item as="a" href="https://www.facebook.com/biffecorps" style={{ color: '#1e70bf' }}>Facebook</List.Item>
                    <List.Item as="a" href="https://discord.gg/biffecorps" style={{ color: '#1e70bf' }}>Discord</List.Item>
                </List>
            </Segment>

            <Divider section />
        </Container>
    );
}
