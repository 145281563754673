import React, { createContext, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isContactMainCharacter } from "../Utils/Utils"

const StoryContext = createContext();

const StoryProvider = ({ children }) => {
    const { t } = useTranslation();
    const [id, setId] = useState('');
    const [music, setMusic] = useState('https://firebasestorage.googleapis.com/v0/b/storiagl.appspot.com/o/musics%2FSummer%20Somewhere%20In%20Cuba%20-%20Cumbia%20Deli.mp3?alt=media&token=1f14c263-44f6-4034-8d02-1c288a3d6139');
    const [messagesList, setMessagesList] = useState([])
    const [eventsList, setEventsList] = useState([])
    const [selectedContact, setSelectedContact] = useState("");
    const [creationDate, setCreationDate] = useState(null);
    const [contactList, setContactList] = useState([]);
    const [contexteHistoire, setContexteHistoire] = useState("");
    const [parameters, setParameters] = useState({
        name: t('NewStoryTitle'),
        language: 'francais',
        context: '',
        start_date: new Date(),
        access: 'limited',
        financial_status: 'tokenUser',
        default_model: 'Mistral-Medium',
        home_message: '',
        thumbnail: null,
        music: null,
        ressources_complexes: [], // TODO Handle this one too
      });
    const [dynamicTime, setDynamicTime] = useState(parameters.start_date);
    const [homeGreetingMessage, setHomeGreetingMessage] = useState("");
    const [endStoryMessageIdDisplayed, setEndStoryMessageIdDisplayed] = useState("");
    const [promptSystemeChoisi, setPromptSystemeChoisi] = useState("");
    const [ressourcesComplexes, setRessourcesComplexes] = useState([]);
    const mainCharacter = useMemo(() => contactList.find(contact => isContactMainCharacter(contact)) || {}, [contactList]); // TODO an optimisation can surely be made here
    const contactListWithoutMainCharacter = useMemo(() => contactList.filter(contact => !isContactMainCharacter(contact)) || [], [contactList]);
    const selectedContactRecord = useMemo(() => contactList.find(contact => contact.number === selectedContact), [selectedContact]);
    const selectedContactName = useMemo(() => selectedContactRecord?.name, [selectedContactRecord]); // TODO eventually get rid of it

    const value = {
        id, setId,
        music, setMusic,
        messagesList, setMessagesList,
        eventsList, setEventsList,
        selectedContact, setSelectedContact,
        creationDate, setCreationDate,
        contactList, setContactList,
        contexteHistoire, setContexteHistoire,
        parameters, setParameters,
        dynamicTime, setDynamicTime,
        homeGreetingMessage, setHomeGreetingMessage,
        endStoryMessageIdDisplayed, setEndStoryMessageIdDisplayed,
        promptSystemeChoisi, setPromptSystemeChoisi,
        ressourcesComplexes, setRessourcesComplexes,
        mainCharacter, contactListWithoutMainCharacter,
        selectedContactRecord, selectedContactName
    }

    return (
        <StoryContext.Provider value={value}>
            {children}
        </StoryContext.Provider>
    );
}

export { StoryProvider, StoryContext };