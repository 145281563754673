import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebaseConfig';
import { collection, query, where, getDocs } from 'firebase/firestore';
import './CreateListStories.css'; // Ensure your CSS is properly linked
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import StoryItem from '../Stories/StoryItem';
import { EnvironmentContext } from '../Context/EnvironmentContext';

const CreateListStories = () => {
    const [publicStories, setPublicStories] = useState([]);
    const [privateStories, setPrivateStories] = useState([]);
    const env = useContext(EnvironmentContext);

    // translation
    const { t } = useTranslation();

    const fetchPublicStories = async () => {
        // console.log("user.uuid : ", env.user.uid)
        if (!env.user) return;
        const q = query(collection(db, "stories"),
            where("owner", "==", env.user.uid),
            where("parameters.access", "==", "public"));
        const querySnapshot = await getDocs(q);
        const fetchedStories = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        fetchedStories.sort((a, b) => {
            const aDate = a.creation_date ? a.creation_date.toDate() : new Date(0);
            const bDate = b.creation_date ? b.creation_date.toDate() : new Date(0);
            return bDate - aDate;
        });
        setPublicStories(fetchedStories);
    };
    const fetchPrivateStories = async () => {
        if (!env.user) return;
        const q = query(collection(db, "stories"),
            where("owner", "==", env.user.uid),
            where("parameters.access", "!=", "public"));
        const querySnapshot = await getDocs(q);
        const fetchedStories = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        }));
        fetchedStories.sort((a, b) => {
            const aDate = a.creation_date ? a.creation_date.toDate() : new Date(0);
            const bDate = b.creation_date ? b.creation_date.toDate() : new Date(0);
            return bDate - aDate;
        });
        setPrivateStories(fetchedStories);
    };


    const handleCreateNew = () => {
        // setInitialContactList([]);
        // setMessagesList([]);
        // setEventsList([]);
        // setTotalContactList([]);
        // TODO 14
    }

    useEffect(() => {
        fetchPublicStories();
        fetchPrivateStories();
    }, [env.user]);

    return (
        <div className="gridIalreadyStarted">
            <Button as={Link} to="/Create/Edit/new" onClick={handleCreateNew} className="create-new-story-link">{t('CreateNewStory')}</Button>
            {publicStories.length > 0 ? (
                <>
                    <h2>{t('MyPublicStories')}</h2>
                    {publicStories.map((story) => (
                        <div className="stories-hero">
                            <div key={story.id}>
                                <StoryItem
                                    env={env}
                                    storyElement={story}
                                    fetchStoriesFunction={fetchPublicStories}
                                    editActived={true}
                                    duplicateActived={true}
                                    deleteActived={true}
                                ></StoryItem>
                            </div>
                        </div>
                    ))}
                </>
            ) : (
                <div className="stories-hero" style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-start" }}>
                    <h2>{t('MyPublicStories')}</h2>
                    <p className='no-stories-found'>{t('NoStoriesFound')} {t('StartCreatingNewStory')}</p>
                </div>
            )}
            <div className="stories-hero">
                {privateStories.length > 0 ? (
                    <>
                        <h2>{t('MyPrivateStories')}</h2>
                        {privateStories.map((story) => (
                            <div className="stories-hero">
                                <div key={story.id + 1}>
                                    <StoryItem
                                        env={env}
                                        storyElement={story}
                                        fetchStoriesFunction={fetchPublicStories}
                                        editActived={true}
                                        duplicateActived={true}
                                        deleteActived={true}
                                    ></StoryItem>
                                </div>
                            </div>
                        ))}
                    </>
                ) : (
                    <div className="stories-hero" style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-start" }}>
                        <h2>{t('MyPrivateStories')}</h2>
                        <p>{t('NoStoriesFound')} {t('StartCreatingNewStory')}</p>
                    </div>
                )}

                {/* <Link to="/Create/Edit/new" className="create-new-story-link">Create New Story</Link> */}
            </div>
        </div>
    );
};

export default CreateListStories;
