import React from 'react';
import { Container, Header, Image, Segment, Grid, List, Button, Divider } from 'semantic-ui-react';
import classes from "./FootersPages.module.css"

export default function AboutUs() {
    return (
        <Container className={`${classes.aboutus}`} style={{ padding: '3em 0em' }}>
            <Segment basic textAlign="center">
                <Header as="h1" style={{ fontSize: '2.5em' }}>About Us</Header>
                <p style={{ fontSize: '1.33em' }}>
                    Welcome to Biffe Corps! We’re dedicated to bringing you the best service possible so that you create the best imersive stories and let your imagination and your readers's imagination run wild. <br></br>
                    <br></br>
                    We're a team of passionate individuals who love what we do and we're excited to share our work with you.
                </p>
            </Segment>

            <Divider section />

            <Segment basic>
                <Grid stackable columns={2} verticalAlign="middle">
                    <Grid.Column width={8}>
                        <Header as="h2" style={{ fontSize: '2em' }}>Our Mission</Header>
                        <p style={{ fontSize: '1.2em' }}>
                            We believe in the real interest of the AI in creative experiences.<br></br>
                        </p>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Image
                            src="https://firebasestorage.googleapis.com/v0/b/storiagl.appspot.com/o/PublicImages%2Ftable.png?alt=media&token=ff7d1f10-2310-43f7-8e7c-7fb9d538b1d5"
                            rounded
                            alt="Mission"
                        />
                    </Grid.Column>
                </Grid>
            </Segment>

            <Divider section />

            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }} textAlign="center">Meet the Team</Header>
                <Grid stackable columns={3}>
                    <Grid.Column key={1} textAlign="center">
                        <Image
                            src={`https://firebasestorage.googleapis.com/v0/b/storiagl.appspot.com/o/PublicImages%2FSimon.jpg?alt=media&token=3ce0484b-35d7-402f-be69-4d585be82488`}
                            circular
                            size="small"
                            alt={"Simon"}
                        />
                        <Header as="h3">{"Simon"}</Header>
                        <p>Developer</p>
                    </Grid.Column>
                    <Grid.Column key={2} textAlign="center">
                        <Image
                            src={`https://firebasestorage.googleapis.com/v0/b/storiagl.appspot.com/o/PublicImages%2FPaul.jpg?alt=media&token=c182ffab-01b0-44cf-b032-4a9988fd29d1`}
                            circular
                            size="small"
                            alt={"Paul"}
                        />
                        <Header as="h3">{"Paul"}</Header>
                        <p>Developer</p>
                    </Grid.Column>
                    <Grid.Column key={3} textAlign="center">
                        <Image
                            src={`https://firebasestorage.googleapis.com/v0/b/storiagl.appspot.com/o/PublicImages%2FMarin.jpg?alt=media&token=97a68e3b-6d60-4f35-beb4-8379e98aedfc`}
                            circular
                            size="small"
                            alt={"Marin"}
                        />
                        <Header as="h3">{"Marin"}</Header>
                        <p>Developer</p>
                    </Grid.Column>
                </Grid>
            </Segment>

            <Divider section />

            <Segment basic textAlign="center">
                <Header as="h2" style={{ fontSize: '2em' }}>Contact Us</Header>
                <List horizontal divided link size="large">
                    <List.Item as="a" href="mailto:marin.luet@gmail.com">Email Us</List.Item>
                    <List.Item as="a" href="https://www.facebook.com/profile.php?id=100092842307846">Facebook</List.Item>
                    <List.Item as="a" href="https://discord.gg/eygzMAVF2X">Discord</List.Item>
                </List>
            </Segment>

            <Divider section />
            
        </Container>


    );
}
