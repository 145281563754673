import React from 'react';
import { Container, Header, Segment, Image, Button } from 'semantic-ui-react';
import classes from "./GuideCreerCompte.module.css";

export default function ChoisirHistoire() {
    return (
        <Container className={classes.guideContainerChoisirHistoire} style={{ padding: '3em 0em' }}>
            <Segment basic textAlign="center">
                <Header as="h1" style={{ fontSize: '2.5em', color: '#2a2a2a' }}>Guide sur la Sélection de l'Histoire</Header>
                <p style={{ fontSize: '1.33em', color: '#5a5a5a' }}>
                    Voici un petit guide destiné à vous expliquer comment fonctionne le menu de sélection d'histoires. 
                    Le fonctionnement y est volontairement très similaire à YouTube.
                </p>
            </Segment>

            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }}>Mode Histoire VS Mode Création</Header>
                <Image src="https://firebasestorage.googleapis.com/v0/b/storiagl.appspot.com/o/PublicImages%2FStoryVsHistoire1.png?alt=media&token=2acc3b4e-8496-4dfc-9be1-6e795ebd701c" alt="Modèle IA" fluid />
                <p style={{ fontSize: '1.2em', color: '#5a5a5a' }}>
                    Bon sang de bonsoir ! Mais pourquoi, dans ma barre de navigation en haut, y a-t-il un "Mode Histoire" et un "Mode Création" ? 
                    En fait, Fablia reprend un peu le principe de YouTube. L'idée est de proposer un espace où les gens peuvent créer des histoires et un espace pour que d'autres puissent jouer à ces histoires créées (si le créateur les a rendues publiques).
                    L'espace pour créer des histoires est donc le fameux "Mode Création", tandis que l'espace pour jouer à des histoires créées par Fablia ou par d'autres utilisateurs est le "Mode Histoire".
                
                    Pour commencer, veuillez cliquer sur le bouton "Mode Histoire".
                </p>
            </Segment>

            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }}>Histoires déjà commencées VS Recommandées par Fablia VS Toutes les histoires</Header>
                <Image src="https://firebasestorage.googleapis.com/v0/b/storiagl.appspot.com/o/PublicImages%2FStoryVsHistoire2.png?alt=media&token=debefd3c-db93-47c0-bbe6-468a657dfc55" alt="Groq Llama 3.1" fluid />
                <p style={{ fontSize: '1.2em', color: '#5a5a5a' }}>
                    Pour que votre expérience sur notre site soit la plus agréable, nous avons divisé la liste en trois sous-listes.
                    <br /><br />
                    <strong>1. Histoires déjà commencées :</strong> Cette liste apparaît en premier afin que vous puissiez facilement reprendre l'aventure que vous avez commencée. Elle est en haut car c'est ce que vous ferez le plus souvent en revenant sur le site.
                    <br /><br />
                    <strong>2. Recommandées par Fablia :</strong> Ces histoires sont des pépites recommandées, gage de qualité et démontrant les performances de Fablia. Si vous ne savez pas quoi choisir, commencez par l'une d'elles !
                    <br /><br />
                    <strong>3. Toutes les histoires :</strong> Cette liste rassemble toutes les histoires publiées publiquement par des créateurs qui souhaitent partager leurs œuvres, un peu comme sur YouTube.
                </p>
            </Segment>

            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }}>Comment jouer à une Histoire ?</Header>
                <Image src="https://firebasestorage.googleapis.com/v0/b/storiagl.appspot.com/o/PublicImages%2FStoryVsHistoire3.png?alt=media&token=3b2b488c-7378-4b68-b61f-4ef2c9697c0e" alt="OpenAI GPT4o" fluid />
                <p style={{ fontSize: '1.2em', color: '#5a5a5a' }}>
                    Ahhh, vous avez enfin trouvé une histoire qui vous plaît et vous souhaitez la lancer pour plonger dans cet univers captivant !
                    <br /><br />
                    Rien de plus simple ! Il vous suffit de cliquer sur le bouton <strong>"Play"</strong> situé sur l'histoire et zhooouuuu, vous voilà propulsé dans un nouvel univers rempli d'aventures palpitantes.
                </p>
            </Segment>

            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }}>Une histoire vous plaît mais vous souhaitez l'améliorer avec vos propres idées ?</Header>
                <Image src="https://firebasestorage.googleapis.com/v0/b/storiagl.appspot.com/o/PublicImages%2FStoryVsHistoire4.png?alt=media&token=26069879-ad84-4142-8e41-52e98f17e88b" alt="OpenAI GPT4o" fluid />
                <p style={{ fontSize: '1.2em', color: '#5a5a5a' }}>
                    Si vous trouvez une histoire qui vous enchante et que vous aimeriez l'adapter à votre guise, pas de souci ! 
                    <br /><br />
                    Il vous suffit de cliquer sur le bouton avec deux feuilles (Bouton dupliquer), et vous pourrez la modifier à votre convenance !
                </p>
            </Segment>

            <Segment basic>
                <Header as="h2" style={{ fontSize: '2em' }}>Supprimer une histoire déjà commencée</Header>
                <p style={{ fontSize: '1.2em', color: '#5a5a5a' }}>
                    Vous avez commencé une histoire mais souhaitez repartir à zéro ? Pas de problème !
                    <br /><br />
                    Il vous suffit de cliquer sur la poubelle et de confirmer l'action. Si la suppression ne fonctionne pas, essayez de rafraîchir la fenêtre.
                </p>
            </Segment>
            <Button primary floated="right" href="/guide/creer-histoire"><p>Continuer</p></Button>

        </Container>
    );
}
