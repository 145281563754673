import React, { useState, useEffect } from 'react';
import { signOut, getAuth } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { Button, Container, Divider, Grid, Header, Icon, Image, Segment, Form, Input, Message, Modal } from 'semantic-ui-react';
import DropDownSelectionAI from '../AIs/AIs';
import { doc, setDoc, getDoc, getFirestore } from "firebase/firestore";
import { useTranslation } from 'react-i18next';
import { onSnapshot } from "firebase/firestore";
import { QuestionPopup } from '../Utils/Utils';
import { Navigate } from 'react-router-dom';

const Settings = (props) => {
    const { t, i18n } = useTranslation();
    const [user, setUser] = useState(null);
    const [formValues, setFormValues] = useState({
        apiKey: '',
        model: 'mistral-small',
    });
    const [modelActuel, setModelActuel] = useState('');
    const [formError, setFormError] = useState('');
    const [formSuccess, setFormSuccess] = useState('');

    useEffect(() => {
        const authInstance = getAuth();
        const unsubscribeAuth = authInstance.onAuthStateChanged(async (currentUser) => {
            setUser(currentUser);
            if (currentUser && currentUser.uid) {
                const db = getFirestore();
                const userRef = doc(db, "users", currentUser.uid);
                try {
                    const unsubscribe = onSnapshot(userRef, (docSnap) => {
                        if (docSnap.exists()) {
                            const userData = docSnap.data();
                            setModelActuel(userData.model?.name || t('DefaultModel'));
                            setFormValues({
                                apiKey: userData.apiKey || '',
                                model: userData.model || 'mistral-small',
                            });
                        } else {
                            console.log("No such document!");
                        }
                    });
                    return () => unsubscribe();
                } catch (error) {
                    console.error("Error fetching user settings: ", error);
                }
            }
        });

        return () => {
            unsubscribeAuth();
        };
    }, []);

    const handleFormSubmit = async (e) => {
        setFormError('')
        setFormSuccess('')
        e.preventDefault();
        if (formValues.apiKey.length < 10) {
          setFormSuccess('');
          setFormError('API key must be at least 10 characters long.');
          return;
        }
        setFormError('');
      
        if (!user || !user.uid) {
          setFormError('No user logged in.');
          return;
        }
      
        // Make an API call to the AI model using the provided API key and URL
        const response = await fetch(`${formValues.model.APIurl}`, {
          headers: {
            'Authorization': `Bearer ${formValues.apiKey}`,
            "Content-Type": "application/json"
          },
          method: 'POST',
          body: JSON.stringify({
            "messages": [{ "role": "user", "content": "just say hi" }],
            "model": `${formValues.model.model}`
          })
        });
      
        if (response.ok) {
          // If the API call is successful, save the settings to Firestore
          const db = getFirestore();
          const userRef = doc(db, "users", user.uid);
          await setDoc(userRef, {
            apiKey: formValues.apiKey,
            model: formValues.model,
          }, { merge: true });
          setFormSuccess('Settings saved!');
          try {
            props.setDoWeNeedToDisplayModal(false);
          } catch (error) { }
        } else {
          // If the API call fails, show an error message
          setFormError('Invalid API key or URL');
        }
      
        // setFormValues({ apiKey: '', model: 'mistral-small' });
      };

    const handleSignOut = async () => {
        await signOut(auth);
        setUser(null);
    };

    return (
        <Container>
            <Segment>
                <Header as="h1" content={t("Settings")} textAlign="center" />
                <Divider />
                {user ? (
                    <>
                        <Grid divided columns={2}>
                            <Grid.Column>
                                <Segment>
                                    <Header as="h3" content={t("Profile")} />
                                    {user.photoURL ? <Image
                                        src={user.photoURL}
                                        avatar
                                        style={{ marginBottom: '1em' }}
                                    /> : <Icon className='user'
                                        style={{ marginBottom: '1em' }} />}
                                    <p>
                                        <strong>{t('Name')}:</strong> {user.displayName}
                                    </p>
                                    <p>
                                        <strong>{t('Email')}:</strong> {user.email}
                                    </p>
                                    <p>
                                        <p><strong>{t('CurrentlyUsedModel')}:</strong> {modelActuel}</p>
                                    </p>
                                </Segment>
                            </Grid.Column>
                            <Grid.Column>
                                <Segment>
                                    <Header as="h3" content={t("AISettings")} />
                                    <Form onSubmit={handleFormSubmit}>
                                        <Form.Field>
                                            <label>
                                                {t('AIModel')}
                                                <QuestionPopup message={t('Popup_Settings_AIModel')} />
                                            </label>
                                            <DropDownSelectionAI setFormValues={setFormValues} formValues={formValues} />
                                        </Form.Field>
                                        {formValues.model.name !== "IA en local" && (<Form.Field>
                                            <label>
                                                {t('APIKey')}
                                                <QuestionPopup message={t('Popup_Settings_APIKey')} />
                                            </label>
                                            <Input
                                                type="password"
                                                placeholder={t('EnterAPIKey')}
                                                value={formValues.apiKey}
                                                onChange={(e) => setFormValues({ ...formValues, apiKey: e.target.value })}
                                            />
                                        </Form.Field>)}

                                        <Button
                                            fluid
                                            color="google plus"
                                            content={t('Save')}
                                            type="submit"
                                        />
                                    </Form>
                                </Segment>
                            </Grid.Column>
                        </Grid>
                        {formError && (
                            <Message error header="Erreur" content={formError} />
                        )}

                        {formSuccess && (
                            <Message success header="Succès" content={formSuccess} />
                        )}
                    </>
                ) : (
                    <p>{t('YouNotSignedIn')}</p>
                )}
            </Segment>
        </Container>
    );
};

export default Settings;