import React, { useState } from 'react';
import { Dropdown, Flag } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import './LanguageDropdown.css'; // Import the custom styles

function LanguageDropdown() {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setLanguage(language);
  };

  const languageOptions = [
    { key: 'en', value: 'en', flag: 'gb', text: '' },
    { key: 'fr', value: 'fr', flag: 'fr', text: '' },
    { key: 'es', value: 'es', flag: 'es', text: '' },
    { key: 'de', value: 'de', flag: 'de', text: '' },
  ];

  return (
    <Dropdown
      className='custom-dropdown' // Add a custom class for styling
      placeholder='Select Language'
      compact // Make the dropdown smaller
      selection
      options={languageOptions}
      value={language}
      onChange={(e, { value }) => changeLanguage(value)}
    />
  );
}

export default LanguageDropdown;
