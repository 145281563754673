// src/firebaseConfig.js
// Initialize Firebase
import { initializeApp } from "firebase/app";
import {
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    signInWithPopup,
    GoogleAuthProvider
} from "firebase/auth";

import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCSWJ6BbN7gZEDEn1afTAgMrm9SzgJ5WB0",
    authDomain: "storiagl.firebaseapp.com",
    projectId: "storiagl",
    storageBucket: "storiagl.appspot.com",
    messagingSenderId: "506902913567",
    appId: "1:506902913567:web:0055411cd986de2cc6ab2a"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage, app, auth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, onAuthStateChanged, signInWithPopup, GoogleAuthProvider };